.joypixels-24-people {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-people.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-people {
        background-image: url(joypixels-sprite-24-people@2x.png);
        background-size: 12174px 24px;
    }
}


.joypixels-24-people._00000 {
    background-position: 0px 0px;
}
.joypixels-24-people._1f302 {
    background-position: -25px 0px;
}
.joypixels-24-people._1f383 {
    background-position: -50px 0px;
}
.joypixels-24-people._1f385 {
    background-position: -75px 0px;
}
.joypixels-24-people._1f392 {
    background-position: -100px 0px;
}
.joypixels-24-people._1f393 {
    background-position: -125px 0px;
}
.joypixels-24-people._1f3a9 {
    background-position: -150px 0px;
}
.joypixels-24-people._1f3c3-2640 {
    background-position: -175px 0px;
}
.joypixels-24-people._1f3c3-2642 {
    background-position: -200px 0px;
}
.joypixels-24-people._1f3c3 {
    background-position: -225px 0px;
}
.joypixels-24-people._1f440 {
    background-position: -250px 0px;
}
.joypixels-24-people._1f441 {
    background-position: -275px 0px;
}
.joypixels-24-people._1f442 {
    background-position: -300px 0px;
}
.joypixels-24-people._1f443 {
    background-position: -325px 0px;
}
.joypixels-24-people._1f444 {
    background-position: -350px 0px;
}
.joypixels-24-people._1f445 {
    background-position: -375px 0px;
}
.joypixels-24-people._1f446 {
    background-position: -400px 0px;
}
.joypixels-24-people._1f447 {
    background-position: -425px 0px;
}
.joypixels-24-people._1f448 {
    background-position: -450px 0px;
}
.joypixels-24-people._1f449 {
    background-position: -475px 0px;
}
.joypixels-24-people._1f44a {
    background-position: -500px 0px;
}
.joypixels-24-people._1f44b {
    background-position: -525px 0px;
}
.joypixels-24-people._1f44c {
    background-position: -550px 0px;
}
.joypixels-24-people._1f44d {
    background-position: -575px 0px;
}
.joypixels-24-people._1f44e {
    background-position: -600px 0px;
}
.joypixels-24-people._1f44f {
    background-position: -625px 0px;
}
.joypixels-24-people._1f450 {
    background-position: -650px 0px;
}
.joypixels-24-people._1f451 {
    background-position: -675px 0px;
}
.joypixels-24-people._1f452 {
    background-position: -700px 0px;
}
.joypixels-24-people._1f453 {
    background-position: -725px 0px;
}
.joypixels-24-people._1f454 {
    background-position: -750px 0px;
}
.joypixels-24-people._1f455 {
    background-position: -775px 0px;
}
.joypixels-24-people._1f456 {
    background-position: -800px 0px;
}
.joypixels-24-people._1f457 {
    background-position: -825px 0px;
}
.joypixels-24-people._1f458 {
    background-position: -850px 0px;
}
.joypixels-24-people._1f459 {
    background-position: -875px 0px;
}
.joypixels-24-people._1f45a {
    background-position: -900px 0px;
}
.joypixels-24-people._1f45b {
    background-position: -925px 0px;
}
.joypixels-24-people._1f45c {
    background-position: -950px 0px;
}
.joypixels-24-people._1f45d {
    background-position: -975px 0px;
}
.joypixels-24-people._1f45e {
    background-position: -1000px 0px;
}
.joypixels-24-people._1f45f {
    background-position: -1025px 0px;
}
.joypixels-24-people._1f460 {
    background-position: -1050px 0px;
}
.joypixels-24-people._1f461 {
    background-position: -1075px 0px;
}
.joypixels-24-people._1f462 {
    background-position: -1100px 0px;
}
.joypixels-24-people._1f463 {
    background-position: -1125px 0px;
}
.joypixels-24-people._1f464 {
    background-position: -1150px 0px;
}
.joypixels-24-people._1f465 {
    background-position: -1175px 0px;
}
.joypixels-24-people._1f466 {
    background-position: -1200px 0px;
}
.joypixels-24-people._1f467 {
    background-position: -1225px 0px;
}
.joypixels-24-people._1f468-1f33e {
    background-position: -1250px 0px;
}
.joypixels-24-people._1f468-1f373 {
    background-position: -1275px 0px;
}
.joypixels-24-people._1f468-1f37c {
    background-position: -1300px 0px;
}
.joypixels-24-people._1f468-1f393 {
    background-position: -1325px 0px;
}
.joypixels-24-people._1f468-1f3a4 {
    background-position: -1350px 0px;
}
.joypixels-24-people._1f468-1f3a8 {
    background-position: -1375px 0px;
}
.joypixels-24-people._1f468-1f3eb {
    background-position: -1400px 0px;
}
.joypixels-24-people._1f468-1f3ed {
    background-position: -1425px 0px;
}
.joypixels-24-people._1f468-1f466-1f466 {
    background-position: -1450px 0px;
}
.joypixels-24-people._1f468-1f466 {
    background-position: -1475px 0px;
}
.joypixels-24-people._1f468-1f467-1f466 {
    background-position: -1500px 0px;
}
.joypixels-24-people._1f468-1f467-1f467 {
    background-position: -1525px 0px;
}
.joypixels-24-people._1f468-1f467 {
    background-position: -1550px 0px;
}
.joypixels-24-people._1f468-1f468-1f466-1f466 {
    background-position: -1575px 0px;
}
.joypixels-24-people._1f468-1f468-1f466 {
    background-position: -1600px 0px;
}
.joypixels-24-people._1f468-1f468-1f467-1f466 {
    background-position: -1625px 0px;
}
.joypixels-24-people._1f468-1f468-1f467-1f467 {
    background-position: -1650px 0px;
}
.joypixels-24-people._1f468-1f468-1f467 {
    background-position: -1675px 0px;
}
.joypixels-24-people._1f468-1f469-1f466-1f466 {
    background-position: -1700px 0px;
}
.joypixels-24-people._1f468-1f469-1f466 {
    background-position: -1725px 0px;
}
.joypixels-24-people._1f468-1f469-1f467-1f466 {
    background-position: -1750px 0px;
}
.joypixels-24-people._1f468-1f469-1f467-1f467 {
    background-position: -1775px 0px;
}
.joypixels-24-people._1f468-1f469-1f467 {
    background-position: -1800px 0px;
}
.joypixels-24-people._1f468-1f4bb {
    background-position: -1825px 0px;
}
.joypixels-24-people._1f468-1f4bc {
    background-position: -1850px 0px;
}
.joypixels-24-people._1f468-1f527 {
    background-position: -1875px 0px;
}
.joypixels-24-people._1f468-1f52c {
    background-position: -1900px 0px;
}
.joypixels-24-people._1f468-1f680 {
    background-position: -1925px 0px;
}
.joypixels-24-people._1f468-1f692 {
    background-position: -1950px 0px;
}
.joypixels-24-people._1f468-1f9af {
    background-position: -1975px 0px;
}
.joypixels-24-people._1f468-1f9b0 {
    background-position: -2000px 0px;
}
.joypixels-24-people._1f468-1f9b1 {
    background-position: -2025px 0px;
}
.joypixels-24-people._1f468-1f9b2 {
    background-position: -2050px 0px;
}
.joypixels-24-people._1f468-1f9b3 {
    background-position: -2075px 0px;
}
.joypixels-24-people._1f468-1f9bc {
    background-position: -2100px 0px;
}
.joypixels-24-people._1f468-1f9bd {
    background-position: -2125px 0px;
}
.joypixels-24-people._1f468-2695 {
    background-position: -2150px 0px;
}
.joypixels-24-people._1f468-2696 {
    background-position: -2175px 0px;
}
.joypixels-24-people._1f468-2708 {
    background-position: -2200px 0px;
}
.joypixels-24-people._1f468-2764-1f468 {
    background-position: -2225px 0px;
}
.joypixels-24-people._1f468-2764-1f48b-1f468 {
    background-position: -2250px 0px;
}
.joypixels-24-people._1f468 {
    background-position: -2275px 0px;
}
.joypixels-24-people._1f469-1f33e {
    background-position: -2300px 0px;
}
.joypixels-24-people._1f469-1f373 {
    background-position: -2325px 0px;
}
.joypixels-24-people._1f469-1f37c {
    background-position: -2350px 0px;
}
.joypixels-24-people._1f469-1f393 {
    background-position: -2375px 0px;
}
.joypixels-24-people._1f469-1f3a4 {
    background-position: -2400px 0px;
}
.joypixels-24-people._1f469-1f3a8 {
    background-position: -2425px 0px;
}
.joypixels-24-people._1f469-1f3eb {
    background-position: -2450px 0px;
}
.joypixels-24-people._1f469-1f3ed {
    background-position: -2475px 0px;
}
.joypixels-24-people._1f469-1f466-1f466 {
    background-position: -2500px 0px;
}
.joypixels-24-people._1f469-1f466 {
    background-position: -2525px 0px;
}
.joypixels-24-people._1f469-1f467-1f466 {
    background-position: -2550px 0px;
}
.joypixels-24-people._1f469-1f467-1f467 {
    background-position: -2575px 0px;
}
.joypixels-24-people._1f469-1f467 {
    background-position: -2600px 0px;
}
.joypixels-24-people._1f469-1f469-1f466-1f466 {
    background-position: -2625px 0px;
}
.joypixels-24-people._1f469-1f469-1f466 {
    background-position: -2650px 0px;
}
.joypixels-24-people._1f469-1f469-1f467-1f466 {
    background-position: -2675px 0px;
}
.joypixels-24-people._1f469-1f469-1f467-1f467 {
    background-position: -2700px 0px;
}
.joypixels-24-people._1f469-1f469-1f467 {
    background-position: -2725px 0px;
}
.joypixels-24-people._1f469-1f4bb {
    background-position: -2750px 0px;
}
.joypixels-24-people._1f469-1f4bc {
    background-position: -2775px 0px;
}
.joypixels-24-people._1f469-1f527 {
    background-position: -2800px 0px;
}
.joypixels-24-people._1f469-1f52c {
    background-position: -2825px 0px;
}
.joypixels-24-people._1f469-1f680 {
    background-position: -2850px 0px;
}
.joypixels-24-people._1f469-1f692 {
    background-position: -2875px 0px;
}
.joypixels-24-people._1f469-1f9af {
    background-position: -2900px 0px;
}
.joypixels-24-people._1f469-1f9b0 {
    background-position: -2925px 0px;
}
.joypixels-24-people._1f469-1f9b1 {
    background-position: -2950px 0px;
}
.joypixels-24-people._1f469-1f9b2 {
    background-position: -2975px 0px;
}
.joypixels-24-people._1f469-1f9b3 {
    background-position: -3000px 0px;
}
.joypixels-24-people._1f469-1f9bc {
    background-position: -3025px 0px;
}
.joypixels-24-people._1f469-1f9bd {
    background-position: -3050px 0px;
}
.joypixels-24-people._1f469-2695 {
    background-position: -3075px 0px;
}
.joypixels-24-people._1f469-2696 {
    background-position: -3100px 0px;
}
.joypixels-24-people._1f469-2708 {
    background-position: -3125px 0px;
}
.joypixels-24-people._1f469-2764-1f468 {
    background-position: -3150px 0px;
}
.joypixels-24-people._1f469-2764-1f469 {
    background-position: -3175px 0px;
}
.joypixels-24-people._1f469-2764-1f48b-1f468 {
    background-position: -3200px 0px;
}
.joypixels-24-people._1f469-2764-1f48b-1f469 {
    background-position: -3225px 0px;
}
.joypixels-24-people._1f469 {
    background-position: -3250px 0px;
}
.joypixels-24-people._1f46a {
    background-position: -3275px 0px;
}
.joypixels-24-people._1f46b {
    background-position: -3300px 0px;
}
.joypixels-24-people._1f46c {
    background-position: -3325px 0px;
}
.joypixels-24-people._1f46d {
    background-position: -3350px 0px;
}
.joypixels-24-people._1f46e-2640 {
    background-position: -3375px 0px;
}
.joypixels-24-people._1f46e-2642 {
    background-position: -3400px 0px;
}
.joypixels-24-people._1f46e {
    background-position: -3425px 0px;
}
.joypixels-24-people._1f46f-2640 {
    background-position: -3450px 0px;
}
.joypixels-24-people._1f46f-2642 {
    background-position: -3475px 0px;
}
.joypixels-24-people._1f46f {
    background-position: -3500px 0px;
}
.joypixels-24-people._1f470-2640 {
    background-position: -3525px 0px;
}
.joypixels-24-people._1f470-2642 {
    background-position: -3550px 0px;
}
.joypixels-24-people._1f470 {
    background-position: -3575px 0px;
}
.joypixels-24-people._1f471-2640 {
    background-position: -3600px 0px;
}
.joypixels-24-people._1f471-2642 {
    background-position: -3625px 0px;
}
.joypixels-24-people._1f471 {
    background-position: -3650px 0px;
}
.joypixels-24-people._1f472 {
    background-position: -3675px 0px;
}
.joypixels-24-people._1f473-2640 {
    background-position: -3700px 0px;
}
.joypixels-24-people._1f473-2642 {
    background-position: -3725px 0px;
}
.joypixels-24-people._1f473 {
    background-position: -3750px 0px;
}
.joypixels-24-people._1f474 {
    background-position: -3775px 0px;
}
.joypixels-24-people._1f475 {
    background-position: -3800px 0px;
}
.joypixels-24-people._1f476 {
    background-position: -3825px 0px;
}
.joypixels-24-people._1f477-2640 {
    background-position: -3850px 0px;
}
.joypixels-24-people._1f477-2642 {
    background-position: -3875px 0px;
}
.joypixels-24-people._1f477 {
    background-position: -3900px 0px;
}
.joypixels-24-people._1f478 {
    background-position: -3925px 0px;
}
.joypixels-24-people._1f479 {
    background-position: -3950px 0px;
}
.joypixels-24-people._1f47a {
    background-position: -3975px 0px;
}
.joypixels-24-people._1f47b {
    background-position: -4000px 0px;
}
.joypixels-24-people._1f47c {
    background-position: -4025px 0px;
}
.joypixels-24-people._1f47d {
    background-position: -4050px 0px;
}
.joypixels-24-people._1f47e {
    background-position: -4075px 0px;
}
.joypixels-24-people._1f47f {
    background-position: -4100px 0px;
}
.joypixels-24-people._1f480 {
    background-position: -4125px 0px;
}
.joypixels-24-people._1f481-2640 {
    background-position: -4150px 0px;
}
.joypixels-24-people._1f481-2642 {
    background-position: -4175px 0px;
}
.joypixels-24-people._1f481 {
    background-position: -4200px 0px;
}
.joypixels-24-people._1f482-2640 {
    background-position: -4225px 0px;
}
.joypixels-24-people._1f482-2642 {
    background-position: -4250px 0px;
}
.joypixels-24-people._1f482 {
    background-position: -4275px 0px;
}
.joypixels-24-people._1f483 {
    background-position: -4300px 0px;
}
.joypixels-24-people._1f484 {
    background-position: -4325px 0px;
}
.joypixels-24-people._1f485 {
    background-position: -4350px 0px;
}
.joypixels-24-people._1f486-2640 {
    background-position: -4375px 0px;
}
.joypixels-24-people._1f486-2642 {
    background-position: -4400px 0px;
}
.joypixels-24-people._1f486 {
    background-position: -4425px 0px;
}
.joypixels-24-people._1f487-2640 {
    background-position: -4450px 0px;
}
.joypixels-24-people._1f487-2642 {
    background-position: -4475px 0px;
}
.joypixels-24-people._1f487 {
    background-position: -4500px 0px;
}
.joypixels-24-people._1f48b {
    background-position: -4525px 0px;
}
.joypixels-24-people._1f48d {
    background-position: -4550px 0px;
}
.joypixels-24-people._1f48f {
    background-position: -4575px 0px;
}
.joypixels-24-people._1f491 {
    background-position: -4600px 0px;
}
.joypixels-24-people._1f4a9 {
    background-position: -4625px 0px;
}
.joypixels-24-people._1f4aa {
    background-position: -4650px 0px;
}
.joypixels-24-people._1f4bc {
    background-position: -4675px 0px;
}
.joypixels-24-people._1f574 {
    background-position: -4700px 0px;
}
.joypixels-24-people._1f575-2640 {
    background-position: -4725px 0px;
}
.joypixels-24-people._1f575-2642 {
    background-position: -4750px 0px;
}
.joypixels-24-people._1f575 {
    background-position: -4775px 0px;
}
.joypixels-24-people._1f576 {
    background-position: -4800px 0px;
}
.joypixels-24-people._1f57a {
    background-position: -4825px 0px;
}
.joypixels-24-people._1f590 {
    background-position: -4850px 0px;
}
.joypixels-24-people._1f595 {
    background-position: -4875px 0px;
}
.joypixels-24-people._1f596 {
    background-position: -4900px 0px;
}
.joypixels-24-people._1f5e3 {
    background-position: -4925px 0px;
}
.joypixels-24-people._1f600 {
    background-position: -4950px 0px;
}
.joypixels-24-people._1f601 {
    background-position: -4975px 0px;
}
.joypixels-24-people._1f602 {
    background-position: -5000px 0px;
}
.joypixels-24-people._1f603 {
    background-position: -5025px 0px;
}
.joypixels-24-people._1f604 {
    background-position: -5050px 0px;
}
.joypixels-24-people._1f605 {
    background-position: -5075px 0px;
}
.joypixels-24-people._1f606 {
    background-position: -5100px 0px;
}
.joypixels-24-people._1f607 {
    background-position: -5125px 0px;
}
.joypixels-24-people._1f608 {
    background-position: -5150px 0px;
}
.joypixels-24-people._1f609 {
    background-position: -5175px 0px;
}
.joypixels-24-people._1f60a {
    background-position: -5200px 0px;
}
.joypixels-24-people._1f60b {
    background-position: -5225px 0px;
}
.joypixels-24-people._1f60c {
    background-position: -5250px 0px;
}
.joypixels-24-people._1f60d {
    background-position: -5275px 0px;
}
.joypixels-24-people._1f60e {
    background-position: -5300px 0px;
}
.joypixels-24-people._1f60f {
    background-position: -5325px 0px;
}
.joypixels-24-people._1f610 {
    background-position: -5350px 0px;
}
.joypixels-24-people._1f611 {
    background-position: -5375px 0px;
}
.joypixels-24-people._1f612 {
    background-position: -5400px 0px;
}
.joypixels-24-people._1f613 {
    background-position: -5425px 0px;
}
.joypixels-24-people._1f614 {
    background-position: -5450px 0px;
}
.joypixels-24-people._1f615 {
    background-position: -5475px 0px;
}
.joypixels-24-people._1f616 {
    background-position: -5500px 0px;
}
.joypixels-24-people._1f617 {
    background-position: -5525px 0px;
}
.joypixels-24-people._1f618 {
    background-position: -5550px 0px;
}
.joypixels-24-people._1f619 {
    background-position: -5575px 0px;
}
.joypixels-24-people._1f61a {
    background-position: -5600px 0px;
}
.joypixels-24-people._1f61b {
    background-position: -5625px 0px;
}
.joypixels-24-people._1f61c {
    background-position: -5650px 0px;
}
.joypixels-24-people._1f61d {
    background-position: -5675px 0px;
}
.joypixels-24-people._1f61e {
    background-position: -5700px 0px;
}
.joypixels-24-people._1f61f {
    background-position: -5725px 0px;
}
.joypixels-24-people._1f620 {
    background-position: -5750px 0px;
}
.joypixels-24-people._1f621 {
    background-position: -5775px 0px;
}
.joypixels-24-people._1f622 {
    background-position: -5800px 0px;
}
.joypixels-24-people._1f623 {
    background-position: -5825px 0px;
}
.joypixels-24-people._1f624 {
    background-position: -5850px 0px;
}
.joypixels-24-people._1f625 {
    background-position: -5875px 0px;
}
.joypixels-24-people._1f626 {
    background-position: -5900px 0px;
}
.joypixels-24-people._1f627 {
    background-position: -5925px 0px;
}
.joypixels-24-people._1f628 {
    background-position: -5950px 0px;
}
.joypixels-24-people._1f629 {
    background-position: -5975px 0px;
}
.joypixels-24-people._1f62a {
    background-position: -6000px 0px;
}
.joypixels-24-people._1f62b {
    background-position: -6025px 0px;
}
.joypixels-24-people._1f62c {
    background-position: -6050px 0px;
}
.joypixels-24-people._1f62d {
    background-position: -6075px 0px;
}
.joypixels-24-people._1f62e-1f4a8 {
    background-position: -6100px 0px;
}
.joypixels-24-people._1f62e {
    background-position: -6125px 0px;
}
.joypixels-24-people._1f62f {
    background-position: -6150px 0px;
}
.joypixels-24-people._1f630 {
    background-position: -6175px 0px;
}
.joypixels-24-people._1f631 {
    background-position: -6200px 0px;
}
.joypixels-24-people._1f632 {
    background-position: -6225px 0px;
}
.joypixels-24-people._1f633 {
    background-position: -6250px 0px;
}
.joypixels-24-people._1f634 {
    background-position: -6275px 0px;
}
.joypixels-24-people._1f635-1f4ab {
    background-position: -6300px 0px;
}
.joypixels-24-people._1f635 {
    background-position: -6325px 0px;
}
.joypixels-24-people._1f636-1f32b {
    background-position: -6350px 0px;
}
.joypixels-24-people._1f636 {
    background-position: -6375px 0px;
}
.joypixels-24-people._1f637 {
    background-position: -6400px 0px;
}
.joypixels-24-people._1f638 {
    background-position: -6425px 0px;
}
.joypixels-24-people._1f639 {
    background-position: -6450px 0px;
}
.joypixels-24-people._1f63a {
    background-position: -6475px 0px;
}
.joypixels-24-people._1f63b {
    background-position: -6500px 0px;
}
.joypixels-24-people._1f63c {
    background-position: -6525px 0px;
}
.joypixels-24-people._1f63d {
    background-position: -6550px 0px;
}
.joypixels-24-people._1f63e {
    background-position: -6575px 0px;
}
.joypixels-24-people._1f63f {
    background-position: -6600px 0px;
}
.joypixels-24-people._1f640 {
    background-position: -6625px 0px;
}
.joypixels-24-people._1f641 {
    background-position: -6650px 0px;
}
.joypixels-24-people._1f642 {
    background-position: -6675px 0px;
}
.joypixels-24-people._1f643 {
    background-position: -6700px 0px;
}
.joypixels-24-people._1f644 {
    background-position: -6725px 0px;
}
.joypixels-24-people._1f645-2640 {
    background-position: -6750px 0px;
}
.joypixels-24-people._1f645-2642 {
    background-position: -6775px 0px;
}
.joypixels-24-people._1f645 {
    background-position: -6800px 0px;
}
.joypixels-24-people._1f646-2640 {
    background-position: -6825px 0px;
}
.joypixels-24-people._1f646-2642 {
    background-position: -6850px 0px;
}
.joypixels-24-people._1f646 {
    background-position: -6875px 0px;
}
.joypixels-24-people._1f647-2640 {
    background-position: -6900px 0px;
}
.joypixels-24-people._1f647-2642 {
    background-position: -6925px 0px;
}
.joypixels-24-people._1f647 {
    background-position: -6950px 0px;
}
.joypixels-24-people._1f64b-2640 {
    background-position: -6975px 0px;
}
.joypixels-24-people._1f64b-2642 {
    background-position: -7000px 0px;
}
.joypixels-24-people._1f64b {
    background-position: -7025px 0px;
}
.joypixels-24-people._1f64c {
    background-position: -7050px 0px;
}
.joypixels-24-people._1f64d-2640 {
    background-position: -7075px 0px;
}
.joypixels-24-people._1f64d-2642 {
    background-position: -7100px 0px;
}
.joypixels-24-people._1f64d {
    background-position: -7125px 0px;
}
.joypixels-24-people._1f64e-2640 {
    background-position: -7150px 0px;
}
.joypixels-24-people._1f64e-2642 {
    background-position: -7175px 0px;
}
.joypixels-24-people._1f64e {
    background-position: -7200px 0px;
}
.joypixels-24-people._1f64f {
    background-position: -7225px 0px;
}
.joypixels-24-people._1f6b6-2640 {
    background-position: -7250px 0px;
}
.joypixels-24-people._1f6b6-2642 {
    background-position: -7275px 0px;
}
.joypixels-24-people._1f6b6 {
    background-position: -7300px 0px;
}
.joypixels-24-people._1f90c {
    background-position: -7325px 0px;
}
.joypixels-24-people._1f90f {
    background-position: -7350px 0px;
}
.joypixels-24-people._1f910 {
    background-position: -7375px 0px;
}
.joypixels-24-people._1f911 {
    background-position: -7400px 0px;
}
.joypixels-24-people._1f912 {
    background-position: -7425px 0px;
}
.joypixels-24-people._1f913 {
    background-position: -7450px 0px;
}
.joypixels-24-people._1f914 {
    background-position: -7475px 0px;
}
.joypixels-24-people._1f915 {
    background-position: -7500px 0px;
}
.joypixels-24-people._1f916 {
    background-position: -7525px 0px;
}
.joypixels-24-people._1f917 {
    background-position: -7550px 0px;
}
.joypixels-24-people._1f918 {
    background-position: -7575px 0px;
}
.joypixels-24-people._1f919 {
    background-position: -7600px 0px;
}
.joypixels-24-people._1f91a {
    background-position: -7625px 0px;
}
.joypixels-24-people._1f91b {
    background-position: -7650px 0px;
}
.joypixels-24-people._1f91c {
    background-position: -7675px 0px;
}
.joypixels-24-people._1f91d {
    background-position: -7700px 0px;
}
.joypixels-24-people._1f91e {
    background-position: -7725px 0px;
}
.joypixels-24-people._1f91f {
    background-position: -7750px 0px;
}
.joypixels-24-people._1f920 {
    background-position: -7775px 0px;
}
.joypixels-24-people._1f921 {
    background-position: -7800px 0px;
}
.joypixels-24-people._1f922 {
    background-position: -7825px 0px;
}
.joypixels-24-people._1f923 {
    background-position: -7850px 0px;
}
.joypixels-24-people._1f924 {
    background-position: -7875px 0px;
}
.joypixels-24-people._1f925 {
    background-position: -7900px 0px;
}
.joypixels-24-people._1f926-2640 {
    background-position: -7925px 0px;
}
.joypixels-24-people._1f926-2642 {
    background-position: -7950px 0px;
}
.joypixels-24-people._1f926 {
    background-position: -7975px 0px;
}
.joypixels-24-people._1f927 {
    background-position: -8000px 0px;
}
.joypixels-24-people._1f928 {
    background-position: -8025px 0px;
}
.joypixels-24-people._1f929 {
    background-position: -8050px 0px;
}
.joypixels-24-people._1f92a {
    background-position: -8075px 0px;
}
.joypixels-24-people._1f92b {
    background-position: -8100px 0px;
}
.joypixels-24-people._1f92c {
    background-position: -8125px 0px;
}
.joypixels-24-people._1f92d {
    background-position: -8150px 0px;
}
.joypixels-24-people._1f92e {
    background-position: -8175px 0px;
}
.joypixels-24-people._1f92f {
    background-position: -8200px 0px;
}
.joypixels-24-people._1f930 {
    background-position: -8225px 0px;
}
.joypixels-24-people._1f931 {
    background-position: -8250px 0px;
}
.joypixels-24-people._1f932 {
    background-position: -8275px 0px;
}
.joypixels-24-people._1f933 {
    background-position: -8300px 0px;
}
.joypixels-24-people._1f934 {
    background-position: -8325px 0px;
}
.joypixels-24-people._1f935-2640 {
    background-position: -8350px 0px;
}
.joypixels-24-people._1f935-2642 {
    background-position: -8375px 0px;
}
.joypixels-24-people._1f935 {
    background-position: -8400px 0px;
}
.joypixels-24-people._1f936 {
    background-position: -8425px 0px;
}
.joypixels-24-people._1f937-2640 {
    background-position: -8450px 0px;
}
.joypixels-24-people._1f937-2642 {
    background-position: -8475px 0px;
}
.joypixels-24-people._1f937 {
    background-position: -8500px 0px;
}
.joypixels-24-people._1f970 {
    background-position: -8525px 0px;
}
.joypixels-24-people._1f971 {
    background-position: -8550px 0px;
}
.joypixels-24-people._1f972 {
    background-position: -8575px 0px;
}
.joypixels-24-people._1f973 {
    background-position: -8600px 0px;
}
.joypixels-24-people._1f974 {
    background-position: -8625px 0px;
}
.joypixels-24-people._1f975 {
    background-position: -8650px 0px;
}
.joypixels-24-people._1f976 {
    background-position: -8675px 0px;
}
.joypixels-24-people._1f977 {
    background-position: -8700px 0px;
}
.joypixels-24-people._1f978 {
    background-position: -8725px 0px;
}
.joypixels-24-people._1f979 {
    background-position: -8750px 0px;
}
.joypixels-24-people._1f97a {
    background-position: -8775px 0px;
}
.joypixels-24-people._1f97b {
    background-position: -8800px 0px;
}
.joypixels-24-people._1f97c {
    background-position: -8825px 0px;
}
.joypixels-24-people._1f97d {
    background-position: -8850px 0px;
}
.joypixels-24-people._1f97e {
    background-position: -8875px 0px;
}
.joypixels-24-people._1f97f {
    background-position: -8900px 0px;
}
.joypixels-24-people._1f9b0 {
    background-position: -8925px 0px;
}
.joypixels-24-people._1f9b1 {
    background-position: -8950px 0px;
}
.joypixels-24-people._1f9b2 {
    background-position: -8975px 0px;
}
.joypixels-24-people._1f9b3 {
    background-position: -9000px 0px;
}
.joypixels-24-people._1f9b5 {
    background-position: -9025px 0px;
}
.joypixels-24-people._1f9b6 {
    background-position: -9050px 0px;
}
.joypixels-24-people._1f9b7 {
    background-position: -9075px 0px;
}
.joypixels-24-people._1f9b8-2640 {
    background-position: -9100px 0px;
}
.joypixels-24-people._1f9b8-2642 {
    background-position: -9125px 0px;
}
.joypixels-24-people._1f9b8 {
    background-position: -9150px 0px;
}
.joypixels-24-people._1f9b9-2640 {
    background-position: -9175px 0px;
}
.joypixels-24-people._1f9b9-2642 {
    background-position: -9200px 0px;
}
.joypixels-24-people._1f9b9 {
    background-position: -9225px 0px;
}
.joypixels-24-people._1f9ba {
    background-position: -9250px 0px;
}
.joypixels-24-people._1f9bb {
    background-position: -9275px 0px;
}
.joypixels-24-people._1f9be {
    background-position: -9300px 0px;
}
.joypixels-24-people._1f9bf {
    background-position: -9325px 0px;
}
.joypixels-24-people._1f9cc {
    background-position: -9350px 0px;
}
.joypixels-24-people._1f9cd-2640 {
    background-position: -9375px 0px;
}
.joypixels-24-people._1f9cd-2642 {
    background-position: -9400px 0px;
}
.joypixels-24-people._1f9cd {
    background-position: -9425px 0px;
}
.joypixels-24-people._1f9ce-2640 {
    background-position: -9450px 0px;
}
.joypixels-24-people._1f9ce-2642 {
    background-position: -9475px 0px;
}
.joypixels-24-people._1f9ce {
    background-position: -9500px 0px;
}
.joypixels-24-people._1f9cf-2640 {
    background-position: -9525px 0px;
}
.joypixels-24-people._1f9cf-2642 {
    background-position: -9550px 0px;
}
.joypixels-24-people._1f9cf {
    background-position: -9575px 0px;
}
.joypixels-24-people._1f9d0 {
    background-position: -9600px 0px;
}
.joypixels-24-people._1f9d1-1f33e {
    background-position: -9625px 0px;
}
.joypixels-24-people._1f9d1-1f373 {
    background-position: -9650px 0px;
}
.joypixels-24-people._1f9d1-1f37c {
    background-position: -9675px 0px;
}
.joypixels-24-people._1f9d1-1f384 {
    background-position: -9700px 0px;
}
.joypixels-24-people._1f9d1-1f393 {
    background-position: -9725px 0px;
}
.joypixels-24-people._1f9d1-1f3a4 {
    background-position: -9750px 0px;
}
.joypixels-24-people._1f9d1-1f3a8 {
    background-position: -9775px 0px;
}
.joypixels-24-people._1f9d1-1f3eb {
    background-position: -9800px 0px;
}
.joypixels-24-people._1f9d1-1f3ed {
    background-position: -9825px 0px;
}
.joypixels-24-people._1f9d1-1f4bb {
    background-position: -9850px 0px;
}
.joypixels-24-people._1f9d1-1f4bc {
    background-position: -9875px 0px;
}
.joypixels-24-people._1f9d1-1f527 {
    background-position: -9900px 0px;
}
.joypixels-24-people._1f9d1-1f52c {
    background-position: -9925px 0px;
}
.joypixels-24-people._1f9d1-1f680 {
    background-position: -9950px 0px;
}
.joypixels-24-people._1f9d1-1f692 {
    background-position: -9975px 0px;
}
.joypixels-24-people._1f9d1-1f91d-1f9d1 {
    background-position: -10000px 0px;
}
.joypixels-24-people._1f9d1-1f9af {
    background-position: -10025px 0px;
}
.joypixels-24-people._1f9d1-1f9b0 {
    background-position: -10050px 0px;
}
.joypixels-24-people._1f9d1-1f9b1 {
    background-position: -10075px 0px;
}
.joypixels-24-people._1f9d1-1f9b2 {
    background-position: -10100px 0px;
}
.joypixels-24-people._1f9d1-1f9b3 {
    background-position: -10125px 0px;
}
.joypixels-24-people._1f9d1-1f9bc {
    background-position: -10150px 0px;
}
.joypixels-24-people._1f9d1-1f9bd {
    background-position: -10175px 0px;
}
.joypixels-24-people._1f9d1-2695 {
    background-position: -10200px 0px;
}
.joypixels-24-people._1f9d1-2696 {
    background-position: -10225px 0px;
}
.joypixels-24-people._1f9d1-2708 {
    background-position: -10250px 0px;
}
.joypixels-24-people._1f9d1 {
    background-position: -10275px 0px;
}
.joypixels-24-people._1f9d2 {
    background-position: -10300px 0px;
}
.joypixels-24-people._1f9d3 {
    background-position: -10325px 0px;
}
.joypixels-24-people._1f9d4-2640 {
    background-position: -10350px 0px;
}
.joypixels-24-people._1f9d4-2642 {
    background-position: -10375px 0px;
}
.joypixels-24-people._1f9d4 {
    background-position: -10400px 0px;
}
.joypixels-24-people._1f9d5 {
    background-position: -10425px 0px;
}
.joypixels-24-people._1f9d6-2640 {
    background-position: -10450px 0px;
}
.joypixels-24-people._1f9d6-2642 {
    background-position: -10475px 0px;
}
.joypixels-24-people._1f9d6 {
    background-position: -10500px 0px;
}
.joypixels-24-people._1f9d9-2640 {
    background-position: -10525px 0px;
}
.joypixels-24-people._1f9d9-2642 {
    background-position: -10550px 0px;
}
.joypixels-24-people._1f9d9 {
    background-position: -10575px 0px;
}
.joypixels-24-people._1f9da-2640 {
    background-position: -10600px 0px;
}
.joypixels-24-people._1f9da-2642 {
    background-position: -10625px 0px;
}
.joypixels-24-people._1f9da {
    background-position: -10650px 0px;
}
.joypixels-24-people._1f9db-2640 {
    background-position: -10675px 0px;
}
.joypixels-24-people._1f9db-2642 {
    background-position: -10700px 0px;
}
.joypixels-24-people._1f9db {
    background-position: -10725px 0px;
}
.joypixels-24-people._1f9dc-2640 {
    background-position: -10750px 0px;
}
.joypixels-24-people._1f9dc-2642 {
    background-position: -10775px 0px;
}
.joypixels-24-people._1f9dc {
    background-position: -10800px 0px;
}
.joypixels-24-people._1f9dd-2640 {
    background-position: -10825px 0px;
}
.joypixels-24-people._1f9dd-2642 {
    background-position: -10850px 0px;
}
.joypixels-24-people._1f9dd {
    background-position: -10875px 0px;
}
.joypixels-24-people._1f9de-2640 {
    background-position: -10900px 0px;
}
.joypixels-24-people._1f9de-2642 {
    background-position: -10925px 0px;
}
.joypixels-24-people._1f9de {
    background-position: -10950px 0px;
}
.joypixels-24-people._1f9df-2640 {
    background-position: -10975px 0px;
}
.joypixels-24-people._1f9df-2642 {
    background-position: -11000px 0px;
}
.joypixels-24-people._1f9df {
    background-position: -11025px 0px;
}
.joypixels-24-people._1f9e0 {
    background-position: -11050px 0px;
}
.joypixels-24-people._1f9e2 {
    background-position: -11075px 0px;
}
.joypixels-24-people._1f9e3 {
    background-position: -11100px 0px;
}
.joypixels-24-people._1f9e4 {
    background-position: -11125px 0px;
}
.joypixels-24-people._1f9e5 {
    background-position: -11150px 0px;
}
.joypixels-24-people._1f9e6 {
    background-position: -11175px 0px;
}
.joypixels-24-people._1f9f3 {
    background-position: -11200px 0px;
}
.joypixels-24-people._1f9f5 {
    background-position: -11225px 0px;
}
.joypixels-24-people._1f9f6 {
    background-position: -11250px 0px;
}
.joypixels-24-people._1fa71 {
    background-position: -11275px 0px;
}
.joypixels-24-people._1fa72 {
    background-position: -11300px 0px;
}
.joypixels-24-people._1fa73 {
    background-position: -11325px 0px;
}
.joypixels-24-people._1fa74 {
    background-position: -11350px 0px;
}
.joypixels-24-people._1fa96 {
    background-position: -11375px 0px;
}
.joypixels-24-people._1faa1 {
    background-position: -11400px 0px;
}
.joypixels-24-people._1faa2 {
    background-position: -11425px 0px;
}
.joypixels-24-people._1fac0 {
    background-position: -11450px 0px;
}
.joypixels-24-people._1fac1 {
    background-position: -11475px 0px;
}
.joypixels-24-people._1fac2 {
    background-position: -11500px 0px;
}
.joypixels-24-people._1fac3 {
    background-position: -11525px 0px;
}
.joypixels-24-people._1fac4 {
    background-position: -11550px 0px;
}
.joypixels-24-people._1fac5 {
    background-position: -11575px 0px;
}
.joypixels-24-people._1fae0 {
    background-position: -11600px 0px;
}
.joypixels-24-people._1fae1 {
    background-position: -11625px 0px;
}
.joypixels-24-people._1fae2 {
    background-position: -11650px 0px;
}
.joypixels-24-people._1fae3 {
    background-position: -11675px 0px;
}
.joypixels-24-people._1fae4 {
    background-position: -11700px 0px;
}
.joypixels-24-people._1fae5 {
    background-position: -11725px 0px;
}
.joypixels-24-people._1fae6 {
    background-position: -11750px 0px;
}
.joypixels-24-people._1faf0 {
    background-position: -11775px 0px;
}
.joypixels-24-people._1faf1 {
    background-position: -11800px 0px;
}
.joypixels-24-people._1faf2 {
    background-position: -11825px 0px;
}
.joypixels-24-people._1faf3 {
    background-position: -11850px 0px;
}
.joypixels-24-people._1faf4 {
    background-position: -11875px 0px;
}
.joypixels-24-people._1faf5 {
    background-position: -11900px 0px;
}
.joypixels-24-people._1faf6 {
    background-position: -11925px 0px;
}
.joypixels-24-people._261d {
    background-position: -11950px 0px;
}
.joypixels-24-people._2620 {
    background-position: -11975px 0px;
}
.joypixels-24-people._2639 {
    background-position: -12000px 0px;
}
.joypixels-24-people._263a {
    background-position: -12025px 0px;
}
.joypixels-24-people._26d1 {
    background-position: -12050px 0px;
}
.joypixels-24-people._270a {
    background-position: -12075px 0px;
}
.joypixels-24-people._270b {
    background-position: -12100px 0px;
}
.joypixels-24-people._270c {
    background-position: -12125px 0px;
}
.joypixels-24-people._270d {
    background-position: -12150px 0px;
}

.joypixels-24-nature {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-nature.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-nature {
        background-image: url(joypixels-sprite-24-nature@2x.png);
        background-size: 5124px 24px;
    }
}


.joypixels-24-nature._00000 {
    background-position: 0px 0px;
}
.joypixels-24-nature._1f308 {
    background-position: -25px 0px;
}
.joypixels-24-nature._1f30a {
    background-position: -50px 0px;
}
.joypixels-24-nature._1f30d {
    background-position: -75px 0px;
}
.joypixels-24-nature._1f30e {
    background-position: -100px 0px;
}
.joypixels-24-nature._1f30f {
    background-position: -125px 0px;
}
.joypixels-24-nature._1f311 {
    background-position: -150px 0px;
}
.joypixels-24-nature._1f312 {
    background-position: -175px 0px;
}
.joypixels-24-nature._1f313 {
    background-position: -200px 0px;
}
.joypixels-24-nature._1f314 {
    background-position: -225px 0px;
}
.joypixels-24-nature._1f315 {
    background-position: -250px 0px;
}
.joypixels-24-nature._1f316 {
    background-position: -275px 0px;
}
.joypixels-24-nature._1f317 {
    background-position: -300px 0px;
}
.joypixels-24-nature._1f318 {
    background-position: -325px 0px;
}
.joypixels-24-nature._1f319 {
    background-position: -350px 0px;
}
.joypixels-24-nature._1f31a {
    background-position: -375px 0px;
}
.joypixels-24-nature._1f31b {
    background-position: -400px 0px;
}
.joypixels-24-nature._1f31c {
    background-position: -425px 0px;
}
.joypixels-24-nature._1f31d {
    background-position: -450px 0px;
}
.joypixels-24-nature._1f31e {
    background-position: -475px 0px;
}
.joypixels-24-nature._1f31f {
    background-position: -500px 0px;
}
.joypixels-24-nature._1f324 {
    background-position: -525px 0px;
}
.joypixels-24-nature._1f325 {
    background-position: -550px 0px;
}
.joypixels-24-nature._1f326 {
    background-position: -575px 0px;
}
.joypixels-24-nature._1f327 {
    background-position: -600px 0px;
}
.joypixels-24-nature._1f328 {
    background-position: -625px 0px;
}
.joypixels-24-nature._1f329 {
    background-position: -650px 0px;
}
.joypixels-24-nature._1f32a {
    background-position: -675px 0px;
}
.joypixels-24-nature._1f32b {
    background-position: -700px 0px;
}
.joypixels-24-nature._1f32c {
    background-position: -725px 0px;
}
.joypixels-24-nature._1f331 {
    background-position: -750px 0px;
}
.joypixels-24-nature._1f332 {
    background-position: -775px 0px;
}
.joypixels-24-nature._1f333 {
    background-position: -800px 0px;
}
.joypixels-24-nature._1f334 {
    background-position: -825px 0px;
}
.joypixels-24-nature._1f335 {
    background-position: -850px 0px;
}
.joypixels-24-nature._1f337 {
    background-position: -875px 0px;
}
.joypixels-24-nature._1f338 {
    background-position: -900px 0px;
}
.joypixels-24-nature._1f339 {
    background-position: -925px 0px;
}
.joypixels-24-nature._1f33a {
    background-position: -950px 0px;
}
.joypixels-24-nature._1f33b {
    background-position: -975px 0px;
}
.joypixels-24-nature._1f33c {
    background-position: -1000px 0px;
}
.joypixels-24-nature._1f33e {
    background-position: -1025px 0px;
}
.joypixels-24-nature._1f33f {
    background-position: -1050px 0px;
}
.joypixels-24-nature._1f340 {
    background-position: -1075px 0px;
}
.joypixels-24-nature._1f341 {
    background-position: -1100px 0px;
}
.joypixels-24-nature._1f342 {
    background-position: -1125px 0px;
}
.joypixels-24-nature._1f343 {
    background-position: -1150px 0px;
}
.joypixels-24-nature._1f344 {
    background-position: -1175px 0px;
}
.joypixels-24-nature._1f384 {
    background-position: -1200px 0px;
}
.joypixels-24-nature._1f38b {
    background-position: -1225px 0px;
}
.joypixels-24-nature._1f38d {
    background-position: -1250px 0px;
}
.joypixels-24-nature._1f400 {
    background-position: -1275px 0px;
}
.joypixels-24-nature._1f401 {
    background-position: -1300px 0px;
}
.joypixels-24-nature._1f402 {
    background-position: -1325px 0px;
}
.joypixels-24-nature._1f403 {
    background-position: -1350px 0px;
}
.joypixels-24-nature._1f404 {
    background-position: -1375px 0px;
}
.joypixels-24-nature._1f405 {
    background-position: -1400px 0px;
}
.joypixels-24-nature._1f406 {
    background-position: -1425px 0px;
}
.joypixels-24-nature._1f407 {
    background-position: -1450px 0px;
}
.joypixels-24-nature._1f408-2b1b {
    background-position: -1475px 0px;
}
.joypixels-24-nature._1f408 {
    background-position: -1500px 0px;
}
.joypixels-24-nature._1f409 {
    background-position: -1525px 0px;
}
.joypixels-24-nature._1f40a {
    background-position: -1550px 0px;
}
.joypixels-24-nature._1f40b {
    background-position: -1575px 0px;
}
.joypixels-24-nature._1f40c {
    background-position: -1600px 0px;
}
.joypixels-24-nature._1f40d {
    background-position: -1625px 0px;
}
.joypixels-24-nature._1f40e {
    background-position: -1650px 0px;
}
.joypixels-24-nature._1f40f {
    background-position: -1675px 0px;
}
.joypixels-24-nature._1f410 {
    background-position: -1700px 0px;
}
.joypixels-24-nature._1f411 {
    background-position: -1725px 0px;
}
.joypixels-24-nature._1f412 {
    background-position: -1750px 0px;
}
.joypixels-24-nature._1f413 {
    background-position: -1775px 0px;
}
.joypixels-24-nature._1f414 {
    background-position: -1800px 0px;
}
.joypixels-24-nature._1f415-1f9ba {
    background-position: -1825px 0px;
}
.joypixels-24-nature._1f415 {
    background-position: -1850px 0px;
}
.joypixels-24-nature._1f416 {
    background-position: -1875px 0px;
}
.joypixels-24-nature._1f417 {
    background-position: -1900px 0px;
}
.joypixels-24-nature._1f418 {
    background-position: -1925px 0px;
}
.joypixels-24-nature._1f419 {
    background-position: -1950px 0px;
}
.joypixels-24-nature._1f41a {
    background-position: -1975px 0px;
}
.joypixels-24-nature._1f41b {
    background-position: -2000px 0px;
}
.joypixels-24-nature._1f41c {
    background-position: -2025px 0px;
}
.joypixels-24-nature._1f41d {
    background-position: -2050px 0px;
}
.joypixels-24-nature._1f41e {
    background-position: -2075px 0px;
}
.joypixels-24-nature._1f41f {
    background-position: -2100px 0px;
}
.joypixels-24-nature._1f420 {
    background-position: -2125px 0px;
}
.joypixels-24-nature._1f421 {
    background-position: -2150px 0px;
}
.joypixels-24-nature._1f422 {
    background-position: -2175px 0px;
}
.joypixels-24-nature._1f423 {
    background-position: -2200px 0px;
}
.joypixels-24-nature._1f424 {
    background-position: -2225px 0px;
}
.joypixels-24-nature._1f425 {
    background-position: -2250px 0px;
}
.joypixels-24-nature._1f426 {
    background-position: -2275px 0px;
}
.joypixels-24-nature._1f427 {
    background-position: -2300px 0px;
}
.joypixels-24-nature._1f428 {
    background-position: -2325px 0px;
}
.joypixels-24-nature._1f429 {
    background-position: -2350px 0px;
}
.joypixels-24-nature._1f42a {
    background-position: -2375px 0px;
}
.joypixels-24-nature._1f42b {
    background-position: -2400px 0px;
}
.joypixels-24-nature._1f42c {
    background-position: -2425px 0px;
}
.joypixels-24-nature._1f42d {
    background-position: -2450px 0px;
}
.joypixels-24-nature._1f42e {
    background-position: -2475px 0px;
}
.joypixels-24-nature._1f42f {
    background-position: -2500px 0px;
}
.joypixels-24-nature._1f430 {
    background-position: -2525px 0px;
}
.joypixels-24-nature._1f431 {
    background-position: -2550px 0px;
}
.joypixels-24-nature._1f432 {
    background-position: -2575px 0px;
}
.joypixels-24-nature._1f433 {
    background-position: -2600px 0px;
}
.joypixels-24-nature._1f434 {
    background-position: -2625px 0px;
}
.joypixels-24-nature._1f435 {
    background-position: -2650px 0px;
}
.joypixels-24-nature._1f436 {
    background-position: -2675px 0px;
}
.joypixels-24-nature._1f437 {
    background-position: -2700px 0px;
}
.joypixels-24-nature._1f438 {
    background-position: -2725px 0px;
}
.joypixels-24-nature._1f439 {
    background-position: -2750px 0px;
}
.joypixels-24-nature._1f43a {
    background-position: -2775px 0px;
}
.joypixels-24-nature._1f43b-2744 {
    background-position: -2800px 0px;
}
.joypixels-24-nature._1f43b {
    background-position: -2825px 0px;
}
.joypixels-24-nature._1f43c {
    background-position: -2850px 0px;
}
.joypixels-24-nature._1f43d {
    background-position: -2875px 0px;
}
.joypixels-24-nature._1f43e {
    background-position: -2900px 0px;
}
.joypixels-24-nature._1f43f {
    background-position: -2925px 0px;
}
.joypixels-24-nature._1f490 {
    background-position: -2950px 0px;
}
.joypixels-24-nature._1f4a5 {
    background-position: -2975px 0px;
}
.joypixels-24-nature._1f4a6 {
    background-position: -3000px 0px;
}
.joypixels-24-nature._1f4a7 {
    background-position: -3025px 0px;
}
.joypixels-24-nature._1f4a8 {
    background-position: -3050px 0px;
}
.joypixels-24-nature._1f4ab {
    background-position: -3075px 0px;
}
.joypixels-24-nature._1f525 {
    background-position: -3100px 0px;
}
.joypixels-24-nature._1f54a {
    background-position: -3125px 0px;
}
.joypixels-24-nature._1f577 {
    background-position: -3150px 0px;
}
.joypixels-24-nature._1f578 {
    background-position: -3175px 0px;
}
.joypixels-24-nature._1f648 {
    background-position: -3200px 0px;
}
.joypixels-24-nature._1f649 {
    background-position: -3225px 0px;
}
.joypixels-24-nature._1f64a {
    background-position: -3250px 0px;
}
.joypixels-24-nature._1f940 {
    background-position: -3275px 0px;
}
.joypixels-24-nature._1f980 {
    background-position: -3300px 0px;
}
.joypixels-24-nature._1f981 {
    background-position: -3325px 0px;
}
.joypixels-24-nature._1f982 {
    background-position: -3350px 0px;
}
.joypixels-24-nature._1f983 {
    background-position: -3375px 0px;
}
.joypixels-24-nature._1f984 {
    background-position: -3400px 0px;
}
.joypixels-24-nature._1f985 {
    background-position: -3425px 0px;
}
.joypixels-24-nature._1f986 {
    background-position: -3450px 0px;
}
.joypixels-24-nature._1f987 {
    background-position: -3475px 0px;
}
.joypixels-24-nature._1f988 {
    background-position: -3500px 0px;
}
.joypixels-24-nature._1f989 {
    background-position: -3525px 0px;
}
.joypixels-24-nature._1f98a {
    background-position: -3550px 0px;
}
.joypixels-24-nature._1f98b {
    background-position: -3575px 0px;
}
.joypixels-24-nature._1f98c {
    background-position: -3600px 0px;
}
.joypixels-24-nature._1f98d {
    background-position: -3625px 0px;
}
.joypixels-24-nature._1f98e {
    background-position: -3650px 0px;
}
.joypixels-24-nature._1f98f {
    background-position: -3675px 0px;
}
.joypixels-24-nature._1f990 {
    background-position: -3700px 0px;
}
.joypixels-24-nature._1f991 {
    background-position: -3725px 0px;
}
.joypixels-24-nature._1f992 {
    background-position: -3750px 0px;
}
.joypixels-24-nature._1f993 {
    background-position: -3775px 0px;
}
.joypixels-24-nature._1f994 {
    background-position: -3800px 0px;
}
.joypixels-24-nature._1f995 {
    background-position: -3825px 0px;
}
.joypixels-24-nature._1f996 {
    background-position: -3850px 0px;
}
.joypixels-24-nature._1f997 {
    background-position: -3875px 0px;
}
.joypixels-24-nature._1f998 {
    background-position: -3900px 0px;
}
.joypixels-24-nature._1f999 {
    background-position: -3925px 0px;
}
.joypixels-24-nature._1f99a {
    background-position: -3950px 0px;
}
.joypixels-24-nature._1f99b {
    background-position: -3975px 0px;
}
.joypixels-24-nature._1f99c {
    background-position: -4000px 0px;
}
.joypixels-24-nature._1f99d {
    background-position: -4025px 0px;
}
.joypixels-24-nature._1f99e {
    background-position: -4050px 0px;
}
.joypixels-24-nature._1f99f {
    background-position: -4075px 0px;
}
.joypixels-24-nature._1f9a1 {
    background-position: -4100px 0px;
}
.joypixels-24-nature._1f9a2 {
    background-position: -4125px 0px;
}
.joypixels-24-nature._1f9a3 {
    background-position: -4150px 0px;
}
.joypixels-24-nature._1f9a4 {
    background-position: -4175px 0px;
}
.joypixels-24-nature._1f9a5 {
    background-position: -4200px 0px;
}
.joypixels-24-nature._1f9a6 {
    background-position: -4225px 0px;
}
.joypixels-24-nature._1f9a7 {
    background-position: -4250px 0px;
}
.joypixels-24-nature._1f9a8 {
    background-position: -4275px 0px;
}
.joypixels-24-nature._1f9a9 {
    background-position: -4300px 0px;
}
.joypixels-24-nature._1f9ab {
    background-position: -4325px 0px;
}
.joypixels-24-nature._1f9ac {
    background-position: -4350px 0px;
}
.joypixels-24-nature._1f9ad {
    background-position: -4375px 0px;
}
.joypixels-24-nature._1f9ae {
    background-position: -4400px 0px;
}
.joypixels-24-nature._1fa90 {
    background-position: -4425px 0px;
}
.joypixels-24-nature._1faa8 {
    background-position: -4450px 0px;
}
.joypixels-24-nature._1fab0 {
    background-position: -4475px 0px;
}
.joypixels-24-nature._1fab1 {
    background-position: -4500px 0px;
}
.joypixels-24-nature._1fab2 {
    background-position: -4525px 0px;
}
.joypixels-24-nature._1fab3 {
    background-position: -4550px 0px;
}
.joypixels-24-nature._1fab4 {
    background-position: -4575px 0px;
}
.joypixels-24-nature._1fab5 {
    background-position: -4600px 0px;
}
.joypixels-24-nature._1fab6 {
    background-position: -4625px 0px;
}
.joypixels-24-nature._1fab7 {
    background-position: -4650px 0px;
}
.joypixels-24-nature._1fab8 {
    background-position: -4675px 0px;
}
.joypixels-24-nature._1fab9 {
    background-position: -4700px 0px;
}
.joypixels-24-nature._1faba {
    background-position: -4725px 0px;
}
.joypixels-24-nature._1fae7 {
    background-position: -4750px 0px;
}
.joypixels-24-nature._2600 {
    background-position: -4775px 0px;
}
.joypixels-24-nature._2601 {
    background-position: -4800px 0px;
}
.joypixels-24-nature._2602 {
    background-position: -4825px 0px;
}
.joypixels-24-nature._2603 {
    background-position: -4850px 0px;
}
.joypixels-24-nature._2604 {
    background-position: -4875px 0px;
}
.joypixels-24-nature._2614 {
    background-position: -4900px 0px;
}
.joypixels-24-nature._2618 {
    background-position: -4925px 0px;
}
.joypixels-24-nature._26a1 {
    background-position: -4950px 0px;
}
.joypixels-24-nature._26c4 {
    background-position: -4975px 0px;
}
.joypixels-24-nature._26c5 {
    background-position: -5000px 0px;
}
.joypixels-24-nature._26c8 {
    background-position: -5025px 0px;
}
.joypixels-24-nature._2728 {
    background-position: -5050px 0px;
}
.joypixels-24-nature._2744 {
    background-position: -5075px 0px;
}
.joypixels-24-nature._2b50 {
    background-position: -5100px 0px;
}

.joypixels-24-food {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-food.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-food {
        background-image: url(joypixels-sprite-24-food@2x.png);
        background-size: 3174px 24px;
    }
}


.joypixels-24-food._00000 {
    background-position: 0px 0px;
}
.joypixels-24-food._1f32d {
    background-position: -25px 0px;
}
.joypixels-24-food._1f32e {
    background-position: -50px 0px;
}
.joypixels-24-food._1f32f {
    background-position: -75px 0px;
}
.joypixels-24-food._1f330 {
    background-position: -100px 0px;
}
.joypixels-24-food._1f336 {
    background-position: -125px 0px;
}
.joypixels-24-food._1f33d {
    background-position: -150px 0px;
}
.joypixels-24-food._1f345 {
    background-position: -175px 0px;
}
.joypixels-24-food._1f346 {
    background-position: -200px 0px;
}
.joypixels-24-food._1f347 {
    background-position: -225px 0px;
}
.joypixels-24-food._1f348 {
    background-position: -250px 0px;
}
.joypixels-24-food._1f349 {
    background-position: -275px 0px;
}
.joypixels-24-food._1f34a {
    background-position: -300px 0px;
}
.joypixels-24-food._1f34b {
    background-position: -325px 0px;
}
.joypixels-24-food._1f34c {
    background-position: -350px 0px;
}
.joypixels-24-food._1f34d {
    background-position: -375px 0px;
}
.joypixels-24-food._1f34e {
    background-position: -400px 0px;
}
.joypixels-24-food._1f34f {
    background-position: -425px 0px;
}
.joypixels-24-food._1f350 {
    background-position: -450px 0px;
}
.joypixels-24-food._1f351 {
    background-position: -475px 0px;
}
.joypixels-24-food._1f352 {
    background-position: -500px 0px;
}
.joypixels-24-food._1f353 {
    background-position: -525px 0px;
}
.joypixels-24-food._1f354 {
    background-position: -550px 0px;
}
.joypixels-24-food._1f355 {
    background-position: -575px 0px;
}
.joypixels-24-food._1f356 {
    background-position: -600px 0px;
}
.joypixels-24-food._1f357 {
    background-position: -625px 0px;
}
.joypixels-24-food._1f358 {
    background-position: -650px 0px;
}
.joypixels-24-food._1f359 {
    background-position: -675px 0px;
}
.joypixels-24-food._1f35a {
    background-position: -700px 0px;
}
.joypixels-24-food._1f35b {
    background-position: -725px 0px;
}
.joypixels-24-food._1f35c {
    background-position: -750px 0px;
}
.joypixels-24-food._1f35d {
    background-position: -775px 0px;
}
.joypixels-24-food._1f35e {
    background-position: -800px 0px;
}
.joypixels-24-food._1f35f {
    background-position: -825px 0px;
}
.joypixels-24-food._1f360 {
    background-position: -850px 0px;
}
.joypixels-24-food._1f361 {
    background-position: -875px 0px;
}
.joypixels-24-food._1f362 {
    background-position: -900px 0px;
}
.joypixels-24-food._1f363 {
    background-position: -925px 0px;
}
.joypixels-24-food._1f364 {
    background-position: -950px 0px;
}
.joypixels-24-food._1f365 {
    background-position: -975px 0px;
}
.joypixels-24-food._1f366 {
    background-position: -1000px 0px;
}
.joypixels-24-food._1f367 {
    background-position: -1025px 0px;
}
.joypixels-24-food._1f368 {
    background-position: -1050px 0px;
}
.joypixels-24-food._1f369 {
    background-position: -1075px 0px;
}
.joypixels-24-food._1f36a {
    background-position: -1100px 0px;
}
.joypixels-24-food._1f36b {
    background-position: -1125px 0px;
}
.joypixels-24-food._1f36c {
    background-position: -1150px 0px;
}
.joypixels-24-food._1f36d {
    background-position: -1175px 0px;
}
.joypixels-24-food._1f36e {
    background-position: -1200px 0px;
}
.joypixels-24-food._1f36f {
    background-position: -1225px 0px;
}
.joypixels-24-food._1f370 {
    background-position: -1250px 0px;
}
.joypixels-24-food._1f371 {
    background-position: -1275px 0px;
}
.joypixels-24-food._1f372 {
    background-position: -1300px 0px;
}
.joypixels-24-food._1f373 {
    background-position: -1325px 0px;
}
.joypixels-24-food._1f374 {
    background-position: -1350px 0px;
}
.joypixels-24-food._1f375 {
    background-position: -1375px 0px;
}
.joypixels-24-food._1f376 {
    background-position: -1400px 0px;
}
.joypixels-24-food._1f377 {
    background-position: -1425px 0px;
}
.joypixels-24-food._1f378 {
    background-position: -1450px 0px;
}
.joypixels-24-food._1f379 {
    background-position: -1475px 0px;
}
.joypixels-24-food._1f37a {
    background-position: -1500px 0px;
}
.joypixels-24-food._1f37b {
    background-position: -1525px 0px;
}
.joypixels-24-food._1f37c {
    background-position: -1550px 0px;
}
.joypixels-24-food._1f37d {
    background-position: -1575px 0px;
}
.joypixels-24-food._1f37e {
    background-position: -1600px 0px;
}
.joypixels-24-food._1f37f {
    background-position: -1625px 0px;
}
.joypixels-24-food._1f382 {
    background-position: -1650px 0px;
}
.joypixels-24-food._1f942 {
    background-position: -1675px 0px;
}
.joypixels-24-food._1f943 {
    background-position: -1700px 0px;
}
.joypixels-24-food._1f944 {
    background-position: -1725px 0px;
}
.joypixels-24-food._1f950 {
    background-position: -1750px 0px;
}
.joypixels-24-food._1f951 {
    background-position: -1775px 0px;
}
.joypixels-24-food._1f952 {
    background-position: -1800px 0px;
}
.joypixels-24-food._1f953 {
    background-position: -1825px 0px;
}
.joypixels-24-food._1f954 {
    background-position: -1850px 0px;
}
.joypixels-24-food._1f955 {
    background-position: -1875px 0px;
}
.joypixels-24-food._1f956 {
    background-position: -1900px 0px;
}
.joypixels-24-food._1f957 {
    background-position: -1925px 0px;
}
.joypixels-24-food._1f958 {
    background-position: -1950px 0px;
}
.joypixels-24-food._1f959 {
    background-position: -1975px 0px;
}
.joypixels-24-food._1f95a {
    background-position: -2000px 0px;
}
.joypixels-24-food._1f95b {
    background-position: -2025px 0px;
}
.joypixels-24-food._1f95c {
    background-position: -2050px 0px;
}
.joypixels-24-food._1f95d {
    background-position: -2075px 0px;
}
.joypixels-24-food._1f95e {
    background-position: -2100px 0px;
}
.joypixels-24-food._1f95f {
    background-position: -2125px 0px;
}
.joypixels-24-food._1f960 {
    background-position: -2150px 0px;
}
.joypixels-24-food._1f961 {
    background-position: -2175px 0px;
}
.joypixels-24-food._1f962 {
    background-position: -2200px 0px;
}
.joypixels-24-food._1f963 {
    background-position: -2225px 0px;
}
.joypixels-24-food._1f964 {
    background-position: -2250px 0px;
}
.joypixels-24-food._1f965 {
    background-position: -2275px 0px;
}
.joypixels-24-food._1f966 {
    background-position: -2300px 0px;
}
.joypixels-24-food._1f967 {
    background-position: -2325px 0px;
}
.joypixels-24-food._1f968 {
    background-position: -2350px 0px;
}
.joypixels-24-food._1f969 {
    background-position: -2375px 0px;
}
.joypixels-24-food._1f96a {
    background-position: -2400px 0px;
}
.joypixels-24-food._1f96b {
    background-position: -2425px 0px;
}
.joypixels-24-food._1f96c {
    background-position: -2450px 0px;
}
.joypixels-24-food._1f96d {
    background-position: -2475px 0px;
}
.joypixels-24-food._1f96e {
    background-position: -2500px 0px;
}
.joypixels-24-food._1f96f {
    background-position: -2525px 0px;
}
.joypixels-24-food._1f9aa {
    background-position: -2550px 0px;
}
.joypixels-24-food._1f9b4 {
    background-position: -2575px 0px;
}
.joypixels-24-food._1f9c0 {
    background-position: -2600px 0px;
}
.joypixels-24-food._1f9c1 {
    background-position: -2625px 0px;
}
.joypixels-24-food._1f9c2 {
    background-position: -2650px 0px;
}
.joypixels-24-food._1f9c3 {
    background-position: -2675px 0px;
}
.joypixels-24-food._1f9c4 {
    background-position: -2700px 0px;
}
.joypixels-24-food._1f9c5 {
    background-position: -2725px 0px;
}
.joypixels-24-food._1f9c6 {
    background-position: -2750px 0px;
}
.joypixels-24-food._1f9c7 {
    background-position: -2775px 0px;
}
.joypixels-24-food._1f9c8 {
    background-position: -2800px 0px;
}
.joypixels-24-food._1f9c9 {
    background-position: -2825px 0px;
}
.joypixels-24-food._1f9ca {
    background-position: -2850px 0px;
}
.joypixels-24-food._1f9cb {
    background-position: -2875px 0px;
}
.joypixels-24-food._1fad0 {
    background-position: -2900px 0px;
}
.joypixels-24-food._1fad1 {
    background-position: -2925px 0px;
}
.joypixels-24-food._1fad2 {
    background-position: -2950px 0px;
}
.joypixels-24-food._1fad3 {
    background-position: -2975px 0px;
}
.joypixels-24-food._1fad4 {
    background-position: -3000px 0px;
}
.joypixels-24-food._1fad5 {
    background-position: -3025px 0px;
}
.joypixels-24-food._1fad6 {
    background-position: -3050px 0px;
}
.joypixels-24-food._1fad7 {
    background-position: -3075px 0px;
}
.joypixels-24-food._1fad8 {
    background-position: -3100px 0px;
}
.joypixels-24-food._1fad9 {
    background-position: -3125px 0px;
}
.joypixels-24-food._2615 {
    background-position: -3150px 0px;
}

.joypixels-24-activity {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-activity.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-activity {
        background-image: url(joypixels-sprite-24-activity@2x.png);
        background-size: 2974px 24px;
    }
}


.joypixels-24-activity._00000 {
    background-position: 0px 0px;
}
.joypixels-24-activity._1f396 {
    background-position: -25px 0px;
}
.joypixels-24-activity._1f397 {
    background-position: -50px 0px;
}
.joypixels-24-activity._1f39f {
    background-position: -75px 0px;
}
.joypixels-24-activity._1f3a3 {
    background-position: -100px 0px;
}
.joypixels-24-activity._1f3a4 {
    background-position: -125px 0px;
}
.joypixels-24-activity._1f3a7 {
    background-position: -150px 0px;
}
.joypixels-24-activity._1f3a8 {
    background-position: -175px 0px;
}
.joypixels-24-activity._1f3aa {
    background-position: -200px 0px;
}
.joypixels-24-activity._1f3ab {
    background-position: -225px 0px;
}
.joypixels-24-activity._1f3ac {
    background-position: -250px 0px;
}
.joypixels-24-activity._1f3ad {
    background-position: -275px 0px;
}
.joypixels-24-activity._1f3ae {
    background-position: -300px 0px;
}
.joypixels-24-activity._1f3af {
    background-position: -325px 0px;
}
.joypixels-24-activity._1f3b0 {
    background-position: -350px 0px;
}
.joypixels-24-activity._1f3b1 {
    background-position: -375px 0px;
}
.joypixels-24-activity._1f3b2 {
    background-position: -400px 0px;
}
.joypixels-24-activity._1f3b3 {
    background-position: -425px 0px;
}
.joypixels-24-activity._1f3b7 {
    background-position: -450px 0px;
}
.joypixels-24-activity._1f3b8 {
    background-position: -475px 0px;
}
.joypixels-24-activity._1f3b9 {
    background-position: -500px 0px;
}
.joypixels-24-activity._1f3ba {
    background-position: -525px 0px;
}
.joypixels-24-activity._1f3bb {
    background-position: -550px 0px;
}
.joypixels-24-activity._1f3bc {
    background-position: -575px 0px;
}
.joypixels-24-activity._1f3bd {
    background-position: -600px 0px;
}
.joypixels-24-activity._1f3be {
    background-position: -625px 0px;
}
.joypixels-24-activity._1f3bf {
    background-position: -650px 0px;
}
.joypixels-24-activity._1f3c0 {
    background-position: -675px 0px;
}
.joypixels-24-activity._1f3c2 {
    background-position: -700px 0px;
}
.joypixels-24-activity._1f3c4-2640 {
    background-position: -725px 0px;
}
.joypixels-24-activity._1f3c4-2642 {
    background-position: -750px 0px;
}
.joypixels-24-activity._1f3c4 {
    background-position: -775px 0px;
}
.joypixels-24-activity._1f3c5 {
    background-position: -800px 0px;
}
.joypixels-24-activity._1f3c6 {
    background-position: -825px 0px;
}
.joypixels-24-activity._1f3c7 {
    background-position: -850px 0px;
}
.joypixels-24-activity._1f3c8 {
    background-position: -875px 0px;
}
.joypixels-24-activity._1f3c9 {
    background-position: -900px 0px;
}
.joypixels-24-activity._1f3ca-2640 {
    background-position: -925px 0px;
}
.joypixels-24-activity._1f3ca-2642 {
    background-position: -950px 0px;
}
.joypixels-24-activity._1f3ca {
    background-position: -975px 0px;
}
.joypixels-24-activity._1f3cb-2640 {
    background-position: -1000px 0px;
}
.joypixels-24-activity._1f3cb-2642 {
    background-position: -1025px 0px;
}
.joypixels-24-activity._1f3cb {
    background-position: -1050px 0px;
}
.joypixels-24-activity._1f3cc-2640 {
    background-position: -1075px 0px;
}
.joypixels-24-activity._1f3cc-2642 {
    background-position: -1100px 0px;
}
.joypixels-24-activity._1f3cc {
    background-position: -1125px 0px;
}
.joypixels-24-activity._1f3cf {
    background-position: -1150px 0px;
}
.joypixels-24-activity._1f3d0 {
    background-position: -1175px 0px;
}
.joypixels-24-activity._1f3d1 {
    background-position: -1200px 0px;
}
.joypixels-24-activity._1f3d2 {
    background-position: -1225px 0px;
}
.joypixels-24-activity._1f3d3 {
    background-position: -1250px 0px;
}
.joypixels-24-activity._1f3f5 {
    background-position: -1275px 0px;
}
.joypixels-24-activity._1f3f8 {
    background-position: -1300px 0px;
}
.joypixels-24-activity._1f3f9 {
    background-position: -1325px 0px;
}
.joypixels-24-activity._1f6a3-2640 {
    background-position: -1350px 0px;
}
.joypixels-24-activity._1f6a3-2642 {
    background-position: -1375px 0px;
}
.joypixels-24-activity._1f6a3 {
    background-position: -1400px 0px;
}
.joypixels-24-activity._1f6b4-2640 {
    background-position: -1425px 0px;
}
.joypixels-24-activity._1f6b4-2642 {
    background-position: -1450px 0px;
}
.joypixels-24-activity._1f6b4 {
    background-position: -1475px 0px;
}
.joypixels-24-activity._1f6b5-2640 {
    background-position: -1500px 0px;
}
.joypixels-24-activity._1f6b5-2642 {
    background-position: -1525px 0px;
}
.joypixels-24-activity._1f6b5 {
    background-position: -1550px 0px;
}
.joypixels-24-activity._1f6dd {
    background-position: -1575px 0px;
}
.joypixels-24-activity._1f6f7 {
    background-position: -1600px 0px;
}
.joypixels-24-activity._1f6f9 {
    background-position: -1625px 0px;
}
.joypixels-24-activity._1f6fc {
    background-position: -1650px 0px;
}
.joypixels-24-activity._1f938-2640 {
    background-position: -1675px 0px;
}
.joypixels-24-activity._1f938-2642 {
    background-position: -1700px 0px;
}
.joypixels-24-activity._1f938 {
    background-position: -1725px 0px;
}
.joypixels-24-activity._1f939-2640 {
    background-position: -1750px 0px;
}
.joypixels-24-activity._1f939-2642 {
    background-position: -1775px 0px;
}
.joypixels-24-activity._1f939 {
    background-position: -1800px 0px;
}
.joypixels-24-activity._1f93a {
    background-position: -1825px 0px;
}
.joypixels-24-activity._1f93c-2640 {
    background-position: -1850px 0px;
}
.joypixels-24-activity._1f93c-2642 {
    background-position: -1875px 0px;
}
.joypixels-24-activity._1f93c {
    background-position: -1900px 0px;
}
.joypixels-24-activity._1f93d-2640 {
    background-position: -1925px 0px;
}
.joypixels-24-activity._1f93d-2642 {
    background-position: -1950px 0px;
}
.joypixels-24-activity._1f93d {
    background-position: -1975px 0px;
}
.joypixels-24-activity._1f93e-2640 {
    background-position: -2000px 0px;
}
.joypixels-24-activity._1f93e-2642 {
    background-position: -2025px 0px;
}
.joypixels-24-activity._1f93e {
    background-position: -2050px 0px;
}
.joypixels-24-activity._1f93f {
    background-position: -2075px 0px;
}
.joypixels-24-activity._1f941 {
    background-position: -2100px 0px;
}
.joypixels-24-activity._1f945 {
    background-position: -2125px 0px;
}
.joypixels-24-activity._1f947 {
    background-position: -2150px 0px;
}
.joypixels-24-activity._1f948 {
    background-position: -2175px 0px;
}
.joypixels-24-activity._1f949 {
    background-position: -2200px 0px;
}
.joypixels-24-activity._1f94a {
    background-position: -2225px 0px;
}
.joypixels-24-activity._1f94b {
    background-position: -2250px 0px;
}
.joypixels-24-activity._1f94c {
    background-position: -2275px 0px;
}
.joypixels-24-activity._1f94d {
    background-position: -2300px 0px;
}
.joypixels-24-activity._1f94e {
    background-position: -2325px 0px;
}
.joypixels-24-activity._1f94f {
    background-position: -2350px 0px;
}
.joypixels-24-activity._1f9d7-2640 {
    background-position: -2375px 0px;
}
.joypixels-24-activity._1f9d7-2642 {
    background-position: -2400px 0px;
}
.joypixels-24-activity._1f9d7 {
    background-position: -2425px 0px;
}
.joypixels-24-activity._1f9d8-2640 {
    background-position: -2450px 0px;
}
.joypixels-24-activity._1f9d8-2642 {
    background-position: -2475px 0px;
}
.joypixels-24-activity._1f9d8 {
    background-position: -2500px 0px;
}
.joypixels-24-activity._1f9e9 {
    background-position: -2525px 0px;
}
.joypixels-24-activity._1fa70 {
    background-position: -2550px 0px;
}
.joypixels-24-activity._1fa80 {
    background-position: -2575px 0px;
}
.joypixels-24-activity._1fa81 {
    background-position: -2600px 0px;
}
.joypixels-24-activity._1fa82 {
    background-position: -2625px 0px;
}
.joypixels-24-activity._1fa83 {
    background-position: -2650px 0px;
}
.joypixels-24-activity._1fa95 {
    background-position: -2675px 0px;
}
.joypixels-24-activity._1fa97 {
    background-position: -2700px 0px;
}
.joypixels-24-activity._1fa98 {
    background-position: -2725px 0px;
}
.joypixels-24-activity._265f {
    background-position: -2750px 0px;
}
.joypixels-24-activity._26bd {
    background-position: -2775px 0px;
}
.joypixels-24-activity._26be {
    background-position: -2800px 0px;
}
.joypixels-24-activity._26f3 {
    background-position: -2825px 0px;
}
.joypixels-24-activity._26f7 {
    background-position: -2850px 0px;
}
.joypixels-24-activity._26f8 {
    background-position: -2875px 0px;
}
.joypixels-24-activity._26f9-2640 {
    background-position: -2900px 0px;
}
.joypixels-24-activity._26f9-2642 {
    background-position: -2925px 0px;
}
.joypixels-24-activity._26f9 {
    background-position: -2950px 0px;
}

.joypixels-24-travel {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-travel.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-travel {
        background-image: url(joypixels-sprite-24-travel@2x.png);
        background-size: 3299px 24px;
    }
}


.joypixels-24-travel._00000 {
    background-position: 0px 0px;
}
.joypixels-24-travel._1f301 {
    background-position: -25px 0px;
}
.joypixels-24-travel._1f303 {
    background-position: -50px 0px;
}
.joypixels-24-travel._1f304 {
    background-position: -75px 0px;
}
.joypixels-24-travel._1f305 {
    background-position: -100px 0px;
}
.joypixels-24-travel._1f306 {
    background-position: -125px 0px;
}
.joypixels-24-travel._1f307 {
    background-position: -150px 0px;
}
.joypixels-24-travel._1f309 {
    background-position: -175px 0px;
}
.joypixels-24-travel._1f30b {
    background-position: -200px 0px;
}
.joypixels-24-travel._1f30c {
    background-position: -225px 0px;
}
.joypixels-24-travel._1f320 {
    background-position: -250px 0px;
}
.joypixels-24-travel._1f386 {
    background-position: -275px 0px;
}
.joypixels-24-travel._1f387 {
    background-position: -300px 0px;
}
.joypixels-24-travel._1f391 {
    background-position: -325px 0px;
}
.joypixels-24-travel._1f3a0 {
    background-position: -350px 0px;
}
.joypixels-24-travel._1f3a1 {
    background-position: -375px 0px;
}
.joypixels-24-travel._1f3a2 {
    background-position: -400px 0px;
}
.joypixels-24-travel._1f3cd {
    background-position: -425px 0px;
}
.joypixels-24-travel._1f3ce {
    background-position: -450px 0px;
}
.joypixels-24-travel._1f3d4 {
    background-position: -475px 0px;
}
.joypixels-24-travel._1f3d5 {
    background-position: -500px 0px;
}
.joypixels-24-travel._1f3d6 {
    background-position: -525px 0px;
}
.joypixels-24-travel._1f3d7 {
    background-position: -550px 0px;
}
.joypixels-24-travel._1f3d8 {
    background-position: -575px 0px;
}
.joypixels-24-travel._1f3d9 {
    background-position: -600px 0px;
}
.joypixels-24-travel._1f3da {
    background-position: -625px 0px;
}
.joypixels-24-travel._1f3db {
    background-position: -650px 0px;
}
.joypixels-24-travel._1f3dc {
    background-position: -675px 0px;
}
.joypixels-24-travel._1f3dd {
    background-position: -700px 0px;
}
.joypixels-24-travel._1f3de {
    background-position: -725px 0px;
}
.joypixels-24-travel._1f3df {
    background-position: -750px 0px;
}
.joypixels-24-travel._1f3e0 {
    background-position: -775px 0px;
}
.joypixels-24-travel._1f3e1 {
    background-position: -800px 0px;
}
.joypixels-24-travel._1f3e2 {
    background-position: -825px 0px;
}
.joypixels-24-travel._1f3e3 {
    background-position: -850px 0px;
}
.joypixels-24-travel._1f3e4 {
    background-position: -875px 0px;
}
.joypixels-24-travel._1f3e5 {
    background-position: -900px 0px;
}
.joypixels-24-travel._1f3e6 {
    background-position: -925px 0px;
}
.joypixels-24-travel._1f3e8 {
    background-position: -950px 0px;
}
.joypixels-24-travel._1f3e9 {
    background-position: -975px 0px;
}
.joypixels-24-travel._1f3ea {
    background-position: -1000px 0px;
}
.joypixels-24-travel._1f3eb {
    background-position: -1025px 0px;
}
.joypixels-24-travel._1f3ec {
    background-position: -1050px 0px;
}
.joypixels-24-travel._1f3ed {
    background-position: -1075px 0px;
}
.joypixels-24-travel._1f3ef {
    background-position: -1100px 0px;
}
.joypixels-24-travel._1f3f0 {
    background-position: -1125px 0px;
}
.joypixels-24-travel._1f492 {
    background-position: -1150px 0px;
}
.joypixels-24-travel._1f4ba {
    background-position: -1175px 0px;
}
.joypixels-24-travel._1f54b {
    background-position: -1200px 0px;
}
.joypixels-24-travel._1f54c {
    background-position: -1225px 0px;
}
.joypixels-24-travel._1f54d {
    background-position: -1250px 0px;
}
.joypixels-24-travel._1f5fa {
    background-position: -1275px 0px;
}
.joypixels-24-travel._1f5fb {
    background-position: -1300px 0px;
}
.joypixels-24-travel._1f5fc {
    background-position: -1325px 0px;
}
.joypixels-24-travel._1f5fd {
    background-position: -1350px 0px;
}
.joypixels-24-travel._1f5fe {
    background-position: -1375px 0px;
}
.joypixels-24-travel._1f5ff {
    background-position: -1400px 0px;
}
.joypixels-24-travel._1f680 {
    background-position: -1425px 0px;
}
.joypixels-24-travel._1f681 {
    background-position: -1450px 0px;
}
.joypixels-24-travel._1f682 {
    background-position: -1475px 0px;
}
.joypixels-24-travel._1f683 {
    background-position: -1500px 0px;
}
.joypixels-24-travel._1f684 {
    background-position: -1525px 0px;
}
.joypixels-24-travel._1f685 {
    background-position: -1550px 0px;
}
.joypixels-24-travel._1f686 {
    background-position: -1575px 0px;
}
.joypixels-24-travel._1f687 {
    background-position: -1600px 0px;
}
.joypixels-24-travel._1f688 {
    background-position: -1625px 0px;
}
.joypixels-24-travel._1f689 {
    background-position: -1650px 0px;
}
.joypixels-24-travel._1f68a {
    background-position: -1675px 0px;
}
.joypixels-24-travel._1f68b {
    background-position: -1700px 0px;
}
.joypixels-24-travel._1f68c {
    background-position: -1725px 0px;
}
.joypixels-24-travel._1f68d {
    background-position: -1750px 0px;
}
.joypixels-24-travel._1f68e {
    background-position: -1775px 0px;
}
.joypixels-24-travel._1f68f {
    background-position: -1800px 0px;
}
.joypixels-24-travel._1f690 {
    background-position: -1825px 0px;
}
.joypixels-24-travel._1f691 {
    background-position: -1850px 0px;
}
.joypixels-24-travel._1f692 {
    background-position: -1875px 0px;
}
.joypixels-24-travel._1f693 {
    background-position: -1900px 0px;
}
.joypixels-24-travel._1f694 {
    background-position: -1925px 0px;
}
.joypixels-24-travel._1f695 {
    background-position: -1950px 0px;
}
.joypixels-24-travel._1f696 {
    background-position: -1975px 0px;
}
.joypixels-24-travel._1f697 {
    background-position: -2000px 0px;
}
.joypixels-24-travel._1f698 {
    background-position: -2025px 0px;
}
.joypixels-24-travel._1f699 {
    background-position: -2050px 0px;
}
.joypixels-24-travel._1f69a {
    background-position: -2075px 0px;
}
.joypixels-24-travel._1f69b {
    background-position: -2100px 0px;
}
.joypixels-24-travel._1f69c {
    background-position: -2125px 0px;
}
.joypixels-24-travel._1f69d {
    background-position: -2150px 0px;
}
.joypixels-24-travel._1f69e {
    background-position: -2175px 0px;
}
.joypixels-24-travel._1f69f {
    background-position: -2200px 0px;
}
.joypixels-24-travel._1f6a0 {
    background-position: -2225px 0px;
}
.joypixels-24-travel._1f6a1 {
    background-position: -2250px 0px;
}
.joypixels-24-travel._1f6a2 {
    background-position: -2275px 0px;
}
.joypixels-24-travel._1f6a4 {
    background-position: -2300px 0px;
}
.joypixels-24-travel._1f6a5 {
    background-position: -2325px 0px;
}
.joypixels-24-travel._1f6a6 {
    background-position: -2350px 0px;
}
.joypixels-24-travel._1f6a7 {
    background-position: -2375px 0px;
}
.joypixels-24-travel._1f6a8 {
    background-position: -2400px 0px;
}
.joypixels-24-travel._1f6b2 {
    background-position: -2425px 0px;
}
.joypixels-24-travel._1f6d5 {
    background-position: -2450px 0px;
}
.joypixels-24-travel._1f6d6 {
    background-position: -2475px 0px;
}
.joypixels-24-travel._1f6de {
    background-position: -2500px 0px;
}
.joypixels-24-travel._1f6df {
    background-position: -2525px 0px;
}
.joypixels-24-travel._1f6e3 {
    background-position: -2550px 0px;
}
.joypixels-24-travel._1f6e4 {
    background-position: -2575px 0px;
}
.joypixels-24-travel._1f6e5 {
    background-position: -2600px 0px;
}
.joypixels-24-travel._1f6e9 {
    background-position: -2625px 0px;
}
.joypixels-24-travel._1f6eb {
    background-position: -2650px 0px;
}
.joypixels-24-travel._1f6ec {
    background-position: -2675px 0px;
}
.joypixels-24-travel._1f6f0 {
    background-position: -2700px 0px;
}
.joypixels-24-travel._1f6f3 {
    background-position: -2725px 0px;
}
.joypixels-24-travel._1f6f4 {
    background-position: -2750px 0px;
}
.joypixels-24-travel._1f6f5 {
    background-position: -2775px 0px;
}
.joypixels-24-travel._1f6f6 {
    background-position: -2800px 0px;
}
.joypixels-24-travel._1f6f8 {
    background-position: -2825px 0px;
}
.joypixels-24-travel._1f6fa {
    background-position: -2850px 0px;
}
.joypixels-24-travel._1f6fb {
    background-position: -2875px 0px;
}
.joypixels-24-travel._1f9af {
    background-position: -2900px 0px;
}
.joypixels-24-travel._1f9bc {
    background-position: -2925px 0px;
}
.joypixels-24-travel._1f9bd {
    background-position: -2950px 0px;
}
.joypixels-24-travel._1fa7c {
    background-position: -2975px 0px;
}
.joypixels-24-travel._1fa9d {
    background-position: -3000px 0px;
}
.joypixels-24-travel._2693 {
    background-position: -3025px 0px;
}
.joypixels-24-travel._26e9 {
    background-position: -3050px 0px;
}
.joypixels-24-travel._26ea {
    background-position: -3075px 0px;
}
.joypixels-24-travel._26f0 {
    background-position: -3100px 0px;
}
.joypixels-24-travel._26f1 {
    background-position: -3125px 0px;
}
.joypixels-24-travel._26f2 {
    background-position: -3150px 0px;
}
.joypixels-24-travel._26f4 {
    background-position: -3175px 0px;
}
.joypixels-24-travel._26f5 {
    background-position: -3200px 0px;
}
.joypixels-24-travel._26fa {
    background-position: -3225px 0px;
}
.joypixels-24-travel._26fd {
    background-position: -3250px 0px;
}
.joypixels-24-travel._2708 {
    background-position: -3275px 0px;
}

.joypixels-24-objects {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-objects.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-objects {
        background-image: url(joypixels-sprite-24-objects@2x.png);
        background-size: 5574px 24px;
    }
}


.joypixels-24-objects._00000 {
    background-position: 0px 0px;
}
.joypixels-24-objects._1f321 {
    background-position: -25px 0px;
}
.joypixels-24-objects._1f380 {
    background-position: -50px 0px;
}
.joypixels-24-objects._1f381 {
    background-position: -75px 0px;
}
.joypixels-24-objects._1f388 {
    background-position: -100px 0px;
}
.joypixels-24-objects._1f389 {
    background-position: -125px 0px;
}
.joypixels-24-objects._1f38a {
    background-position: -150px 0px;
}
.joypixels-24-objects._1f38e {
    background-position: -175px 0px;
}
.joypixels-24-objects._1f38f {
    background-position: -200px 0px;
}
.joypixels-24-objects._1f390 {
    background-position: -225px 0px;
}
.joypixels-24-objects._1f399 {
    background-position: -250px 0px;
}
.joypixels-24-objects._1f39a {
    background-position: -275px 0px;
}
.joypixels-24-objects._1f39b {
    background-position: -300px 0px;
}
.joypixels-24-objects._1f39e {
    background-position: -325px 0px;
}
.joypixels-24-objects._1f3a5 {
    background-position: -350px 0px;
}
.joypixels-24-objects._1f3ee {
    background-position: -375px 0px;
}
.joypixels-24-objects._1f3f7 {
    background-position: -400px 0px;
}
.joypixels-24-objects._1f3fa {
    background-position: -425px 0px;
}
.joypixels-24-objects._1f488 {
    background-position: -450px 0px;
}
.joypixels-24-objects._1f489 {
    background-position: -475px 0px;
}
.joypixels-24-objects._1f48a {
    background-position: -500px 0px;
}
.joypixels-24-objects._1f48c {
    background-position: -525px 0px;
}
.joypixels-24-objects._1f48e {
    background-position: -550px 0px;
}
.joypixels-24-objects._1f4a1 {
    background-position: -575px 0px;
}
.joypixels-24-objects._1f4a3 {
    background-position: -600px 0px;
}
.joypixels-24-objects._1f4b0 {
    background-position: -625px 0px;
}
.joypixels-24-objects._1f4b3 {
    background-position: -650px 0px;
}
.joypixels-24-objects._1f4b4 {
    background-position: -675px 0px;
}
.joypixels-24-objects._1f4b5 {
    background-position: -700px 0px;
}
.joypixels-24-objects._1f4b6 {
    background-position: -725px 0px;
}
.joypixels-24-objects._1f4b7 {
    background-position: -750px 0px;
}
.joypixels-24-objects._1f4b8 {
    background-position: -775px 0px;
}
.joypixels-24-objects._1f4bb {
    background-position: -800px 0px;
}
.joypixels-24-objects._1f4bd {
    background-position: -825px 0px;
}
.joypixels-24-objects._1f4be {
    background-position: -850px 0px;
}
.joypixels-24-objects._1f4bf {
    background-position: -875px 0px;
}
.joypixels-24-objects._1f4c0 {
    background-position: -900px 0px;
}
.joypixels-24-objects._1f4c1 {
    background-position: -925px 0px;
}
.joypixels-24-objects._1f4c2 {
    background-position: -950px 0px;
}
.joypixels-24-objects._1f4c3 {
    background-position: -975px 0px;
}
.joypixels-24-objects._1f4c4 {
    background-position: -1000px 0px;
}
.joypixels-24-objects._1f4c5 {
    background-position: -1025px 0px;
}
.joypixels-24-objects._1f4c6 {
    background-position: -1050px 0px;
}
.joypixels-24-objects._1f4c7 {
    background-position: -1075px 0px;
}
.joypixels-24-objects._1f4c8 {
    background-position: -1100px 0px;
}
.joypixels-24-objects._1f4c9 {
    background-position: -1125px 0px;
}
.joypixels-24-objects._1f4ca {
    background-position: -1150px 0px;
}
.joypixels-24-objects._1f4cb {
    background-position: -1175px 0px;
}
.joypixels-24-objects._1f4cc {
    background-position: -1200px 0px;
}
.joypixels-24-objects._1f4cd {
    background-position: -1225px 0px;
}
.joypixels-24-objects._1f4ce {
    background-position: -1250px 0px;
}
.joypixels-24-objects._1f4cf {
    background-position: -1275px 0px;
}
.joypixels-24-objects._1f4d0 {
    background-position: -1300px 0px;
}
.joypixels-24-objects._1f4d1 {
    background-position: -1325px 0px;
}
.joypixels-24-objects._1f4d2 {
    background-position: -1350px 0px;
}
.joypixels-24-objects._1f4d3 {
    background-position: -1375px 0px;
}
.joypixels-24-objects._1f4d4 {
    background-position: -1400px 0px;
}
.joypixels-24-objects._1f4d5 {
    background-position: -1425px 0px;
}
.joypixels-24-objects._1f4d6 {
    background-position: -1450px 0px;
}
.joypixels-24-objects._1f4d7 {
    background-position: -1475px 0px;
}
.joypixels-24-objects._1f4d8 {
    background-position: -1500px 0px;
}
.joypixels-24-objects._1f4d9 {
    background-position: -1525px 0px;
}
.joypixels-24-objects._1f4da {
    background-position: -1550px 0px;
}
.joypixels-24-objects._1f4dc {
    background-position: -1575px 0px;
}
.joypixels-24-objects._1f4dd {
    background-position: -1600px 0px;
}
.joypixels-24-objects._1f4de {
    background-position: -1625px 0px;
}
.joypixels-24-objects._1f4df {
    background-position: -1650px 0px;
}
.joypixels-24-objects._1f4e0 {
    background-position: -1675px 0px;
}
.joypixels-24-objects._1f4e1 {
    background-position: -1700px 0px;
}
.joypixels-24-objects._1f4e4 {
    background-position: -1725px 0px;
}
.joypixels-24-objects._1f4e5 {
    background-position: -1750px 0px;
}
.joypixels-24-objects._1f4e6 {
    background-position: -1775px 0px;
}
.joypixels-24-objects._1f4e7 {
    background-position: -1800px 0px;
}
.joypixels-24-objects._1f4e8 {
    background-position: -1825px 0px;
}
.joypixels-24-objects._1f4e9 {
    background-position: -1850px 0px;
}
.joypixels-24-objects._1f4ea {
    background-position: -1875px 0px;
}
.joypixels-24-objects._1f4eb {
    background-position: -1900px 0px;
}
.joypixels-24-objects._1f4ec {
    background-position: -1925px 0px;
}
.joypixels-24-objects._1f4ed {
    background-position: -1950px 0px;
}
.joypixels-24-objects._1f4ee {
    background-position: -1975px 0px;
}
.joypixels-24-objects._1f4ef {
    background-position: -2000px 0px;
}
.joypixels-24-objects._1f4f0 {
    background-position: -2025px 0px;
}
.joypixels-24-objects._1f4f1 {
    background-position: -2050px 0px;
}
.joypixels-24-objects._1f4f2 {
    background-position: -2075px 0px;
}
.joypixels-24-objects._1f4f7 {
    background-position: -2100px 0px;
}
.joypixels-24-objects._1f4f8 {
    background-position: -2125px 0px;
}
.joypixels-24-objects._1f4f9 {
    background-position: -2150px 0px;
}
.joypixels-24-objects._1f4fa {
    background-position: -2175px 0px;
}
.joypixels-24-objects._1f4fb {
    background-position: -2200px 0px;
}
.joypixels-24-objects._1f4fc {
    background-position: -2225px 0px;
}
.joypixels-24-objects._1f4fd {
    background-position: -2250px 0px;
}
.joypixels-24-objects._1f4ff {
    background-position: -2275px 0px;
}
.joypixels-24-objects._1f50b {
    background-position: -2300px 0px;
}
.joypixels-24-objects._1f50c {
    background-position: -2325px 0px;
}
.joypixels-24-objects._1f50d {
    background-position: -2350px 0px;
}
.joypixels-24-objects._1f50e {
    background-position: -2375px 0px;
}
.joypixels-24-objects._1f50f {
    background-position: -2400px 0px;
}
.joypixels-24-objects._1f510 {
    background-position: -2425px 0px;
}
.joypixels-24-objects._1f511 {
    background-position: -2450px 0px;
}
.joypixels-24-objects._1f512 {
    background-position: -2475px 0px;
}
.joypixels-24-objects._1f513 {
    background-position: -2500px 0px;
}
.joypixels-24-objects._1f516 {
    background-position: -2525px 0px;
}
.joypixels-24-objects._1f517 {
    background-position: -2550px 0px;
}
.joypixels-24-objects._1f526 {
    background-position: -2575px 0px;
}
.joypixels-24-objects._1f527 {
    background-position: -2600px 0px;
}
.joypixels-24-objects._1f528 {
    background-position: -2625px 0px;
}
.joypixels-24-objects._1f529 {
    background-position: -2650px 0px;
}
.joypixels-24-objects._1f52a {
    background-position: -2675px 0px;
}
.joypixels-24-objects._1f52b {
    background-position: -2700px 0px;
}
.joypixels-24-objects._1f52c {
    background-position: -2725px 0px;
}
.joypixels-24-objects._1f52d {
    background-position: -2750px 0px;
}
.joypixels-24-objects._1f52e {
    background-position: -2775px 0px;
}
.joypixels-24-objects._1f56f {
    background-position: -2800px 0px;
}
.joypixels-24-objects._1f570 {
    background-position: -2825px 0px;
}
.joypixels-24-objects._1f573 {
    background-position: -2850px 0px;
}
.joypixels-24-objects._1f579 {
    background-position: -2875px 0px;
}
.joypixels-24-objects._1f587 {
    background-position: -2900px 0px;
}
.joypixels-24-objects._1f58a {
    background-position: -2925px 0px;
}
.joypixels-24-objects._1f58b {
    background-position: -2950px 0px;
}
.joypixels-24-objects._1f58c {
    background-position: -2975px 0px;
}
.joypixels-24-objects._1f58d {
    background-position: -3000px 0px;
}
.joypixels-24-objects._1f5a5 {
    background-position: -3025px 0px;
}
.joypixels-24-objects._1f5a8 {
    background-position: -3050px 0px;
}
.joypixels-24-objects._1f5b1 {
    background-position: -3075px 0px;
}
.joypixels-24-objects._1f5b2 {
    background-position: -3100px 0px;
}
.joypixels-24-objects._1f5bc {
    background-position: -3125px 0px;
}
.joypixels-24-objects._1f5c2 {
    background-position: -3150px 0px;
}
.joypixels-24-objects._1f5c3 {
    background-position: -3175px 0px;
}
.joypixels-24-objects._1f5c4 {
    background-position: -3200px 0px;
}
.joypixels-24-objects._1f5d1 {
    background-position: -3225px 0px;
}
.joypixels-24-objects._1f5d2 {
    background-position: -3250px 0px;
}
.joypixels-24-objects._1f5d3 {
    background-position: -3275px 0px;
}
.joypixels-24-objects._1f5dc {
    background-position: -3300px 0px;
}
.joypixels-24-objects._1f5dd {
    background-position: -3325px 0px;
}
.joypixels-24-objects._1f5de {
    background-position: -3350px 0px;
}
.joypixels-24-objects._1f5e1 {
    background-position: -3375px 0px;
}
.joypixels-24-objects._1f5f3 {
    background-position: -3400px 0px;
}
.joypixels-24-objects._1f6aa {
    background-position: -3425px 0px;
}
.joypixels-24-objects._1f6ac {
    background-position: -3450px 0px;
}
.joypixels-24-objects._1f6b0 {
    background-position: -3475px 0px;
}
.joypixels-24-objects._1f6bd {
    background-position: -3500px 0px;
}
.joypixels-24-objects._1f6bf {
    background-position: -3525px 0px;
}
.joypixels-24-objects._1f6c0 {
    background-position: -3550px 0px;
}
.joypixels-24-objects._1f6c1 {
    background-position: -3575px 0px;
}
.joypixels-24-objects._1f6cb {
    background-position: -3600px 0px;
}
.joypixels-24-objects._1f6cc {
    background-position: -3625px 0px;
}
.joypixels-24-objects._1f6cd {
    background-position: -3650px 0px;
}
.joypixels-24-objects._1f6ce {
    background-position: -3675px 0px;
}
.joypixels-24-objects._1f6cf {
    background-position: -3700px 0px;
}
.joypixels-24-objects._1f6d2 {
    background-position: -3725px 0px;
}
.joypixels-24-objects._1f6e0 {
    background-position: -3750px 0px;
}
.joypixels-24-objects._1f6e1 {
    background-position: -3775px 0px;
}
.joypixels-24-objects._1f6e2 {
    background-position: -3800px 0px;
}
.joypixels-24-objects._1f9a0 {
    background-position: -3825px 0px;
}
.joypixels-24-objects._1f9e7 {
    background-position: -3850px 0px;
}
.joypixels-24-objects._1f9e8 {
    background-position: -3875px 0px;
}
.joypixels-24-objects._1f9ea {
    background-position: -3900px 0px;
}
.joypixels-24-objects._1f9eb {
    background-position: -3925px 0px;
}
.joypixels-24-objects._1f9ec {
    background-position: -3950px 0px;
}
.joypixels-24-objects._1f9ed {
    background-position: -3975px 0px;
}
.joypixels-24-objects._1f9ee {
    background-position: -4000px 0px;
}
.joypixels-24-objects._1f9ef {
    background-position: -4025px 0px;
}
.joypixels-24-objects._1f9f0 {
    background-position: -4050px 0px;
}
.joypixels-24-objects._1f9f1 {
    background-position: -4075px 0px;
}
.joypixels-24-objects._1f9f2 {
    background-position: -4100px 0px;
}
.joypixels-24-objects._1f9f4 {
    background-position: -4125px 0px;
}
.joypixels-24-objects._1f9f7 {
    background-position: -4150px 0px;
}
.joypixels-24-objects._1f9f8 {
    background-position: -4175px 0px;
}
.joypixels-24-objects._1f9f9 {
    background-position: -4200px 0px;
}
.joypixels-24-objects._1f9fa {
    background-position: -4225px 0px;
}
.joypixels-24-objects._1f9fb {
    background-position: -4250px 0px;
}
.joypixels-24-objects._1f9fc {
    background-position: -4275px 0px;
}
.joypixels-24-objects._1f9fd {
    background-position: -4300px 0px;
}
.joypixels-24-objects._1f9fe {
    background-position: -4325px 0px;
}
.joypixels-24-objects._1f9ff {
    background-position: -4350px 0px;
}
.joypixels-24-objects._1fa78 {
    background-position: -4375px 0px;
}
.joypixels-24-objects._1fa79 {
    background-position: -4400px 0px;
}
.joypixels-24-objects._1fa7a {
    background-position: -4425px 0px;
}
.joypixels-24-objects._1fa7b {
    background-position: -4450px 0px;
}
.joypixels-24-objects._1fa84 {
    background-position: -4475px 0px;
}
.joypixels-24-objects._1fa85 {
    background-position: -4500px 0px;
}
.joypixels-24-objects._1fa86 {
    background-position: -4525px 0px;
}
.joypixels-24-objects._1fa91 {
    background-position: -4550px 0px;
}
.joypixels-24-objects._1fa92 {
    background-position: -4575px 0px;
}
.joypixels-24-objects._1fa93 {
    background-position: -4600px 0px;
}
.joypixels-24-objects._1fa94 {
    background-position: -4625px 0px;
}
.joypixels-24-objects._1fa99 {
    background-position: -4650px 0px;
}
.joypixels-24-objects._1fa9a {
    background-position: -4675px 0px;
}
.joypixels-24-objects._1fa9b {
    background-position: -4700px 0px;
}
.joypixels-24-objects._1fa9c {
    background-position: -4725px 0px;
}
.joypixels-24-objects._1fa9e {
    background-position: -4750px 0px;
}
.joypixels-24-objects._1fa9f {
    background-position: -4775px 0px;
}
.joypixels-24-objects._1faa0 {
    background-position: -4800px 0px;
}
.joypixels-24-objects._1faa3 {
    background-position: -4825px 0px;
}
.joypixels-24-objects._1faa4 {
    background-position: -4850px 0px;
}
.joypixels-24-objects._1faa5 {
    background-position: -4875px 0px;
}
.joypixels-24-objects._1faa6 {
    background-position: -4900px 0px;
}
.joypixels-24-objects._1faa7 {
    background-position: -4925px 0px;
}
.joypixels-24-objects._1faa9 {
    background-position: -4950px 0px;
}
.joypixels-24-objects._1faaa {
    background-position: -4975px 0px;
}
.joypixels-24-objects._1faab {
    background-position: -5000px 0px;
}
.joypixels-24-objects._1faac {
    background-position: -5025px 0px;
}
.joypixels-24-objects._231a {
    background-position: -5050px 0px;
}
.joypixels-24-objects._231b {
    background-position: -5075px 0px;
}
.joypixels-24-objects._2328 {
    background-position: -5100px 0px;
}
.joypixels-24-objects._23f0 {
    background-position: -5125px 0px;
}
.joypixels-24-objects._23f1 {
    background-position: -5150px 0px;
}
.joypixels-24-objects._23f2 {
    background-position: -5175px 0px;
}
.joypixels-24-objects._23f3 {
    background-position: -5200px 0px;
}
.joypixels-24-objects._260e {
    background-position: -5225px 0px;
}
.joypixels-24-objects._2692 {
    background-position: -5250px 0px;
}
.joypixels-24-objects._2694 {
    background-position: -5275px 0px;
}
.joypixels-24-objects._2696 {
    background-position: -5300px 0px;
}
.joypixels-24-objects._2697 {
    background-position: -5325px 0px;
}
.joypixels-24-objects._2699 {
    background-position: -5350px 0px;
}
.joypixels-24-objects._26b0 {
    background-position: -5375px 0px;
}
.joypixels-24-objects._26b1 {
    background-position: -5400px 0px;
}
.joypixels-24-objects._26cf {
    background-position: -5425px 0px;
}
.joypixels-24-objects._26d3 {
    background-position: -5450px 0px;
}
.joypixels-24-objects._2702 {
    background-position: -5475px 0px;
}
.joypixels-24-objects._2709 {
    background-position: -5500px 0px;
}
.joypixels-24-objects._270f {
    background-position: -5525px 0px;
}
.joypixels-24-objects._2712 {
    background-position: -5550px 0px;
}

.joypixels-24-symbols {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-symbols.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-symbols {
        background-image: url(joypixels-sprite-24-symbols@2x.png);
        background-size: 7749px 24px;
    }
}


.joypixels-24-symbols._00000 {
    background-position: 0px 0px;
}
.joypixels-24-symbols._0023-20e3 {
    background-position: -25px 0px;
}
.joypixels-24-symbols._0023 {
    background-position: -50px 0px;
}
.joypixels-24-symbols._002a-20e3 {
    background-position: -75px 0px;
}
.joypixels-24-symbols._002a {
    background-position: -100px 0px;
}
.joypixels-24-symbols._0030-20e3 {
    background-position: -125px 0px;
}
.joypixels-24-symbols._0030 {
    background-position: -150px 0px;
}
.joypixels-24-symbols._0031-20e3 {
    background-position: -175px 0px;
}
.joypixels-24-symbols._0031 {
    background-position: -200px 0px;
}
.joypixels-24-symbols._0032-20e3 {
    background-position: -225px 0px;
}
.joypixels-24-symbols._0032 {
    background-position: -250px 0px;
}
.joypixels-24-symbols._0033-20e3 {
    background-position: -275px 0px;
}
.joypixels-24-symbols._0033 {
    background-position: -300px 0px;
}
.joypixels-24-symbols._0034-20e3 {
    background-position: -325px 0px;
}
.joypixels-24-symbols._0034 {
    background-position: -350px 0px;
}
.joypixels-24-symbols._0035-20e3 {
    background-position: -375px 0px;
}
.joypixels-24-symbols._0035 {
    background-position: -400px 0px;
}
.joypixels-24-symbols._0036-20e3 {
    background-position: -425px 0px;
}
.joypixels-24-symbols._0036 {
    background-position: -450px 0px;
}
.joypixels-24-symbols._0037-20e3 {
    background-position: -475px 0px;
}
.joypixels-24-symbols._0037 {
    background-position: -500px 0px;
}
.joypixels-24-symbols._0038-20e3 {
    background-position: -525px 0px;
}
.joypixels-24-symbols._0038 {
    background-position: -550px 0px;
}
.joypixels-24-symbols._0039-20e3 {
    background-position: -575px 0px;
}
.joypixels-24-symbols._0039 {
    background-position: -600px 0px;
}
.joypixels-24-symbols._00a9 {
    background-position: -625px 0px;
}
.joypixels-24-symbols._00ae {
    background-position: -650px 0px;
}
.joypixels-24-symbols._1f004 {
    background-position: -675px 0px;
}
.joypixels-24-symbols._1f0cf {
    background-position: -700px 0px;
}
.joypixels-24-symbols._1f170 {
    background-position: -725px 0px;
}
.joypixels-24-symbols._1f171 {
    background-position: -750px 0px;
}
.joypixels-24-symbols._1f17e {
    background-position: -775px 0px;
}
.joypixels-24-symbols._1f17f {
    background-position: -800px 0px;
}
.joypixels-24-symbols._1f18e {
    background-position: -825px 0px;
}
.joypixels-24-symbols._1f191 {
    background-position: -850px 0px;
}
.joypixels-24-symbols._1f192 {
    background-position: -875px 0px;
}
.joypixels-24-symbols._1f193 {
    background-position: -900px 0px;
}
.joypixels-24-symbols._1f194 {
    background-position: -925px 0px;
}
.joypixels-24-symbols._1f195 {
    background-position: -950px 0px;
}
.joypixels-24-symbols._1f196 {
    background-position: -975px 0px;
}
.joypixels-24-symbols._1f197 {
    background-position: -1000px 0px;
}
.joypixels-24-symbols._1f198 {
    background-position: -1025px 0px;
}
.joypixels-24-symbols._1f199 {
    background-position: -1050px 0px;
}
.joypixels-24-symbols._1f19a {
    background-position: -1075px 0px;
}
.joypixels-24-symbols._1f201 {
    background-position: -1100px 0px;
}
.joypixels-24-symbols._1f202 {
    background-position: -1125px 0px;
}
.joypixels-24-symbols._1f21a {
    background-position: -1150px 0px;
}
.joypixels-24-symbols._1f22f {
    background-position: -1175px 0px;
}
.joypixels-24-symbols._1f232 {
    background-position: -1200px 0px;
}
.joypixels-24-symbols._1f233 {
    background-position: -1225px 0px;
}
.joypixels-24-symbols._1f234 {
    background-position: -1250px 0px;
}
.joypixels-24-symbols._1f235 {
    background-position: -1275px 0px;
}
.joypixels-24-symbols._1f236 {
    background-position: -1300px 0px;
}
.joypixels-24-symbols._1f237 {
    background-position: -1325px 0px;
}
.joypixels-24-symbols._1f238 {
    background-position: -1350px 0px;
}
.joypixels-24-symbols._1f239 {
    background-position: -1375px 0px;
}
.joypixels-24-symbols._1f23a {
    background-position: -1400px 0px;
}
.joypixels-24-symbols._1f250 {
    background-position: -1425px 0px;
}
.joypixels-24-symbols._1f251 {
    background-position: -1450px 0px;
}
.joypixels-24-symbols._1f300 {
    background-position: -1475px 0px;
}
.joypixels-24-symbols._1f310 {
    background-position: -1500px 0px;
}
.joypixels-24-symbols._1f3a6 {
    background-position: -1525px 0px;
}
.joypixels-24-symbols._1f3b4 {
    background-position: -1550px 0px;
}
.joypixels-24-symbols._1f3b5 {
    background-position: -1575px 0px;
}
.joypixels-24-symbols._1f3b6 {
    background-position: -1600px 0px;
}
.joypixels-24-symbols._1f3e7 {
    background-position: -1625px 0px;
}
.joypixels-24-symbols._1f441-1f5e8 {
    background-position: -1650px 0px;
}
.joypixels-24-symbols._1f493 {
    background-position: -1675px 0px;
}
.joypixels-24-symbols._1f494 {
    background-position: -1700px 0px;
}
.joypixels-24-symbols._1f495 {
    background-position: -1725px 0px;
}
.joypixels-24-symbols._1f496 {
    background-position: -1750px 0px;
}
.joypixels-24-symbols._1f497 {
    background-position: -1775px 0px;
}
.joypixels-24-symbols._1f498 {
    background-position: -1800px 0px;
}
.joypixels-24-symbols._1f499 {
    background-position: -1825px 0px;
}
.joypixels-24-symbols._1f49a {
    background-position: -1850px 0px;
}
.joypixels-24-symbols._1f49b {
    background-position: -1875px 0px;
}
.joypixels-24-symbols._1f49c {
    background-position: -1900px 0px;
}
.joypixels-24-symbols._1f49d {
    background-position: -1925px 0px;
}
.joypixels-24-symbols._1f49e {
    background-position: -1950px 0px;
}
.joypixels-24-symbols._1f49f {
    background-position: -1975px 0px;
}
.joypixels-24-symbols._1f4a0 {
    background-position: -2000px 0px;
}
.joypixels-24-symbols._1f4a2 {
    background-position: -2025px 0px;
}
.joypixels-24-symbols._1f4a4 {
    background-position: -2050px 0px;
}
.joypixels-24-symbols._1f4ac {
    background-position: -2075px 0px;
}
.joypixels-24-symbols._1f4ad {
    background-position: -2100px 0px;
}
.joypixels-24-symbols._1f4ae {
    background-position: -2125px 0px;
}
.joypixels-24-symbols._1f4af {
    background-position: -2150px 0px;
}
.joypixels-24-symbols._1f4b1 {
    background-position: -2175px 0px;
}
.joypixels-24-symbols._1f4b2 {
    background-position: -2200px 0px;
}
.joypixels-24-symbols._1f4b9 {
    background-position: -2225px 0px;
}
.joypixels-24-symbols._1f4db {
    background-position: -2250px 0px;
}
.joypixels-24-symbols._1f4e2 {
    background-position: -2275px 0px;
}
.joypixels-24-symbols._1f4e3 {
    background-position: -2300px 0px;
}
.joypixels-24-symbols._1f4f3 {
    background-position: -2325px 0px;
}
.joypixels-24-symbols._1f4f4 {
    background-position: -2350px 0px;
}
.joypixels-24-symbols._1f4f5 {
    background-position: -2375px 0px;
}
.joypixels-24-symbols._1f4f6 {
    background-position: -2400px 0px;
}
.joypixels-24-symbols._1f500 {
    background-position: -2425px 0px;
}
.joypixels-24-symbols._1f501 {
    background-position: -2450px 0px;
}
.joypixels-24-symbols._1f502 {
    background-position: -2475px 0px;
}
.joypixels-24-symbols._1f503 {
    background-position: -2500px 0px;
}
.joypixels-24-symbols._1f504 {
    background-position: -2525px 0px;
}
.joypixels-24-symbols._1f505 {
    background-position: -2550px 0px;
}
.joypixels-24-symbols._1f506 {
    background-position: -2575px 0px;
}
.joypixels-24-symbols._1f507 {
    background-position: -2600px 0px;
}
.joypixels-24-symbols._1f508 {
    background-position: -2625px 0px;
}
.joypixels-24-symbols._1f509 {
    background-position: -2650px 0px;
}
.joypixels-24-symbols._1f50a {
    background-position: -2675px 0px;
}
.joypixels-24-symbols._1f514 {
    background-position: -2700px 0px;
}
.joypixels-24-symbols._1f515 {
    background-position: -2725px 0px;
}
.joypixels-24-symbols._1f518 {
    background-position: -2750px 0px;
}
.joypixels-24-symbols._1f519 {
    background-position: -2775px 0px;
}
.joypixels-24-symbols._1f51a {
    background-position: -2800px 0px;
}
.joypixels-24-symbols._1f51b {
    background-position: -2825px 0px;
}
.joypixels-24-symbols._1f51c {
    background-position: -2850px 0px;
}
.joypixels-24-symbols._1f51d {
    background-position: -2875px 0px;
}
.joypixels-24-symbols._1f51e {
    background-position: -2900px 0px;
}
.joypixels-24-symbols._1f51f {
    background-position: -2925px 0px;
}
.joypixels-24-symbols._1f520 {
    background-position: -2950px 0px;
}
.joypixels-24-symbols._1f521 {
    background-position: -2975px 0px;
}
.joypixels-24-symbols._1f522 {
    background-position: -3000px 0px;
}
.joypixels-24-symbols._1f523 {
    background-position: -3025px 0px;
}
.joypixels-24-symbols._1f524 {
    background-position: -3050px 0px;
}
.joypixels-24-symbols._1f52f {
    background-position: -3075px 0px;
}
.joypixels-24-symbols._1f530 {
    background-position: -3100px 0px;
}
.joypixels-24-symbols._1f531 {
    background-position: -3125px 0px;
}
.joypixels-24-symbols._1f532 {
    background-position: -3150px 0px;
}
.joypixels-24-symbols._1f533 {
    background-position: -3175px 0px;
}
.joypixels-24-symbols._1f534 {
    background-position: -3200px 0px;
}
.joypixels-24-symbols._1f535 {
    background-position: -3225px 0px;
}
.joypixels-24-symbols._1f536 {
    background-position: -3250px 0px;
}
.joypixels-24-symbols._1f537 {
    background-position: -3275px 0px;
}
.joypixels-24-symbols._1f538 {
    background-position: -3300px 0px;
}
.joypixels-24-symbols._1f539 {
    background-position: -3325px 0px;
}
.joypixels-24-symbols._1f53a {
    background-position: -3350px 0px;
}
.joypixels-24-symbols._1f53b {
    background-position: -3375px 0px;
}
.joypixels-24-symbols._1f53c {
    background-position: -3400px 0px;
}
.joypixels-24-symbols._1f53d {
    background-position: -3425px 0px;
}
.joypixels-24-symbols._1f549 {
    background-position: -3450px 0px;
}
.joypixels-24-symbols._1f54e {
    background-position: -3475px 0px;
}
.joypixels-24-symbols._1f550 {
    background-position: -3500px 0px;
}
.joypixels-24-symbols._1f551 {
    background-position: -3525px 0px;
}
.joypixels-24-symbols._1f552 {
    background-position: -3550px 0px;
}
.joypixels-24-symbols._1f553 {
    background-position: -3575px 0px;
}
.joypixels-24-symbols._1f554 {
    background-position: -3600px 0px;
}
.joypixels-24-symbols._1f555 {
    background-position: -3625px 0px;
}
.joypixels-24-symbols._1f556 {
    background-position: -3650px 0px;
}
.joypixels-24-symbols._1f557 {
    background-position: -3675px 0px;
}
.joypixels-24-symbols._1f558 {
    background-position: -3700px 0px;
}
.joypixels-24-symbols._1f559 {
    background-position: -3725px 0px;
}
.joypixels-24-symbols._1f55a {
    background-position: -3750px 0px;
}
.joypixels-24-symbols._1f55b {
    background-position: -3775px 0px;
}
.joypixels-24-symbols._1f55c {
    background-position: -3800px 0px;
}
.joypixels-24-symbols._1f55d {
    background-position: -3825px 0px;
}
.joypixels-24-symbols._1f55e {
    background-position: -3850px 0px;
}
.joypixels-24-symbols._1f55f {
    background-position: -3875px 0px;
}
.joypixels-24-symbols._1f560 {
    background-position: -3900px 0px;
}
.joypixels-24-symbols._1f561 {
    background-position: -3925px 0px;
}
.joypixels-24-symbols._1f562 {
    background-position: -3950px 0px;
}
.joypixels-24-symbols._1f563 {
    background-position: -3975px 0px;
}
.joypixels-24-symbols._1f564 {
    background-position: -4000px 0px;
}
.joypixels-24-symbols._1f565 {
    background-position: -4025px 0px;
}
.joypixels-24-symbols._1f566 {
    background-position: -4050px 0px;
}
.joypixels-24-symbols._1f567 {
    background-position: -4075px 0px;
}
.joypixels-24-symbols._1f5a4 {
    background-position: -4100px 0px;
}
.joypixels-24-symbols._1f5e8 {
    background-position: -4125px 0px;
}
.joypixels-24-symbols._1f5ef {
    background-position: -4150px 0px;
}
.joypixels-24-symbols._1f6ab {
    background-position: -4175px 0px;
}
.joypixels-24-symbols._1f6ad {
    background-position: -4200px 0px;
}
.joypixels-24-symbols._1f6ae {
    background-position: -4225px 0px;
}
.joypixels-24-symbols._1f6af {
    background-position: -4250px 0px;
}
.joypixels-24-symbols._1f6b1 {
    background-position: -4275px 0px;
}
.joypixels-24-symbols._1f6b3 {
    background-position: -4300px 0px;
}
.joypixels-24-symbols._1f6b7 {
    background-position: -4325px 0px;
}
.joypixels-24-symbols._1f6b8 {
    background-position: -4350px 0px;
}
.joypixels-24-symbols._1f6b9 {
    background-position: -4375px 0px;
}
.joypixels-24-symbols._1f6ba {
    background-position: -4400px 0px;
}
.joypixels-24-symbols._1f6bb {
    background-position: -4425px 0px;
}
.joypixels-24-symbols._1f6bc {
    background-position: -4450px 0px;
}
.joypixels-24-symbols._1f6be {
    background-position: -4475px 0px;
}
.joypixels-24-symbols._1f6c2 {
    background-position: -4500px 0px;
}
.joypixels-24-symbols._1f6c3 {
    background-position: -4525px 0px;
}
.joypixels-24-symbols._1f6c4 {
    background-position: -4550px 0px;
}
.joypixels-24-symbols._1f6c5 {
    background-position: -4575px 0px;
}
.joypixels-24-symbols._1f6d0 {
    background-position: -4600px 0px;
}
.joypixels-24-symbols._1f6d1 {
    background-position: -4625px 0px;
}
.joypixels-24-symbols._1f6d7 {
    background-position: -4650px 0px;
}
.joypixels-24-symbols._1f7e0 {
    background-position: -4675px 0px;
}
.joypixels-24-symbols._1f7e1 {
    background-position: -4700px 0px;
}
.joypixels-24-symbols._1f7e2 {
    background-position: -4725px 0px;
}
.joypixels-24-symbols._1f7e3 {
    background-position: -4750px 0px;
}
.joypixels-24-symbols._1f7e4 {
    background-position: -4775px 0px;
}
.joypixels-24-symbols._1f7e5 {
    background-position: -4800px 0px;
}
.joypixels-24-symbols._1f7e6 {
    background-position: -4825px 0px;
}
.joypixels-24-symbols._1f7e7 {
    background-position: -4850px 0px;
}
.joypixels-24-symbols._1f7e8 {
    background-position: -4875px 0px;
}
.joypixels-24-symbols._1f7e9 {
    background-position: -4900px 0px;
}
.joypixels-24-symbols._1f7ea {
    background-position: -4925px 0px;
}
.joypixels-24-symbols._1f7eb {
    background-position: -4950px 0px;
}
.joypixels-24-symbols._1f7f0 {
    background-position: -4975px 0px;
}
.joypixels-24-symbols._1f90d {
    background-position: -5000px 0px;
}
.joypixels-24-symbols._1f90e {
    background-position: -5025px 0px;
}
.joypixels-24-symbols._1f9e1 {
    background-position: -5050px 0px;
}
.joypixels-24-symbols._203c {
    background-position: -5075px 0px;
}
.joypixels-24-symbols._2049 {
    background-position: -5100px 0px;
}
.joypixels-24-symbols._2122 {
    background-position: -5125px 0px;
}
.joypixels-24-symbols._2139 {
    background-position: -5150px 0px;
}
.joypixels-24-symbols._2194 {
    background-position: -5175px 0px;
}
.joypixels-24-symbols._2195 {
    background-position: -5200px 0px;
}
.joypixels-24-symbols._2196 {
    background-position: -5225px 0px;
}
.joypixels-24-symbols._2197 {
    background-position: -5250px 0px;
}
.joypixels-24-symbols._2198 {
    background-position: -5275px 0px;
}
.joypixels-24-symbols._2199 {
    background-position: -5300px 0px;
}
.joypixels-24-symbols._21a9 {
    background-position: -5325px 0px;
}
.joypixels-24-symbols._21aa {
    background-position: -5350px 0px;
}
.joypixels-24-symbols._23cf {
    background-position: -5375px 0px;
}
.joypixels-24-symbols._23e9 {
    background-position: -5400px 0px;
}
.joypixels-24-symbols._23ea {
    background-position: -5425px 0px;
}
.joypixels-24-symbols._23eb {
    background-position: -5450px 0px;
}
.joypixels-24-symbols._23ec {
    background-position: -5475px 0px;
}
.joypixels-24-symbols._23ed {
    background-position: -5500px 0px;
}
.joypixels-24-symbols._23ee {
    background-position: -5525px 0px;
}
.joypixels-24-symbols._23ef {
    background-position: -5550px 0px;
}
.joypixels-24-symbols._23f8 {
    background-position: -5575px 0px;
}
.joypixels-24-symbols._23f9 {
    background-position: -5600px 0px;
}
.joypixels-24-symbols._23fa {
    background-position: -5625px 0px;
}
.joypixels-24-symbols._24c2 {
    background-position: -5650px 0px;
}
.joypixels-24-symbols._25aa {
    background-position: -5675px 0px;
}
.joypixels-24-symbols._25ab {
    background-position: -5700px 0px;
}
.joypixels-24-symbols._25b6 {
    background-position: -5725px 0px;
}
.joypixels-24-symbols._25c0 {
    background-position: -5750px 0px;
}
.joypixels-24-symbols._25fb {
    background-position: -5775px 0px;
}
.joypixels-24-symbols._25fc {
    background-position: -5800px 0px;
}
.joypixels-24-symbols._25fd {
    background-position: -5825px 0px;
}
.joypixels-24-symbols._25fe {
    background-position: -5850px 0px;
}
.joypixels-24-symbols._2611 {
    background-position: -5875px 0px;
}
.joypixels-24-symbols._2622 {
    background-position: -5900px 0px;
}
.joypixels-24-symbols._2623 {
    background-position: -5925px 0px;
}
.joypixels-24-symbols._2626 {
    background-position: -5950px 0px;
}
.joypixels-24-symbols._262a {
    background-position: -5975px 0px;
}
.joypixels-24-symbols._262e {
    background-position: -6000px 0px;
}
.joypixels-24-symbols._262f {
    background-position: -6025px 0px;
}
.joypixels-24-symbols._2638 {
    background-position: -6050px 0px;
}
.joypixels-24-symbols._2640 {
    background-position: -6075px 0px;
}
.joypixels-24-symbols._2642 {
    background-position: -6100px 0px;
}
.joypixels-24-symbols._2648 {
    background-position: -6125px 0px;
}
.joypixels-24-symbols._2649 {
    background-position: -6150px 0px;
}
.joypixels-24-symbols._264a {
    background-position: -6175px 0px;
}
.joypixels-24-symbols._264b {
    background-position: -6200px 0px;
}
.joypixels-24-symbols._264c {
    background-position: -6225px 0px;
}
.joypixels-24-symbols._264d {
    background-position: -6250px 0px;
}
.joypixels-24-symbols._264e {
    background-position: -6275px 0px;
}
.joypixels-24-symbols._264f {
    background-position: -6300px 0px;
}
.joypixels-24-symbols._2650 {
    background-position: -6325px 0px;
}
.joypixels-24-symbols._2651 {
    background-position: -6350px 0px;
}
.joypixels-24-symbols._2652 {
    background-position: -6375px 0px;
}
.joypixels-24-symbols._2653 {
    background-position: -6400px 0px;
}
.joypixels-24-symbols._2660 {
    background-position: -6425px 0px;
}
.joypixels-24-symbols._2663 {
    background-position: -6450px 0px;
}
.joypixels-24-symbols._2665 {
    background-position: -6475px 0px;
}
.joypixels-24-symbols._2666 {
    background-position: -6500px 0px;
}
.joypixels-24-symbols._2668 {
    background-position: -6525px 0px;
}
.joypixels-24-symbols._267b {
    background-position: -6550px 0px;
}
.joypixels-24-symbols._267e {
    background-position: -6575px 0px;
}
.joypixels-24-symbols._267f {
    background-position: -6600px 0px;
}
.joypixels-24-symbols._2695 {
    background-position: -6625px 0px;
}
.joypixels-24-symbols._269b {
    background-position: -6650px 0px;
}
.joypixels-24-symbols._269c {
    background-position: -6675px 0px;
}
.joypixels-24-symbols._26a0 {
    background-position: -6700px 0px;
}
.joypixels-24-symbols._26a7 {
    background-position: -6725px 0px;
}
.joypixels-24-symbols._26aa {
    background-position: -6750px 0px;
}
.joypixels-24-symbols._26ab {
    background-position: -6775px 0px;
}
.joypixels-24-symbols._26ce {
    background-position: -6800px 0px;
}
.joypixels-24-symbols._26d4 {
    background-position: -6825px 0px;
}
.joypixels-24-symbols._2705 {
    background-position: -6850px 0px;
}
.joypixels-24-symbols._2714 {
    background-position: -6875px 0px;
}
.joypixels-24-symbols._2716 {
    background-position: -6900px 0px;
}
.joypixels-24-symbols._271d {
    background-position: -6925px 0px;
}
.joypixels-24-symbols._2721 {
    background-position: -6950px 0px;
}
.joypixels-24-symbols._2733 {
    background-position: -6975px 0px;
}
.joypixels-24-symbols._2734 {
    background-position: -7000px 0px;
}
.joypixels-24-symbols._2747 {
    background-position: -7025px 0px;
}
.joypixels-24-symbols._274c {
    background-position: -7050px 0px;
}
.joypixels-24-symbols._274e {
    background-position: -7075px 0px;
}
.joypixels-24-symbols._2753 {
    background-position: -7100px 0px;
}
.joypixels-24-symbols._2754 {
    background-position: -7125px 0px;
}
.joypixels-24-symbols._2755 {
    background-position: -7150px 0px;
}
.joypixels-24-symbols._2757 {
    background-position: -7175px 0px;
}
.joypixels-24-symbols._2763 {
    background-position: -7200px 0px;
}
.joypixels-24-symbols._2764-1f525 {
    background-position: -7225px 0px;
}
.joypixels-24-symbols._2764-1fa79 {
    background-position: -7250px 0px;
}
.joypixels-24-symbols._2764 {
    background-position: -7275px 0px;
}
.joypixels-24-symbols._2795 {
    background-position: -7300px 0px;
}
.joypixels-24-symbols._2796 {
    background-position: -7325px 0px;
}
.joypixels-24-symbols._2797 {
    background-position: -7350px 0px;
}
.joypixels-24-symbols._27a1 {
    background-position: -7375px 0px;
}
.joypixels-24-symbols._27b0 {
    background-position: -7400px 0px;
}
.joypixels-24-symbols._27bf {
    background-position: -7425px 0px;
}
.joypixels-24-symbols._2934 {
    background-position: -7450px 0px;
}
.joypixels-24-symbols._2935 {
    background-position: -7475px 0px;
}
.joypixels-24-symbols._2b05 {
    background-position: -7500px 0px;
}
.joypixels-24-symbols._2b06 {
    background-position: -7525px 0px;
}
.joypixels-24-symbols._2b07 {
    background-position: -7550px 0px;
}
.joypixels-24-symbols._2b1b {
    background-position: -7575px 0px;
}
.joypixels-24-symbols._2b1c {
    background-position: -7600px 0px;
}
.joypixels-24-symbols._2b55 {
    background-position: -7625px 0px;
}
.joypixels-24-symbols._3030 {
    background-position: -7650px 0px;
}
.joypixels-24-symbols._303d {
    background-position: -7675px 0px;
}
.joypixels-24-symbols._3297 {
    background-position: -7700px 0px;
}
.joypixels-24-symbols._3299 {
    background-position: -7725px 0px;
}

.joypixels-24-flags {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-flags.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-flags {
        background-image: url(joypixels-sprite-24-flags@2x.png);
        background-size: 6749px 24px;
    }
}


.joypixels-24-flags._00000 {
    background-position: 0px 0px;
}
.joypixels-24-flags._1f1e6-1f1e8 {
    background-position: -25px 0px;
}
.joypixels-24-flags._1f1e6-1f1e9 {
    background-position: -50px 0px;
}
.joypixels-24-flags._1f1e6-1f1ea {
    background-position: -75px 0px;
}
.joypixels-24-flags._1f1e6-1f1eb {
    background-position: -100px 0px;
}
.joypixels-24-flags._1f1e6-1f1ec {
    background-position: -125px 0px;
}
.joypixels-24-flags._1f1e6-1f1ee {
    background-position: -150px 0px;
}
.joypixels-24-flags._1f1e6-1f1f1 {
    background-position: -175px 0px;
}
.joypixels-24-flags._1f1e6-1f1f2 {
    background-position: -200px 0px;
}
.joypixels-24-flags._1f1e6-1f1f4 {
    background-position: -225px 0px;
}
.joypixels-24-flags._1f1e6-1f1f6 {
    background-position: -250px 0px;
}
.joypixels-24-flags._1f1e6-1f1f7 {
    background-position: -275px 0px;
}
.joypixels-24-flags._1f1e6-1f1f8 {
    background-position: -300px 0px;
}
.joypixels-24-flags._1f1e6-1f1f9 {
    background-position: -325px 0px;
}
.joypixels-24-flags._1f1e6-1f1fa {
    background-position: -350px 0px;
}
.joypixels-24-flags._1f1e6-1f1fc {
    background-position: -375px 0px;
}
.joypixels-24-flags._1f1e6-1f1fd {
    background-position: -400px 0px;
}
.joypixels-24-flags._1f1e6-1f1ff {
    background-position: -425px 0px;
}
.joypixels-24-flags._1f1e7-1f1e6 {
    background-position: -450px 0px;
}
.joypixels-24-flags._1f1e7-1f1e7 {
    background-position: -475px 0px;
}
.joypixels-24-flags._1f1e7-1f1e9 {
    background-position: -500px 0px;
}
.joypixels-24-flags._1f1e7-1f1ea {
    background-position: -525px 0px;
}
.joypixels-24-flags._1f1e7-1f1eb {
    background-position: -550px 0px;
}
.joypixels-24-flags._1f1e7-1f1ec {
    background-position: -575px 0px;
}
.joypixels-24-flags._1f1e7-1f1ed {
    background-position: -600px 0px;
}
.joypixels-24-flags._1f1e7-1f1ee {
    background-position: -625px 0px;
}
.joypixels-24-flags._1f1e7-1f1ef {
    background-position: -650px 0px;
}
.joypixels-24-flags._1f1e7-1f1f1 {
    background-position: -675px 0px;
}
.joypixels-24-flags._1f1e7-1f1f2 {
    background-position: -700px 0px;
}
.joypixels-24-flags._1f1e7-1f1f3 {
    background-position: -725px 0px;
}
.joypixels-24-flags._1f1e7-1f1f4 {
    background-position: -750px 0px;
}
.joypixels-24-flags._1f1e7-1f1f6 {
    background-position: -775px 0px;
}
.joypixels-24-flags._1f1e7-1f1f7 {
    background-position: -800px 0px;
}
.joypixels-24-flags._1f1e7-1f1f8 {
    background-position: -825px 0px;
}
.joypixels-24-flags._1f1e7-1f1f9 {
    background-position: -850px 0px;
}
.joypixels-24-flags._1f1e7-1f1fb {
    background-position: -875px 0px;
}
.joypixels-24-flags._1f1e7-1f1fc {
    background-position: -900px 0px;
}
.joypixels-24-flags._1f1e7-1f1fe {
    background-position: -925px 0px;
}
.joypixels-24-flags._1f1e7-1f1ff {
    background-position: -950px 0px;
}
.joypixels-24-flags._1f1e8-1f1e6 {
    background-position: -975px 0px;
}
.joypixels-24-flags._1f1e8-1f1e8 {
    background-position: -1000px 0px;
}
.joypixels-24-flags._1f1e8-1f1e9 {
    background-position: -1025px 0px;
}
.joypixels-24-flags._1f1e8-1f1eb {
    background-position: -1050px 0px;
}
.joypixels-24-flags._1f1e8-1f1ec {
    background-position: -1075px 0px;
}
.joypixels-24-flags._1f1e8-1f1ed {
    background-position: -1100px 0px;
}
.joypixels-24-flags._1f1e8-1f1ee {
    background-position: -1125px 0px;
}
.joypixels-24-flags._1f1e8-1f1f0 {
    background-position: -1150px 0px;
}
.joypixels-24-flags._1f1e8-1f1f1 {
    background-position: -1175px 0px;
}
.joypixels-24-flags._1f1e8-1f1f2 {
    background-position: -1200px 0px;
}
.joypixels-24-flags._1f1e8-1f1f3 {
    background-position: -1225px 0px;
}
.joypixels-24-flags._1f1e8-1f1f4 {
    background-position: -1250px 0px;
}
.joypixels-24-flags._1f1e8-1f1f5 {
    background-position: -1275px 0px;
}
.joypixels-24-flags._1f1e8-1f1f7 {
    background-position: -1300px 0px;
}
.joypixels-24-flags._1f1e8-1f1fa {
    background-position: -1325px 0px;
}
.joypixels-24-flags._1f1e8-1f1fb {
    background-position: -1350px 0px;
}
.joypixels-24-flags._1f1e8-1f1fc {
    background-position: -1375px 0px;
}
.joypixels-24-flags._1f1e8-1f1fd {
    background-position: -1400px 0px;
}
.joypixels-24-flags._1f1e8-1f1fe {
    background-position: -1425px 0px;
}
.joypixels-24-flags._1f1e8-1f1ff {
    background-position: -1450px 0px;
}
.joypixels-24-flags._1f1e9-1f1ea {
    background-position: -1475px 0px;
}
.joypixels-24-flags._1f1e9-1f1ec {
    background-position: -1500px 0px;
}
.joypixels-24-flags._1f1e9-1f1ef {
    background-position: -1525px 0px;
}
.joypixels-24-flags._1f1e9-1f1f0 {
    background-position: -1550px 0px;
}
.joypixels-24-flags._1f1e9-1f1f2 {
    background-position: -1575px 0px;
}
.joypixels-24-flags._1f1e9-1f1f4 {
    background-position: -1600px 0px;
}
.joypixels-24-flags._1f1e9-1f1ff {
    background-position: -1625px 0px;
}
.joypixels-24-flags._1f1ea-1f1e6 {
    background-position: -1650px 0px;
}
.joypixels-24-flags._1f1ea-1f1e8 {
    background-position: -1675px 0px;
}
.joypixels-24-flags._1f1ea-1f1ea {
    background-position: -1700px 0px;
}
.joypixels-24-flags._1f1ea-1f1ec {
    background-position: -1725px 0px;
}
.joypixels-24-flags._1f1ea-1f1ed {
    background-position: -1750px 0px;
}
.joypixels-24-flags._1f1ea-1f1f7 {
    background-position: -1775px 0px;
}
.joypixels-24-flags._1f1ea-1f1f8 {
    background-position: -1800px 0px;
}
.joypixels-24-flags._1f1ea-1f1f9 {
    background-position: -1825px 0px;
}
.joypixels-24-flags._1f1ea-1f1fa {
    background-position: -1850px 0px;
}
.joypixels-24-flags._1f1eb-1f1ee {
    background-position: -1875px 0px;
}
.joypixels-24-flags._1f1eb-1f1ef {
    background-position: -1900px 0px;
}
.joypixels-24-flags._1f1eb-1f1f0 {
    background-position: -1925px 0px;
}
.joypixels-24-flags._1f1eb-1f1f2 {
    background-position: -1950px 0px;
}
.joypixels-24-flags._1f1eb-1f1f4 {
    background-position: -1975px 0px;
}
.joypixels-24-flags._1f1eb-1f1f7 {
    background-position: -2000px 0px;
}
.joypixels-24-flags._1f1ec-1f1e6 {
    background-position: -2025px 0px;
}
.joypixels-24-flags._1f1ec-1f1e7 {
    background-position: -2050px 0px;
}
.joypixels-24-flags._1f1ec-1f1e9 {
    background-position: -2075px 0px;
}
.joypixels-24-flags._1f1ec-1f1ea {
    background-position: -2100px 0px;
}
.joypixels-24-flags._1f1ec-1f1eb {
    background-position: -2125px 0px;
}
.joypixels-24-flags._1f1ec-1f1ec {
    background-position: -2150px 0px;
}
.joypixels-24-flags._1f1ec-1f1ed {
    background-position: -2175px 0px;
}
.joypixels-24-flags._1f1ec-1f1ee {
    background-position: -2200px 0px;
}
.joypixels-24-flags._1f1ec-1f1f1 {
    background-position: -2225px 0px;
}
.joypixels-24-flags._1f1ec-1f1f2 {
    background-position: -2250px 0px;
}
.joypixels-24-flags._1f1ec-1f1f3 {
    background-position: -2275px 0px;
}
.joypixels-24-flags._1f1ec-1f1f5 {
    background-position: -2300px 0px;
}
.joypixels-24-flags._1f1ec-1f1f6 {
    background-position: -2325px 0px;
}
.joypixels-24-flags._1f1ec-1f1f7 {
    background-position: -2350px 0px;
}
.joypixels-24-flags._1f1ec-1f1f8 {
    background-position: -2375px 0px;
}
.joypixels-24-flags._1f1ec-1f1f9 {
    background-position: -2400px 0px;
}
.joypixels-24-flags._1f1ec-1f1fa {
    background-position: -2425px 0px;
}
.joypixels-24-flags._1f1ec-1f1fc {
    background-position: -2450px 0px;
}
.joypixels-24-flags._1f1ec-1f1fe {
    background-position: -2475px 0px;
}
.joypixels-24-flags._1f1ed-1f1f0 {
    background-position: -2500px 0px;
}
.joypixels-24-flags._1f1ed-1f1f2 {
    background-position: -2525px 0px;
}
.joypixels-24-flags._1f1ed-1f1f3 {
    background-position: -2550px 0px;
}
.joypixels-24-flags._1f1ed-1f1f7 {
    background-position: -2575px 0px;
}
.joypixels-24-flags._1f1ed-1f1f9 {
    background-position: -2600px 0px;
}
.joypixels-24-flags._1f1ed-1f1fa {
    background-position: -2625px 0px;
}
.joypixels-24-flags._1f1ee-1f1e8 {
    background-position: -2650px 0px;
}
.joypixels-24-flags._1f1ee-1f1e9 {
    background-position: -2675px 0px;
}
.joypixels-24-flags._1f1ee-1f1ea {
    background-position: -2700px 0px;
}
.joypixels-24-flags._1f1ee-1f1f1 {
    background-position: -2725px 0px;
}
.joypixels-24-flags._1f1ee-1f1f2 {
    background-position: -2750px 0px;
}
.joypixels-24-flags._1f1ee-1f1f3 {
    background-position: -2775px 0px;
}
.joypixels-24-flags._1f1ee-1f1f4 {
    background-position: -2800px 0px;
}
.joypixels-24-flags._1f1ee-1f1f6 {
    background-position: -2825px 0px;
}
.joypixels-24-flags._1f1ee-1f1f7 {
    background-position: -2850px 0px;
}
.joypixels-24-flags._1f1ee-1f1f8 {
    background-position: -2875px 0px;
}
.joypixels-24-flags._1f1ee-1f1f9 {
    background-position: -2900px 0px;
}
.joypixels-24-flags._1f1ef-1f1ea {
    background-position: -2925px 0px;
}
.joypixels-24-flags._1f1ef-1f1f2 {
    background-position: -2950px 0px;
}
.joypixels-24-flags._1f1ef-1f1f4 {
    background-position: -2975px 0px;
}
.joypixels-24-flags._1f1ef-1f1f5 {
    background-position: -3000px 0px;
}
.joypixels-24-flags._1f1f0-1f1ea {
    background-position: -3025px 0px;
}
.joypixels-24-flags._1f1f0-1f1ec {
    background-position: -3050px 0px;
}
.joypixels-24-flags._1f1f0-1f1ed {
    background-position: -3075px 0px;
}
.joypixels-24-flags._1f1f0-1f1ee {
    background-position: -3100px 0px;
}
.joypixels-24-flags._1f1f0-1f1f2 {
    background-position: -3125px 0px;
}
.joypixels-24-flags._1f1f0-1f1f3 {
    background-position: -3150px 0px;
}
.joypixels-24-flags._1f1f0-1f1f5 {
    background-position: -3175px 0px;
}
.joypixels-24-flags._1f1f0-1f1f7 {
    background-position: -3200px 0px;
}
.joypixels-24-flags._1f1f0-1f1fc {
    background-position: -3225px 0px;
}
.joypixels-24-flags._1f1f0-1f1fe {
    background-position: -3250px 0px;
}
.joypixels-24-flags._1f1f0-1f1ff {
    background-position: -3275px 0px;
}
.joypixels-24-flags._1f1f1-1f1e6 {
    background-position: -3300px 0px;
}
.joypixels-24-flags._1f1f1-1f1e7 {
    background-position: -3325px 0px;
}
.joypixels-24-flags._1f1f1-1f1e8 {
    background-position: -3350px 0px;
}
.joypixels-24-flags._1f1f1-1f1ee {
    background-position: -3375px 0px;
}
.joypixels-24-flags._1f1f1-1f1f0 {
    background-position: -3400px 0px;
}
.joypixels-24-flags._1f1f1-1f1f7 {
    background-position: -3425px 0px;
}
.joypixels-24-flags._1f1f1-1f1f8 {
    background-position: -3450px 0px;
}
.joypixels-24-flags._1f1f1-1f1f9 {
    background-position: -3475px 0px;
}
.joypixels-24-flags._1f1f1-1f1fa {
    background-position: -3500px 0px;
}
.joypixels-24-flags._1f1f1-1f1fb {
    background-position: -3525px 0px;
}
.joypixels-24-flags._1f1f1-1f1fe {
    background-position: -3550px 0px;
}
.joypixels-24-flags._1f1f2-1f1e6 {
    background-position: -3575px 0px;
}
.joypixels-24-flags._1f1f2-1f1e8 {
    background-position: -3600px 0px;
}
.joypixels-24-flags._1f1f2-1f1e9 {
    background-position: -3625px 0px;
}
.joypixels-24-flags._1f1f2-1f1ea {
    background-position: -3650px 0px;
}
.joypixels-24-flags._1f1f2-1f1eb {
    background-position: -3675px 0px;
}
.joypixels-24-flags._1f1f2-1f1ec {
    background-position: -3700px 0px;
}
.joypixels-24-flags._1f1f2-1f1ed {
    background-position: -3725px 0px;
}
.joypixels-24-flags._1f1f2-1f1f0 {
    background-position: -3750px 0px;
}
.joypixels-24-flags._1f1f2-1f1f1 {
    background-position: -3775px 0px;
}
.joypixels-24-flags._1f1f2-1f1f2 {
    background-position: -3800px 0px;
}
.joypixels-24-flags._1f1f2-1f1f3 {
    background-position: -3825px 0px;
}
.joypixels-24-flags._1f1f2-1f1f4 {
    background-position: -3850px 0px;
}
.joypixels-24-flags._1f1f2-1f1f5 {
    background-position: -3875px 0px;
}
.joypixels-24-flags._1f1f2-1f1f6 {
    background-position: -3900px 0px;
}
.joypixels-24-flags._1f1f2-1f1f7 {
    background-position: -3925px 0px;
}
.joypixels-24-flags._1f1f2-1f1f8 {
    background-position: -3950px 0px;
}
.joypixels-24-flags._1f1f2-1f1f9 {
    background-position: -3975px 0px;
}
.joypixels-24-flags._1f1f2-1f1fa {
    background-position: -4000px 0px;
}
.joypixels-24-flags._1f1f2-1f1fb {
    background-position: -4025px 0px;
}
.joypixels-24-flags._1f1f2-1f1fc {
    background-position: -4050px 0px;
}
.joypixels-24-flags._1f1f2-1f1fd {
    background-position: -4075px 0px;
}
.joypixels-24-flags._1f1f2-1f1fe {
    background-position: -4100px 0px;
}
.joypixels-24-flags._1f1f2-1f1ff {
    background-position: -4125px 0px;
}
.joypixels-24-flags._1f1f3-1f1e6 {
    background-position: -4150px 0px;
}
.joypixels-24-flags._1f1f3-1f1e8 {
    background-position: -4175px 0px;
}
.joypixels-24-flags._1f1f3-1f1ea {
    background-position: -4200px 0px;
}
.joypixels-24-flags._1f1f3-1f1eb {
    background-position: -4225px 0px;
}
.joypixels-24-flags._1f1f3-1f1ec {
    background-position: -4250px 0px;
}
.joypixels-24-flags._1f1f3-1f1ee {
    background-position: -4275px 0px;
}
.joypixels-24-flags._1f1f3-1f1f1 {
    background-position: -4300px 0px;
}
.joypixels-24-flags._1f1f3-1f1f4 {
    background-position: -4325px 0px;
}
.joypixels-24-flags._1f1f3-1f1f5 {
    background-position: -4350px 0px;
}
.joypixels-24-flags._1f1f3-1f1f7 {
    background-position: -4375px 0px;
}
.joypixels-24-flags._1f1f3-1f1fa {
    background-position: -4400px 0px;
}
.joypixels-24-flags._1f1f3-1f1ff {
    background-position: -4425px 0px;
}
.joypixels-24-flags._1f1f4-1f1f2 {
    background-position: -4450px 0px;
}
.joypixels-24-flags._1f1f5-1f1e6 {
    background-position: -4475px 0px;
}
.joypixels-24-flags._1f1f5-1f1ea {
    background-position: -4500px 0px;
}
.joypixels-24-flags._1f1f5-1f1eb {
    background-position: -4525px 0px;
}
.joypixels-24-flags._1f1f5-1f1ec {
    background-position: -4550px 0px;
}
.joypixels-24-flags._1f1f5-1f1ed {
    background-position: -4575px 0px;
}
.joypixels-24-flags._1f1f5-1f1f0 {
    background-position: -4600px 0px;
}
.joypixels-24-flags._1f1f5-1f1f1 {
    background-position: -4625px 0px;
}
.joypixels-24-flags._1f1f5-1f1f2 {
    background-position: -4650px 0px;
}
.joypixels-24-flags._1f1f5-1f1f3 {
    background-position: -4675px 0px;
}
.joypixels-24-flags._1f1f5-1f1f7 {
    background-position: -4700px 0px;
}
.joypixels-24-flags._1f1f5-1f1f8 {
    background-position: -4725px 0px;
}
.joypixels-24-flags._1f1f5-1f1f9 {
    background-position: -4750px 0px;
}
.joypixels-24-flags._1f1f5-1f1fc {
    background-position: -4775px 0px;
}
.joypixels-24-flags._1f1f5-1f1fe {
    background-position: -4800px 0px;
}
.joypixels-24-flags._1f1f6-1f1e6 {
    background-position: -4825px 0px;
}
.joypixels-24-flags._1f1f7-1f1ea {
    background-position: -4850px 0px;
}
.joypixels-24-flags._1f1f7-1f1f4 {
    background-position: -4875px 0px;
}
.joypixels-24-flags._1f1f7-1f1f8 {
    background-position: -4900px 0px;
}
.joypixels-24-flags._1f1f7-1f1fa {
    background-position: -4925px 0px;
}
.joypixels-24-flags._1f1f7-1f1fc {
    background-position: -4950px 0px;
}
.joypixels-24-flags._1f1f8-1f1e6 {
    background-position: -4975px 0px;
}
.joypixels-24-flags._1f1f8-1f1e7 {
    background-position: -5000px 0px;
}
.joypixels-24-flags._1f1f8-1f1e8 {
    background-position: -5025px 0px;
}
.joypixels-24-flags._1f1f8-1f1e9 {
    background-position: -5050px 0px;
}
.joypixels-24-flags._1f1f8-1f1ea {
    background-position: -5075px 0px;
}
.joypixels-24-flags._1f1f8-1f1ec {
    background-position: -5100px 0px;
}
.joypixels-24-flags._1f1f8-1f1ed {
    background-position: -5125px 0px;
}
.joypixels-24-flags._1f1f8-1f1ee {
    background-position: -5150px 0px;
}
.joypixels-24-flags._1f1f8-1f1ef {
    background-position: -5175px 0px;
}
.joypixels-24-flags._1f1f8-1f1f0 {
    background-position: -5200px 0px;
}
.joypixels-24-flags._1f1f8-1f1f1 {
    background-position: -5225px 0px;
}
.joypixels-24-flags._1f1f8-1f1f2 {
    background-position: -5250px 0px;
}
.joypixels-24-flags._1f1f8-1f1f3 {
    background-position: -5275px 0px;
}
.joypixels-24-flags._1f1f8-1f1f4 {
    background-position: -5300px 0px;
}
.joypixels-24-flags._1f1f8-1f1f7 {
    background-position: -5325px 0px;
}
.joypixels-24-flags._1f1f8-1f1f8 {
    background-position: -5350px 0px;
}
.joypixels-24-flags._1f1f8-1f1f9 {
    background-position: -5375px 0px;
}
.joypixels-24-flags._1f1f8-1f1fb {
    background-position: -5400px 0px;
}
.joypixels-24-flags._1f1f8-1f1fd {
    background-position: -5425px 0px;
}
.joypixels-24-flags._1f1f8-1f1fe {
    background-position: -5450px 0px;
}
.joypixels-24-flags._1f1f8-1f1ff {
    background-position: -5475px 0px;
}
.joypixels-24-flags._1f1f9-1f1e6 {
    background-position: -5500px 0px;
}
.joypixels-24-flags._1f1f9-1f1e8 {
    background-position: -5525px 0px;
}
.joypixels-24-flags._1f1f9-1f1e9 {
    background-position: -5550px 0px;
}
.joypixels-24-flags._1f1f9-1f1eb {
    background-position: -5575px 0px;
}
.joypixels-24-flags._1f1f9-1f1ec {
    background-position: -5600px 0px;
}
.joypixels-24-flags._1f1f9-1f1ed {
    background-position: -5625px 0px;
}
.joypixels-24-flags._1f1f9-1f1ef {
    background-position: -5650px 0px;
}
.joypixels-24-flags._1f1f9-1f1f0 {
    background-position: -5675px 0px;
}
.joypixels-24-flags._1f1f9-1f1f1 {
    background-position: -5700px 0px;
}
.joypixels-24-flags._1f1f9-1f1f2 {
    background-position: -5725px 0px;
}
.joypixels-24-flags._1f1f9-1f1f3 {
    background-position: -5750px 0px;
}
.joypixels-24-flags._1f1f9-1f1f4 {
    background-position: -5775px 0px;
}
.joypixels-24-flags._1f1f9-1f1f7 {
    background-position: -5800px 0px;
}
.joypixels-24-flags._1f1f9-1f1f9 {
    background-position: -5825px 0px;
}
.joypixels-24-flags._1f1f9-1f1fb {
    background-position: -5850px 0px;
}
.joypixels-24-flags._1f1f9-1f1fc {
    background-position: -5875px 0px;
}
.joypixels-24-flags._1f1f9-1f1ff {
    background-position: -5900px 0px;
}
.joypixels-24-flags._1f1fa-1f1e6 {
    background-position: -5925px 0px;
}
.joypixels-24-flags._1f1fa-1f1ec {
    background-position: -5950px 0px;
}
.joypixels-24-flags._1f1fa-1f1f2 {
    background-position: -5975px 0px;
}
.joypixels-24-flags._1f1fa-1f1f3 {
    background-position: -6000px 0px;
}
.joypixels-24-flags._1f1fa-1f1f8 {
    background-position: -6025px 0px;
}
.joypixels-24-flags._1f1fa-1f1fe {
    background-position: -6050px 0px;
}
.joypixels-24-flags._1f1fa-1f1ff {
    background-position: -6075px 0px;
}
.joypixels-24-flags._1f1fb-1f1e6 {
    background-position: -6100px 0px;
}
.joypixels-24-flags._1f1fb-1f1e8 {
    background-position: -6125px 0px;
}
.joypixels-24-flags._1f1fb-1f1ea {
    background-position: -6150px 0px;
}
.joypixels-24-flags._1f1fb-1f1ec {
    background-position: -6175px 0px;
}
.joypixels-24-flags._1f1fb-1f1ee {
    background-position: -6200px 0px;
}
.joypixels-24-flags._1f1fb-1f1f3 {
    background-position: -6225px 0px;
}
.joypixels-24-flags._1f1fb-1f1fa {
    background-position: -6250px 0px;
}
.joypixels-24-flags._1f1fc-1f1eb {
    background-position: -6275px 0px;
}
.joypixels-24-flags._1f1fc-1f1f8 {
    background-position: -6300px 0px;
}
.joypixels-24-flags._1f1fd-1f1f0 {
    background-position: -6325px 0px;
}
.joypixels-24-flags._1f1fe-1f1ea {
    background-position: -6350px 0px;
}
.joypixels-24-flags._1f1fe-1f1f9 {
    background-position: -6375px 0px;
}
.joypixels-24-flags._1f1ff-1f1e6 {
    background-position: -6400px 0px;
}
.joypixels-24-flags._1f1ff-1f1f2 {
    background-position: -6425px 0px;
}
.joypixels-24-flags._1f1ff-1f1fc {
    background-position: -6450px 0px;
}
.joypixels-24-flags._1f38c {
    background-position: -6475px 0px;
}
.joypixels-24-flags._1f3c1 {
    background-position: -6500px 0px;
}
.joypixels-24-flags._1f3f3-1f308 {
    background-position: -6525px 0px;
}
.joypixels-24-flags._1f3f3-26a7 {
    background-position: -6550px 0px;
}
.joypixels-24-flags._1f3f3 {
    background-position: -6575px 0px;
}
.joypixels-24-flags._1f3f4-2620 {
    background-position: -6600px 0px;
}
.joypixels-24-flags._1f3f4-e0067-e0062-e0065-e006e-e0067-e007f {
    background-position: -6625px 0px;
}
.joypixels-24-flags._1f3f4-e0067-e0062-e0073-e0063-e0074-e007f {
    background-position: -6650px 0px;
}
.joypixels-24-flags._1f3f4-e0067-e0062-e0077-e006c-e0073-e007f {
    background-position: -6675px 0px;
}
.joypixels-24-flags._1f3f4 {
    background-position: -6700px 0px;
}
.joypixels-24-flags._1f6a9 {
    background-position: -6725px 0px;
}

.joypixels-24-regional {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-regional.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-regional {
        background-image: url(joypixels-sprite-24-regional@2x.png);
        background-size: 649px 24px;
    }
}


.joypixels-24-regional._1f1e6 {
    background-position: 0px 0px;
}
.joypixels-24-regional._1f1e7 {
    background-position: -25px 0px;
}
.joypixels-24-regional._1f1e8 {
    background-position: -50px 0px;
}
.joypixels-24-regional._1f1e9 {
    background-position: -75px 0px;
}
.joypixels-24-regional._1f1ea {
    background-position: -100px 0px;
}
.joypixels-24-regional._1f1eb {
    background-position: -125px 0px;
}
.joypixels-24-regional._1f1ec {
    background-position: -150px 0px;
}
.joypixels-24-regional._1f1ed {
    background-position: -175px 0px;
}
.joypixels-24-regional._1f1ee {
    background-position: -200px 0px;
}
.joypixels-24-regional._1f1ef {
    background-position: -225px 0px;
}
.joypixels-24-regional._1f1f0 {
    background-position: -250px 0px;
}
.joypixels-24-regional._1f1f1 {
    background-position: -275px 0px;
}
.joypixels-24-regional._1f1f2 {
    background-position: -300px 0px;
}
.joypixels-24-regional._1f1f3 {
    background-position: -325px 0px;
}
.joypixels-24-regional._1f1f4 {
    background-position: -350px 0px;
}
.joypixels-24-regional._1f1f5 {
    background-position: -375px 0px;
}
.joypixels-24-regional._1f1f6 {
    background-position: -400px 0px;
}
.joypixels-24-regional._1f1f7 {
    background-position: -425px 0px;
}
.joypixels-24-regional._1f1f8 {
    background-position: -450px 0px;
}
.joypixels-24-regional._1f1f9 {
    background-position: -475px 0px;
}
.joypixels-24-regional._1f1fa {
    background-position: -500px 0px;
}
.joypixels-24-regional._1f1fb {
    background-position: -525px 0px;
}
.joypixels-24-regional._1f1fc {
    background-position: -550px 0px;
}
.joypixels-24-regional._1f1fd {
    background-position: -575px 0px;
}
.joypixels-24-regional._1f1fe {
    background-position: -600px 0px;
}
.joypixels-24-regional._1f1ff {
    background-position: -625px 0px;
}

.joypixels-24-diversity {
    text-indent: -9999em;
    image-rendering: optimizeQuality;
    font-size: inherit;
    height: 24px;
    width: 24px;
    top: -3px;
    position: relative;
    display: inline-block;
    margin: 0 .15em;
    line-height: normal;
    vertical-align: middle;
    background-image: url(joypixels-sprite-24-diversity.png);
    background-repeat: no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)
{
    .joypixels-24-diversity {
        background-image: url(joypixels-sprite-24-diversity@2x.png);
        background-size: 1074px 1049px;
    }
}


.joypixels-24-diversity._00000 {
    background-position: 0px 0px;
}
.joypixels-24-diversity._1f385-1f3fb {
    background-position: -25px 0px;
}
.joypixels-24-diversity._1f385-1f3fc {
    background-position: 0px -25px;
}
.joypixels-24-diversity._1f385-1f3fd {
    background-position: -25px -25px;
}
.joypixels-24-diversity._1f385-1f3fe {
    background-position: -50px 0px;
}
.joypixels-24-diversity._1f385-1f3ff {
    background-position: -50px -25px;
}
.joypixels-24-diversity._1f3c2-1f3fb {
    background-position: 0px -50px;
}
.joypixels-24-diversity._1f3c2-1f3fc {
    background-position: -25px -50px;
}
.joypixels-24-diversity._1f3c2-1f3fd {
    background-position: -50px -50px;
}
.joypixels-24-diversity._1f3c2-1f3fe {
    background-position: -75px 0px;
}
.joypixels-24-diversity._1f3c2-1f3ff {
    background-position: -75px -25px;
}
.joypixels-24-diversity._1f3c3-1f3fb-2640 {
    background-position: -75px -50px;
}
.joypixels-24-diversity._1f3c3-1f3fb-2642 {
    background-position: 0px -75px;
}
.joypixels-24-diversity._1f3c3-1f3fb {
    background-position: -25px -75px;
}
.joypixels-24-diversity._1f3c3-1f3fc-2640 {
    background-position: -50px -75px;
}
.joypixels-24-diversity._1f3c3-1f3fc-2642 {
    background-position: -75px -75px;
}
.joypixels-24-diversity._1f3c3-1f3fc {
    background-position: -100px 0px;
}
.joypixels-24-diversity._1f3c3-1f3fd-2640 {
    background-position: -100px -25px;
}
.joypixels-24-diversity._1f3c3-1f3fd-2642 {
    background-position: -100px -50px;
}
.joypixels-24-diversity._1f3c3-1f3fd {
    background-position: -100px -75px;
}
.joypixels-24-diversity._1f3c3-1f3fe-2640 {
    background-position: 0px -100px;
}
.joypixels-24-diversity._1f3c3-1f3fe-2642 {
    background-position: -25px -100px;
}
.joypixels-24-diversity._1f3c3-1f3fe {
    background-position: -50px -100px;
}
.joypixels-24-diversity._1f3c3-1f3ff-2640 {
    background-position: -75px -100px;
}
.joypixels-24-diversity._1f3c3-1f3ff-2642 {
    background-position: -100px -100px;
}
.joypixels-24-diversity._1f3c3-1f3ff {
    background-position: -125px 0px;
}
.joypixels-24-diversity._1f3c4-1f3fb-2640 {
    background-position: -125px -25px;
}
.joypixels-24-diversity._1f3c4-1f3fb-2642 {
    background-position: -125px -50px;
}
.joypixels-24-diversity._1f3c4-1f3fb {
    background-position: -125px -75px;
}
.joypixels-24-diversity._1f3c4-1f3fc-2640 {
    background-position: -125px -100px;
}
.joypixels-24-diversity._1f3c4-1f3fc-2642 {
    background-position: 0px -125px;
}
.joypixels-24-diversity._1f3c4-1f3fc {
    background-position: -25px -125px;
}
.joypixels-24-diversity._1f3c4-1f3fd-2640 {
    background-position: -50px -125px;
}
.joypixels-24-diversity._1f3c4-1f3fd-2642 {
    background-position: -75px -125px;
}
.joypixels-24-diversity._1f3c4-1f3fd {
    background-position: -100px -125px;
}
.joypixels-24-diversity._1f3c4-1f3fe-2640 {
    background-position: -125px -125px;
}
.joypixels-24-diversity._1f3c4-1f3fe-2642 {
    background-position: -150px 0px;
}
.joypixels-24-diversity._1f3c4-1f3fe {
    background-position: -150px -25px;
}
.joypixels-24-diversity._1f3c4-1f3ff-2640 {
    background-position: -150px -50px;
}
.joypixels-24-diversity._1f3c4-1f3ff-2642 {
    background-position: -150px -75px;
}
.joypixels-24-diversity._1f3c4-1f3ff {
    background-position: -150px -100px;
}
.joypixels-24-diversity._1f3c7-1f3fb {
    background-position: -150px -125px;
}
.joypixels-24-diversity._1f3c7-1f3fc {
    background-position: 0px -150px;
}
.joypixels-24-diversity._1f3c7-1f3fd {
    background-position: -25px -150px;
}
.joypixels-24-diversity._1f3c7-1f3fe {
    background-position: -50px -150px;
}
.joypixels-24-diversity._1f3c7-1f3ff {
    background-position: -75px -150px;
}
.joypixels-24-diversity._1f3ca-1f3fb-2640 {
    background-position: -100px -150px;
}
.joypixels-24-diversity._1f3ca-1f3fb-2642 {
    background-position: -125px -150px;
}
.joypixels-24-diversity._1f3ca-1f3fb {
    background-position: -150px -150px;
}
.joypixels-24-diversity._1f3ca-1f3fc-2640 {
    background-position: -175px 0px;
}
.joypixels-24-diversity._1f3ca-1f3fc-2642 {
    background-position: -175px -25px;
}
.joypixels-24-diversity._1f3ca-1f3fc {
    background-position: -175px -50px;
}
.joypixels-24-diversity._1f3ca-1f3fd-2640 {
    background-position: -175px -75px;
}
.joypixels-24-diversity._1f3ca-1f3fd-2642 {
    background-position: -175px -100px;
}
.joypixels-24-diversity._1f3ca-1f3fd {
    background-position: -175px -125px;
}
.joypixels-24-diversity._1f3ca-1f3fe-2640 {
    background-position: -175px -150px;
}
.joypixels-24-diversity._1f3ca-1f3fe-2642 {
    background-position: 0px -175px;
}
.joypixels-24-diversity._1f3ca-1f3fe {
    background-position: -25px -175px;
}
.joypixels-24-diversity._1f3ca-1f3ff-2640 {
    background-position: -50px -175px;
}
.joypixels-24-diversity._1f3ca-1f3ff-2642 {
    background-position: -75px -175px;
}
.joypixels-24-diversity._1f3ca-1f3ff {
    background-position: -100px -175px;
}
.joypixels-24-diversity._1f3cb-1f3fb-2640 {
    background-position: -125px -175px;
}
.joypixels-24-diversity._1f3cb-1f3fb-2642 {
    background-position: -150px -175px;
}
.joypixels-24-diversity._1f3cb-1f3fb {
    background-position: -175px -175px;
}
.joypixels-24-diversity._1f3cb-1f3fc-2640 {
    background-position: -200px 0px;
}
.joypixels-24-diversity._1f3cb-1f3fc-2642 {
    background-position: -200px -25px;
}
.joypixels-24-diversity._1f3cb-1f3fc {
    background-position: -200px -50px;
}
.joypixels-24-diversity._1f3cb-1f3fd-2640 {
    background-position: -200px -75px;
}
.joypixels-24-diversity._1f3cb-1f3fd-2642 {
    background-position: -200px -100px;
}
.joypixels-24-diversity._1f3cb-1f3fd {
    background-position: -200px -125px;
}
.joypixels-24-diversity._1f3cb-1f3fe-2640 {
    background-position: -200px -150px;
}
.joypixels-24-diversity._1f3cb-1f3fe-2642 {
    background-position: -200px -175px;
}
.joypixels-24-diversity._1f3cb-1f3fe {
    background-position: 0px -200px;
}
.joypixels-24-diversity._1f3cb-1f3ff-2640 {
    background-position: -25px -200px;
}
.joypixels-24-diversity._1f3cb-1f3ff-2642 {
    background-position: -50px -200px;
}
.joypixels-24-diversity._1f3cb-1f3ff {
    background-position: -75px -200px;
}
.joypixels-24-diversity._1f3cc-1f3fb-2640 {
    background-position: -100px -200px;
}
.joypixels-24-diversity._1f3cc-1f3fb-2642 {
    background-position: -125px -200px;
}
.joypixels-24-diversity._1f3cc-1f3fb {
    background-position: -150px -200px;
}
.joypixels-24-diversity._1f3cc-1f3fc-2640 {
    background-position: -175px -200px;
}
.joypixels-24-diversity._1f3cc-1f3fc-2642 {
    background-position: -200px -200px;
}
.joypixels-24-diversity._1f3cc-1f3fc {
    background-position: -225px 0px;
}
.joypixels-24-diversity._1f3cc-1f3fd-2640 {
    background-position: -225px -25px;
}
.joypixels-24-diversity._1f3cc-1f3fd-2642 {
    background-position: -225px -50px;
}
.joypixels-24-diversity._1f3cc-1f3fd {
    background-position: -225px -75px;
}
.joypixels-24-diversity._1f3cc-1f3fe-2640 {
    background-position: -225px -100px;
}
.joypixels-24-diversity._1f3cc-1f3fe-2642 {
    background-position: -225px -125px;
}
.joypixels-24-diversity._1f3cc-1f3fe {
    background-position: -225px -150px;
}
.joypixels-24-diversity._1f3cc-1f3ff-2640 {
    background-position: -225px -175px;
}
.joypixels-24-diversity._1f3cc-1f3ff-2642 {
    background-position: -225px -200px;
}
.joypixels-24-diversity._1f3cc-1f3ff {
    background-position: 0px -225px;
}
.joypixels-24-diversity._1f3fb {
    background-position: -25px -225px;
}
.joypixels-24-diversity._1f3fc {
    background-position: -50px -225px;
}
.joypixels-24-diversity._1f3fd {
    background-position: -75px -225px;
}
.joypixels-24-diversity._1f3fe {
    background-position: -100px -225px;
}
.joypixels-24-diversity._1f3ff {
    background-position: -125px -225px;
}
.joypixels-24-diversity._1f442-1f3fb {
    background-position: -150px -225px;
}
.joypixels-24-diversity._1f442-1f3fc {
    background-position: -175px -225px;
}
.joypixels-24-diversity._1f442-1f3fd {
    background-position: -200px -225px;
}
.joypixels-24-diversity._1f442-1f3fe {
    background-position: -225px -225px;
}
.joypixels-24-diversity._1f442-1f3ff {
    background-position: -250px 0px;
}
.joypixels-24-diversity._1f443-1f3fb {
    background-position: -250px -25px;
}
.joypixels-24-diversity._1f443-1f3fc {
    background-position: -250px -50px;
}
.joypixels-24-diversity._1f443-1f3fd {
    background-position: -250px -75px;
}
.joypixels-24-diversity._1f443-1f3fe {
    background-position: -250px -100px;
}
.joypixels-24-diversity._1f443-1f3ff {
    background-position: -250px -125px;
}
.joypixels-24-diversity._1f446-1f3fb {
    background-position: -250px -150px;
}
.joypixels-24-diversity._1f446-1f3fc {
    background-position: -250px -175px;
}
.joypixels-24-diversity._1f446-1f3fd {
    background-position: -250px -200px;
}
.joypixels-24-diversity._1f446-1f3fe {
    background-position: -250px -225px;
}
.joypixels-24-diversity._1f446-1f3ff {
    background-position: 0px -250px;
}
.joypixels-24-diversity._1f447-1f3fb {
    background-position: -25px -250px;
}
.joypixels-24-diversity._1f447-1f3fc {
    background-position: -50px -250px;
}
.joypixels-24-diversity._1f447-1f3fd {
    background-position: -75px -250px;
}
.joypixels-24-diversity._1f447-1f3fe {
    background-position: -100px -250px;
}
.joypixels-24-diversity._1f447-1f3ff {
    background-position: -125px -250px;
}
.joypixels-24-diversity._1f448-1f3fb {
    background-position: -150px -250px;
}
.joypixels-24-diversity._1f448-1f3fc {
    background-position: -175px -250px;
}
.joypixels-24-diversity._1f448-1f3fd {
    background-position: -200px -250px;
}
.joypixels-24-diversity._1f448-1f3fe {
    background-position: -225px -250px;
}
.joypixels-24-diversity._1f448-1f3ff {
    background-position: -250px -250px;
}
.joypixels-24-diversity._1f449-1f3fb {
    background-position: -275px 0px;
}
.joypixels-24-diversity._1f449-1f3fc {
    background-position: -275px -25px;
}
.joypixels-24-diversity._1f449-1f3fd {
    background-position: -275px -50px;
}
.joypixels-24-diversity._1f449-1f3fe {
    background-position: -275px -75px;
}
.joypixels-24-diversity._1f449-1f3ff {
    background-position: -275px -100px;
}
.joypixels-24-diversity._1f44a-1f3fb {
    background-position: -275px -125px;
}
.joypixels-24-diversity._1f44a-1f3fc {
    background-position: -275px -150px;
}
.joypixels-24-diversity._1f44a-1f3fd {
    background-position: -275px -175px;
}
.joypixels-24-diversity._1f44a-1f3fe {
    background-position: -275px -200px;
}
.joypixels-24-diversity._1f44a-1f3ff {
    background-position: -275px -225px;
}
.joypixels-24-diversity._1f44b-1f3fb {
    background-position: -275px -250px;
}
.joypixels-24-diversity._1f44b-1f3fc {
    background-position: 0px -275px;
}
.joypixels-24-diversity._1f44b-1f3fd {
    background-position: -25px -275px;
}
.joypixels-24-diversity._1f44b-1f3fe {
    background-position: -50px -275px;
}
.joypixels-24-diversity._1f44b-1f3ff {
    background-position: -75px -275px;
}
.joypixels-24-diversity._1f44c-1f3fb {
    background-position: -100px -275px;
}
.joypixels-24-diversity._1f44c-1f3fc {
    background-position: -125px -275px;
}
.joypixels-24-diversity._1f44c-1f3fd {
    background-position: -150px -275px;
}
.joypixels-24-diversity._1f44c-1f3fe {
    background-position: -175px -275px;
}
.joypixels-24-diversity._1f44c-1f3ff {
    background-position: -200px -275px;
}
.joypixels-24-diversity._1f44d-1f3fb {
    background-position: -225px -275px;
}
.joypixels-24-diversity._1f44d-1f3fc {
    background-position: -250px -275px;
}
.joypixels-24-diversity._1f44d-1f3fd {
    background-position: -275px -275px;
}
.joypixels-24-diversity._1f44d-1f3fe {
    background-position: -300px 0px;
}
.joypixels-24-diversity._1f44d-1f3ff {
    background-position: -300px -25px;
}
.joypixels-24-diversity._1f44e-1f3fb {
    background-position: -300px -50px;
}
.joypixels-24-diversity._1f44e-1f3fc {
    background-position: -300px -75px;
}
.joypixels-24-diversity._1f44e-1f3fd {
    background-position: -300px -100px;
}
.joypixels-24-diversity._1f44e-1f3fe {
    background-position: -300px -125px;
}
.joypixels-24-diversity._1f44e-1f3ff {
    background-position: -300px -150px;
}
.joypixels-24-diversity._1f44f-1f3fb {
    background-position: -300px -175px;
}
.joypixels-24-diversity._1f44f-1f3fc {
    background-position: -300px -200px;
}
.joypixels-24-diversity._1f44f-1f3fd {
    background-position: -300px -225px;
}
.joypixels-24-diversity._1f44f-1f3fe {
    background-position: -300px -250px;
}
.joypixels-24-diversity._1f44f-1f3ff {
    background-position: -300px -275px;
}
.joypixels-24-diversity._1f450-1f3fb {
    background-position: 0px -300px;
}
.joypixels-24-diversity._1f450-1f3fc {
    background-position: -25px -300px;
}
.joypixels-24-diversity._1f450-1f3fd {
    background-position: -50px -300px;
}
.joypixels-24-diversity._1f450-1f3fe {
    background-position: -75px -300px;
}
.joypixels-24-diversity._1f450-1f3ff {
    background-position: -100px -300px;
}
.joypixels-24-diversity._1f466-1f3fb {
    background-position: -125px -300px;
}
.joypixels-24-diversity._1f466-1f3fc {
    background-position: -150px -300px;
}
.joypixels-24-diversity._1f466-1f3fd {
    background-position: -175px -300px;
}
.joypixels-24-diversity._1f466-1f3fe {
    background-position: -200px -300px;
}
.joypixels-24-diversity._1f466-1f3ff {
    background-position: -225px -300px;
}
.joypixels-24-diversity._1f467-1f3fb {
    background-position: -250px -300px;
}
.joypixels-24-diversity._1f467-1f3fc {
    background-position: -275px -300px;
}
.joypixels-24-diversity._1f467-1f3fd {
    background-position: -300px -300px;
}
.joypixels-24-diversity._1f467-1f3fe {
    background-position: -325px 0px;
}
.joypixels-24-diversity._1f467-1f3ff {
    background-position: -325px -25px;
}
.joypixels-24-diversity._1f468-1f3fb-1f33e {
    background-position: -325px -50px;
}
.joypixels-24-diversity._1f468-1f3fb-1f373 {
    background-position: -325px -75px;
}
.joypixels-24-diversity._1f468-1f3fb-1f37c {
    background-position: -325px -100px;
}
.joypixels-24-diversity._1f468-1f3fb-1f393 {
    background-position: -325px -125px;
}
.joypixels-24-diversity._1f468-1f3fb-1f3a4 {
    background-position: -325px -150px;
}
.joypixels-24-diversity._1f468-1f3fb-1f3a8 {
    background-position: -325px -175px;
}
.joypixels-24-diversity._1f468-1f3fb-1f3eb {
    background-position: -325px -200px;
}
.joypixels-24-diversity._1f468-1f3fb-1f3ed {
    background-position: -325px -225px;
}
.joypixels-24-diversity._1f468-1f3fb-1f4bb {
    background-position: -325px -250px;
}
.joypixels-24-diversity._1f468-1f3fb-1f4bc {
    background-position: -325px -275px;
}
.joypixels-24-diversity._1f468-1f3fb-1f527 {
    background-position: -325px -300px;
}
.joypixels-24-diversity._1f468-1f3fb-1f52c {
    background-position: 0px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f680 {
    background-position: -25px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f692 {
    background-position: -50px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f91d-1f468-1f3fc {
    background-position: -75px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f91d-1f468-1f3fd {
    background-position: -100px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f91d-1f468-1f3fe {
    background-position: -125px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f91d-1f468-1f3ff {
    background-position: -150px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9af {
    background-position: -175px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9b0 {
    background-position: -200px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9b1 {
    background-position: -225px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9b2 {
    background-position: -250px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9b3 {
    background-position: -275px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9bc {
    background-position: -300px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-1f9bd {
    background-position: -325px -325px;
}
.joypixels-24-diversity._1f468-1f3fb-2695 {
    background-position: -350px 0px;
}
.joypixels-24-diversity._1f468-1f3fb-2696 {
    background-position: -350px -25px;
}
.joypixels-24-diversity._1f468-1f3fb-2708 {
    background-position: -350px -50px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f468-1f3fb {
    background-position: -350px -75px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f468-1f3fc {
    background-position: -350px -100px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f468-1f3fd {
    background-position: -350px -125px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f468-1f3fe {
    background-position: -350px -150px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f468-1f3ff {
    background-position: -350px -175px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f48b-1f468-1f3fb {
    background-position: -350px -200px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f48b-1f468-1f3fc {
    background-position: -350px -225px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f48b-1f468-1f3fd {
    background-position: -350px -250px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f48b-1f468-1f3fe {
    background-position: -350px -275px;
}
.joypixels-24-diversity._1f468-1f3fb-2764-1f48b-1f468-1f3ff {
    background-position: -350px -300px;
}
.joypixels-24-diversity._1f468-1f3fb {
    background-position: -350px -325px;
}
.joypixels-24-diversity._1f468-1f3fc-1f33e {
    background-position: 0px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f373 {
    background-position: -25px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f37c {
    background-position: -50px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f393 {
    background-position: -75px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f3a4 {
    background-position: -100px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f3a8 {
    background-position: -125px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f3eb {
    background-position: -150px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f3ed {
    background-position: -175px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f4bb {
    background-position: -200px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f4bc {
    background-position: -225px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f527 {
    background-position: -250px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f52c {
    background-position: -275px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f680 {
    background-position: -300px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f692 {
    background-position: -325px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f91d-1f468-1f3fb {
    background-position: -350px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-1f91d-1f468-1f3fd {
    background-position: -375px 0px;
}
.joypixels-24-diversity._1f468-1f3fc-1f91d-1f468-1f3fe {
    background-position: -375px -25px;
}
.joypixels-24-diversity._1f468-1f3fc-1f91d-1f468-1f3ff {
    background-position: -375px -50px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9af {
    background-position: -375px -75px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9b0 {
    background-position: -375px -100px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9b1 {
    background-position: -375px -125px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9b2 {
    background-position: -375px -150px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9b3 {
    background-position: -375px -175px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9bc {
    background-position: -375px -200px;
}
.joypixels-24-diversity._1f468-1f3fc-1f9bd {
    background-position: -375px -225px;
}
.joypixels-24-diversity._1f468-1f3fc-2695 {
    background-position: -375px -250px;
}
.joypixels-24-diversity._1f468-1f3fc-2696 {
    background-position: -375px -275px;
}
.joypixels-24-diversity._1f468-1f3fc-2708 {
    background-position: -375px -300px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f468-1f3fb {
    background-position: -375px -325px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f468-1f3fc {
    background-position: -375px -350px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f468-1f3fd {
    background-position: 0px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f468-1f3fe {
    background-position: -25px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f468-1f3ff {
    background-position: -50px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f48b-1f468-1f3fb {
    background-position: -75px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f48b-1f468-1f3fc {
    background-position: -100px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f48b-1f468-1f3fd {
    background-position: -125px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f48b-1f468-1f3fe {
    background-position: -150px -375px;
}
.joypixels-24-diversity._1f468-1f3fc-2764-1f48b-1f468-1f3ff {
    background-position: -175px -375px;
}
.joypixels-24-diversity._1f468-1f3fc {
    background-position: -200px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f33e {
    background-position: -225px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f373 {
    background-position: -250px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f37c {
    background-position: -275px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f393 {
    background-position: -300px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f3a4 {
    background-position: -325px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f3a8 {
    background-position: -350px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f3eb {
    background-position: -375px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f3ed {
    background-position: -400px 0px;
}
.joypixels-24-diversity._1f468-1f3fd-1f4bb {
    background-position: -400px -25px;
}
.joypixels-24-diversity._1f468-1f3fd-1f4bc {
    background-position: -400px -50px;
}
.joypixels-24-diversity._1f468-1f3fd-1f527 {
    background-position: -400px -75px;
}
.joypixels-24-diversity._1f468-1f3fd-1f52c {
    background-position: -400px -100px;
}
.joypixels-24-diversity._1f468-1f3fd-1f680 {
    background-position: -400px -125px;
}
.joypixels-24-diversity._1f468-1f3fd-1f692 {
    background-position: -400px -150px;
}
.joypixels-24-diversity._1f468-1f3fd-1f91d-1f468-1f3fb {
    background-position: -400px -175px;
}
.joypixels-24-diversity._1f468-1f3fd-1f91d-1f468-1f3fc {
    background-position: -400px -200px;
}
.joypixels-24-diversity._1f468-1f3fd-1f91d-1f468-1f3fe {
    background-position: -400px -225px;
}
.joypixels-24-diversity._1f468-1f3fd-1f91d-1f468-1f3ff {
    background-position: -400px -250px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9af {
    background-position: -400px -275px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9b0 {
    background-position: -400px -300px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9b1 {
    background-position: -400px -325px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9b2 {
    background-position: -400px -350px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9b3 {
    background-position: -400px -375px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9bc {
    background-position: 0px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-1f9bd {
    background-position: -25px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2695 {
    background-position: -50px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2696 {
    background-position: -75px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2708 {
    background-position: -100px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f468-1f3fb {
    background-position: -125px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f468-1f3fc {
    background-position: -150px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f468-1f3fd {
    background-position: -175px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f468-1f3fe {
    background-position: -200px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f468-1f3ff {
    background-position: -225px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f48b-1f468-1f3fb {
    background-position: -250px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f48b-1f468-1f3fc {
    background-position: -275px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f48b-1f468-1f3fd {
    background-position: -300px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f48b-1f468-1f3fe {
    background-position: -325px -400px;
}
.joypixels-24-diversity._1f468-1f3fd-2764-1f48b-1f468-1f3ff {
    background-position: -350px -400px;
}
.joypixels-24-diversity._1f468-1f3fd {
    background-position: -375px -400px;
}
.joypixels-24-diversity._1f468-1f3fe-1f33e {
    background-position: -400px -400px;
}
.joypixels-24-diversity._1f468-1f3fe-1f373 {
    background-position: -425px 0px;
}
.joypixels-24-diversity._1f468-1f3fe-1f37c {
    background-position: -425px -25px;
}
.joypixels-24-diversity._1f468-1f3fe-1f393 {
    background-position: -425px -50px;
}
.joypixels-24-diversity._1f468-1f3fe-1f3a4 {
    background-position: -425px -75px;
}
.joypixels-24-diversity._1f468-1f3fe-1f3a8 {
    background-position: -425px -100px;
}
.joypixels-24-diversity._1f468-1f3fe-1f3eb {
    background-position: -425px -125px;
}
.joypixels-24-diversity._1f468-1f3fe-1f3ed {
    background-position: -425px -150px;
}
.joypixels-24-diversity._1f468-1f3fe-1f4bb {
    background-position: -425px -175px;
}
.joypixels-24-diversity._1f468-1f3fe-1f4bc {
    background-position: -425px -200px;
}
.joypixels-24-diversity._1f468-1f3fe-1f527 {
    background-position: -425px -225px;
}
.joypixels-24-diversity._1f468-1f3fe-1f52c {
    background-position: -425px -250px;
}
.joypixels-24-diversity._1f468-1f3fe-1f680 {
    background-position: -425px -275px;
}
.joypixels-24-diversity._1f468-1f3fe-1f692 {
    background-position: -425px -300px;
}
.joypixels-24-diversity._1f468-1f3fe-1f91d-1f468-1f3fb {
    background-position: -425px -325px;
}
.joypixels-24-diversity._1f468-1f3fe-1f91d-1f468-1f3fc {
    background-position: -425px -350px;
}
.joypixels-24-diversity._1f468-1f3fe-1f91d-1f468-1f3fd {
    background-position: -425px -375px;
}
.joypixels-24-diversity._1f468-1f3fe-1f91d-1f468-1f3ff {
    background-position: -425px -400px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9af {
    background-position: 0px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9b0 {
    background-position: -25px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9b1 {
    background-position: -50px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9b2 {
    background-position: -75px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9b3 {
    background-position: -100px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9bc {
    background-position: -125px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-1f9bd {
    background-position: -150px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2695 {
    background-position: -175px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2696 {
    background-position: -200px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2708 {
    background-position: -225px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f468-1f3fb {
    background-position: -250px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f468-1f3fc {
    background-position: -275px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f468-1f3fd {
    background-position: -300px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f468-1f3fe {
    background-position: -325px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f468-1f3ff {
    background-position: -350px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f48b-1f468-1f3fb {
    background-position: -375px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f48b-1f468-1f3fc {
    background-position: -400px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f48b-1f468-1f3fd {
    background-position: -425px -425px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f48b-1f468-1f3fe {
    background-position: -450px 0px;
}
.joypixels-24-diversity._1f468-1f3fe-2764-1f48b-1f468-1f3ff {
    background-position: -450px -25px;
}
.joypixels-24-diversity._1f468-1f3fe {
    background-position: -450px -50px;
}
.joypixels-24-diversity._1f468-1f3ff-1f33e {
    background-position: -450px -75px;
}
.joypixels-24-diversity._1f468-1f3ff-1f373 {
    background-position: -450px -100px;
}
.joypixels-24-diversity._1f468-1f3ff-1f37c {
    background-position: -450px -125px;
}
.joypixels-24-diversity._1f468-1f3ff-1f393 {
    background-position: -450px -150px;
}
.joypixels-24-diversity._1f468-1f3ff-1f3a4 {
    background-position: -450px -175px;
}
.joypixels-24-diversity._1f468-1f3ff-1f3a8 {
    background-position: -450px -200px;
}
.joypixels-24-diversity._1f468-1f3ff-1f3eb {
    background-position: -450px -225px;
}
.joypixels-24-diversity._1f468-1f3ff-1f3ed {
    background-position: -450px -250px;
}
.joypixels-24-diversity._1f468-1f3ff-1f4bb {
    background-position: -450px -275px;
}
.joypixels-24-diversity._1f468-1f3ff-1f4bc {
    background-position: -450px -300px;
}
.joypixels-24-diversity._1f468-1f3ff-1f527 {
    background-position: -450px -325px;
}
.joypixels-24-diversity._1f468-1f3ff-1f52c {
    background-position: -450px -350px;
}
.joypixels-24-diversity._1f468-1f3ff-1f680 {
    background-position: -450px -375px;
}
.joypixels-24-diversity._1f468-1f3ff-1f692 {
    background-position: -450px -400px;
}
.joypixels-24-diversity._1f468-1f3ff-1f91d-1f468-1f3fb {
    background-position: -450px -425px;
}
.joypixels-24-diversity._1f468-1f3ff-1f91d-1f468-1f3fc {
    background-position: 0px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f91d-1f468-1f3fd {
    background-position: -25px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f91d-1f468-1f3fe {
    background-position: -50px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9af {
    background-position: -75px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9b0 {
    background-position: -100px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9b1 {
    background-position: -125px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9b2 {
    background-position: -150px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9b3 {
    background-position: -175px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9bc {
    background-position: -200px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-1f9bd {
    background-position: -225px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2695 {
    background-position: -250px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2696 {
    background-position: -275px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2708 {
    background-position: -300px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f468-1f3fb {
    background-position: -325px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f468-1f3fc {
    background-position: -350px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f468-1f3fd {
    background-position: -375px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f468-1f3fe {
    background-position: -400px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f468-1f3ff {
    background-position: -425px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f48b-1f468-1f3fb {
    background-position: -450px -450px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f48b-1f468-1f3fc {
    background-position: -475px 0px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f48b-1f468-1f3fd {
    background-position: -475px -25px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f48b-1f468-1f3fe {
    background-position: -475px -50px;
}
.joypixels-24-diversity._1f468-1f3ff-2764-1f48b-1f468-1f3ff {
    background-position: -475px -75px;
}
.joypixels-24-diversity._1f468-1f3ff {
    background-position: -475px -100px;
}
.joypixels-24-diversity._1f469-1f3fb-1f33e {
    background-position: -475px -125px;
}
.joypixels-24-diversity._1f469-1f3fb-1f373 {
    background-position: -475px -150px;
}
.joypixels-24-diversity._1f469-1f3fb-1f37c {
    background-position: -475px -175px;
}
.joypixels-24-diversity._1f469-1f3fb-1f393 {
    background-position: -475px -200px;
}
.joypixels-24-diversity._1f469-1f3fb-1f3a4 {
    background-position: -475px -225px;
}
.joypixels-24-diversity._1f469-1f3fb-1f3a8 {
    background-position: -475px -250px;
}
.joypixels-24-diversity._1f469-1f3fb-1f3eb {
    background-position: -475px -275px;
}
.joypixels-24-diversity._1f469-1f3fb-1f3ed {
    background-position: -475px -300px;
}
.joypixels-24-diversity._1f469-1f3fb-1f4bb {
    background-position: -475px -325px;
}
.joypixels-24-diversity._1f469-1f3fb-1f4bc {
    background-position: -475px -350px;
}
.joypixels-24-diversity._1f469-1f3fb-1f527 {
    background-position: -475px -375px;
}
.joypixels-24-diversity._1f469-1f3fb-1f52c {
    background-position: -475px -400px;
}
.joypixels-24-diversity._1f469-1f3fb-1f680 {
    background-position: -475px -425px;
}
.joypixels-24-diversity._1f469-1f3fb-1f692 {
    background-position: -475px -450px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f468-1f3fc {
    background-position: 0px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f468-1f3fd {
    background-position: -25px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f468-1f3fe {
    background-position: -50px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f468-1f3ff {
    background-position: -75px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f469-1f3fc {
    background-position: -100px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f469-1f3fd {
    background-position: -125px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f469-1f3fe {
    background-position: -150px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f91d-1f469-1f3ff {
    background-position: -175px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9af {
    background-position: -200px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9b0 {
    background-position: -225px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9b1 {
    background-position: -250px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9b2 {
    background-position: -275px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9b3 {
    background-position: -300px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9bc {
    background-position: -325px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-1f9bd {
    background-position: -350px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-2695 {
    background-position: -375px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-2696 {
    background-position: -400px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-2708 {
    background-position: -425px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f468-1f3fb {
    background-position: -450px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f468-1f3fc {
    background-position: -475px -475px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f468-1f3fd {
    background-position: -500px 0px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f468-1f3fe {
    background-position: -500px -25px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f468-1f3ff {
    background-position: -500px -50px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f469-1f3fb {
    background-position: -500px -75px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f469-1f3fc {
    background-position: -500px -100px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f469-1f3fd {
    background-position: -500px -125px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f469-1f3fe {
    background-position: -500px -150px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f469-1f3ff {
    background-position: -500px -175px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f468-1f3fb {
    background-position: -500px -200px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f468-1f3fc {
    background-position: -500px -225px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f468-1f3fd {
    background-position: -500px -250px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f468-1f3fe {
    background-position: -500px -275px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f468-1f3ff {
    background-position: -500px -300px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f469-1f3fb {
    background-position: -500px -325px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f469-1f3fc {
    background-position: -500px -350px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f469-1f3fd {
    background-position: -500px -375px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f469-1f3fe {
    background-position: -500px -400px;
}
.joypixels-24-diversity._1f469-1f3fb-2764-1f48b-1f469-1f3ff {
    background-position: -500px -425px;
}
.joypixels-24-diversity._1f469-1f3fb {
    background-position: -500px -450px;
}
.joypixels-24-diversity._1f469-1f3fc-1f33e {
    background-position: -500px -475px;
}
.joypixels-24-diversity._1f469-1f3fc-1f373 {
    background-position: 0px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f37c {
    background-position: -25px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f393 {
    background-position: -50px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f3a4 {
    background-position: -75px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f3a8 {
    background-position: -100px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f3eb {
    background-position: -125px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f3ed {
    background-position: -150px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f4bb {
    background-position: -175px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f4bc {
    background-position: -200px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f527 {
    background-position: -225px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f52c {
    background-position: -250px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f680 {
    background-position: -275px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f692 {
    background-position: -300px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f468-1f3fb {
    background-position: -325px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f468-1f3fd {
    background-position: -350px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f468-1f3fe {
    background-position: -375px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f468-1f3ff {
    background-position: -400px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f469-1f3fb {
    background-position: -425px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f469-1f3fd {
    background-position: -450px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f469-1f3fe {
    background-position: -475px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f91d-1f469-1f3ff {
    background-position: -500px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9af {
    background-position: -525px 0px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9b0 {
    background-position: -525px -25px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9b1 {
    background-position: -525px -50px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9b2 {
    background-position: -525px -75px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9b3 {
    background-position: -525px -100px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9bc {
    background-position: -525px -125px;
}
.joypixels-24-diversity._1f469-1f3fc-1f9bd {
    background-position: -525px -150px;
}
.joypixels-24-diversity._1f469-1f3fc-2695 {
    background-position: -525px -175px;
}
.joypixels-24-diversity._1f469-1f3fc-2696 {
    background-position: -525px -200px;
}
.joypixels-24-diversity._1f469-1f3fc-2708 {
    background-position: -525px -225px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f468-1f3fb {
    background-position: -525px -250px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f468-1f3fc {
    background-position: -525px -275px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f468-1f3fd {
    background-position: -525px -300px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f468-1f3fe {
    background-position: -525px -325px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f468-1f3ff {
    background-position: -525px -350px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f469-1f3fb {
    background-position: -525px -375px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f469-1f3fc {
    background-position: -525px -400px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f469-1f3fd {
    background-position: -525px -425px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f469-1f3fe {
    background-position: -525px -450px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f469-1f3ff {
    background-position: -525px -475px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f468-1f3fb {
    background-position: -525px -500px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f468-1f3fc {
    background-position: 0px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f468-1f3fd {
    background-position: -25px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f468-1f3fe {
    background-position: -50px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f468-1f3ff {
    background-position: -75px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f469-1f3fb {
    background-position: -100px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f469-1f3fc {
    background-position: -125px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f469-1f3fd {
    background-position: -150px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f469-1f3fe {
    background-position: -175px -525px;
}
.joypixels-24-diversity._1f469-1f3fc-2764-1f48b-1f469-1f3ff {
    background-position: -200px -525px;
}
.joypixels-24-diversity._1f469-1f3fc {
    background-position: -225px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f33e {
    background-position: -250px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f373 {
    background-position: -275px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f37c {
    background-position: -300px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f393 {
    background-position: -325px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f3a4 {
    background-position: -350px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f3a8 {
    background-position: -375px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f3eb {
    background-position: -400px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f3ed {
    background-position: -425px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f4bb {
    background-position: -450px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f4bc {
    background-position: -475px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f527 {
    background-position: -500px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f52c {
    background-position: -525px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-1f680 {
    background-position: -550px 0px;
}
.joypixels-24-diversity._1f469-1f3fd-1f692 {
    background-position: -550px -25px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f468-1f3fb {
    background-position: -550px -50px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f468-1f3fc {
    background-position: -550px -75px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f468-1f3fe {
    background-position: -550px -100px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f468-1f3ff {
    background-position: -550px -125px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f469-1f3fb {
    background-position: -550px -150px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f469-1f3fc {
    background-position: -550px -175px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f469-1f3fe {
    background-position: -550px -200px;
}
.joypixels-24-diversity._1f469-1f3fd-1f91d-1f469-1f3ff {
    background-position: -550px -225px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9af {
    background-position: -550px -250px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9b0 {
    background-position: -550px -275px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9b1 {
    background-position: -550px -300px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9b2 {
    background-position: -550px -325px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9b3 {
    background-position: -550px -350px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9bc {
    background-position: -550px -375px;
}
.joypixels-24-diversity._1f469-1f3fd-1f9bd {
    background-position: -550px -400px;
}
.joypixels-24-diversity._1f469-1f3fd-2695 {
    background-position: -550px -425px;
}
.joypixels-24-diversity._1f469-1f3fd-2696 {
    background-position: -550px -450px;
}
.joypixels-24-diversity._1f469-1f3fd-2708 {
    background-position: -550px -475px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f468-1f3fb {
    background-position: -550px -500px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f468-1f3fc {
    background-position: -550px -525px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f468-1f3fd {
    background-position: 0px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f468-1f3fe {
    background-position: -25px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f468-1f3ff {
    background-position: -50px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f469-1f3fb {
    background-position: -75px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f469-1f3fc {
    background-position: -100px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f469-1f3fd {
    background-position: -125px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f469-1f3fe {
    background-position: -150px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f469-1f3ff {
    background-position: -175px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f468-1f3fb {
    background-position: -200px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f468-1f3fc {
    background-position: -225px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f468-1f3fd {
    background-position: -250px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f468-1f3fe {
    background-position: -275px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f468-1f3ff {
    background-position: -300px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f469-1f3fb {
    background-position: -325px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f469-1f3fc {
    background-position: -350px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f469-1f3fd {
    background-position: -375px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f469-1f3fe {
    background-position: -400px -550px;
}
.joypixels-24-diversity._1f469-1f3fd-2764-1f48b-1f469-1f3ff {
    background-position: -425px -550px;
}
.joypixels-24-diversity._1f469-1f3fd {
    background-position: -450px -550px;
}
.joypixels-24-diversity._1f469-1f3fe-1f33e {
    background-position: -475px -550px;
}
.joypixels-24-diversity._1f469-1f3fe-1f373 {
    background-position: -500px -550px;
}
.joypixels-24-diversity._1f469-1f3fe-1f37c {
    background-position: -525px -550px;
}
.joypixels-24-diversity._1f469-1f3fe-1f393 {
    background-position: -550px -550px;
}
.joypixels-24-diversity._1f469-1f3fe-1f3a4 {
    background-position: -575px 0px;
}
.joypixels-24-diversity._1f469-1f3fe-1f3a8 {
    background-position: -575px -25px;
}
.joypixels-24-diversity._1f469-1f3fe-1f3eb {
    background-position: -575px -50px;
}
.joypixels-24-diversity._1f469-1f3fe-1f3ed {
    background-position: -575px -75px;
}
.joypixels-24-diversity._1f469-1f3fe-1f4bb {
    background-position: -575px -100px;
}
.joypixels-24-diversity._1f469-1f3fe-1f4bc {
    background-position: -575px -125px;
}
.joypixels-24-diversity._1f469-1f3fe-1f527 {
    background-position: -575px -150px;
}
.joypixels-24-diversity._1f469-1f3fe-1f52c {
    background-position: -575px -175px;
}
.joypixels-24-diversity._1f469-1f3fe-1f680 {
    background-position: -575px -200px;
}
.joypixels-24-diversity._1f469-1f3fe-1f692 {
    background-position: -575px -225px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f468-1f3fb {
    background-position: -575px -250px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f468-1f3fc {
    background-position: -575px -275px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f468-1f3fd {
    background-position: -575px -300px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f468-1f3ff {
    background-position: -575px -325px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f469-1f3fb {
    background-position: -575px -350px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f469-1f3fc {
    background-position: -575px -375px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f469-1f3fd {
    background-position: -575px -400px;
}
.joypixels-24-diversity._1f469-1f3fe-1f91d-1f469-1f3ff {
    background-position: -575px -425px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9af {
    background-position: -575px -450px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9b0 {
    background-position: -575px -475px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9b1 {
    background-position: -575px -500px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9b2 {
    background-position: -575px -525px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9b3 {
    background-position: -575px -550px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9bc {
    background-position: 0px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-1f9bd {
    background-position: -25px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2695 {
    background-position: -50px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2696 {
    background-position: -75px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2708 {
    background-position: -100px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f468-1f3fb {
    background-position: -125px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f468-1f3fc {
    background-position: -150px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f468-1f3fd {
    background-position: -175px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f468-1f3fe {
    background-position: -200px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f468-1f3ff {
    background-position: -225px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f469-1f3fb {
    background-position: -250px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f469-1f3fc {
    background-position: -275px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f469-1f3fd {
    background-position: -300px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f469-1f3fe {
    background-position: -325px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f469-1f3ff {
    background-position: -350px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f468-1f3fb {
    background-position: -375px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f468-1f3fc {
    background-position: -400px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f468-1f3fd {
    background-position: -425px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f468-1f3fe {
    background-position: -450px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f468-1f3ff {
    background-position: -475px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f469-1f3fb {
    background-position: -500px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f469-1f3fc {
    background-position: -525px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f469-1f3fd {
    background-position: -550px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f469-1f3fe {
    background-position: -575px -575px;
}
.joypixels-24-diversity._1f469-1f3fe-2764-1f48b-1f469-1f3ff {
    background-position: -600px 0px;
}
.joypixels-24-diversity._1f469-1f3fe {
    background-position: -600px -25px;
}
.joypixels-24-diversity._1f469-1f3ff-1f33e {
    background-position: -600px -50px;
}
.joypixels-24-diversity._1f469-1f3ff-1f373 {
    background-position: -600px -75px;
}
.joypixels-24-diversity._1f469-1f3ff-1f37c {
    background-position: -600px -100px;
}
.joypixels-24-diversity._1f469-1f3ff-1f393 {
    background-position: -600px -125px;
}
.joypixels-24-diversity._1f469-1f3ff-1f3a4 {
    background-position: -600px -150px;
}
.joypixels-24-diversity._1f469-1f3ff-1f3a8 {
    background-position: -600px -175px;
}
.joypixels-24-diversity._1f469-1f3ff-1f3eb {
    background-position: -600px -200px;
}
.joypixels-24-diversity._1f469-1f3ff-1f3ed {
    background-position: -600px -225px;
}
.joypixels-24-diversity._1f469-1f3ff-1f4bb {
    background-position: -600px -250px;
}
.joypixels-24-diversity._1f469-1f3ff-1f4bc {
    background-position: -600px -275px;
}
.joypixels-24-diversity._1f469-1f3ff-1f527 {
    background-position: -600px -300px;
}
.joypixels-24-diversity._1f469-1f3ff-1f52c {
    background-position: -600px -325px;
}
.joypixels-24-diversity._1f469-1f3ff-1f680 {
    background-position: -600px -350px;
}
.joypixels-24-diversity._1f469-1f3ff-1f692 {
    background-position: -600px -375px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f468-1f3fb {
    background-position: -600px -400px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f468-1f3fc {
    background-position: -600px -425px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f468-1f3fd {
    background-position: -600px -450px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f468-1f3fe {
    background-position: -600px -475px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f469-1f3fb {
    background-position: -600px -500px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f469-1f3fc {
    background-position: -600px -525px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f469-1f3fd {
    background-position: -600px -550px;
}
.joypixels-24-diversity._1f469-1f3ff-1f91d-1f469-1f3fe {
    background-position: -600px -575px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9af {
    background-position: 0px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9b0 {
    background-position: -25px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9b1 {
    background-position: -50px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9b2 {
    background-position: -75px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9b3 {
    background-position: -100px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9bc {
    background-position: -125px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-1f9bd {
    background-position: -150px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2695 {
    background-position: -175px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2696 {
    background-position: -200px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2708 {
    background-position: -225px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f468-1f3fb {
    background-position: -250px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f468-1f3fc {
    background-position: -275px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f468-1f3fd {
    background-position: -300px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f468-1f3fe {
    background-position: -325px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f468-1f3ff {
    background-position: -350px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f469-1f3fb {
    background-position: -375px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f469-1f3fc {
    background-position: -400px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f469-1f3fd {
    background-position: -425px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f469-1f3fe {
    background-position: -450px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f469-1f3ff {
    background-position: -475px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f468-1f3fb {
    background-position: -500px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f468-1f3fc {
    background-position: -525px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f468-1f3fd {
    background-position: -550px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f468-1f3fe {
    background-position: -575px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f468-1f3ff {
    background-position: -600px -600px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f469-1f3fb {
    background-position: -625px 0px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f469-1f3fc {
    background-position: -625px -25px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f469-1f3fd {
    background-position: -625px -50px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f469-1f3fe {
    background-position: -625px -75px;
}
.joypixels-24-diversity._1f469-1f3ff-2764-1f48b-1f469-1f3ff {
    background-position: -625px -100px;
}
.joypixels-24-diversity._1f469-1f3ff {
    background-position: -625px -125px;
}
.joypixels-24-diversity._1f46b-1f3fb {
    background-position: -625px -150px;
}
.joypixels-24-diversity._1f46b-1f3fc {
    background-position: -625px -175px;
}
.joypixels-24-diversity._1f46b-1f3fd {
    background-position: -625px -200px;
}
.joypixels-24-diversity._1f46b-1f3fe {
    background-position: -625px -225px;
}
.joypixels-24-diversity._1f46b-1f3ff {
    background-position: -625px -250px;
}
.joypixels-24-diversity._1f46c-1f3fb {
    background-position: -625px -275px;
}
.joypixels-24-diversity._1f46c-1f3fc {
    background-position: -625px -300px;
}
.joypixels-24-diversity._1f46c-1f3fd {
    background-position: -625px -325px;
}
.joypixels-24-diversity._1f46c-1f3fe {
    background-position: -625px -350px;
}
.joypixels-24-diversity._1f46c-1f3ff {
    background-position: -625px -375px;
}
.joypixels-24-diversity._1f46d-1f3fb {
    background-position: -625px -400px;
}
.joypixels-24-diversity._1f46d-1f3fc {
    background-position: -625px -425px;
}
.joypixels-24-diversity._1f46d-1f3fd {
    background-position: -625px -450px;
}
.joypixels-24-diversity._1f46d-1f3fe {
    background-position: -625px -475px;
}
.joypixels-24-diversity._1f46d-1f3ff {
    background-position: -625px -500px;
}
.joypixels-24-diversity._1f46e-1f3fb-2640 {
    background-position: -625px -525px;
}
.joypixels-24-diversity._1f46e-1f3fb-2642 {
    background-position: -625px -550px;
}
.joypixels-24-diversity._1f46e-1f3fb {
    background-position: -625px -575px;
}
.joypixels-24-diversity._1f46e-1f3fc-2640 {
    background-position: -625px -600px;
}
.joypixels-24-diversity._1f46e-1f3fc-2642 {
    background-position: 0px -625px;
}
.joypixels-24-diversity._1f46e-1f3fc {
    background-position: -25px -625px;
}
.joypixels-24-diversity._1f46e-1f3fd-2640 {
    background-position: -50px -625px;
}
.joypixels-24-diversity._1f46e-1f3fd-2642 {
    background-position: -75px -625px;
}
.joypixels-24-diversity._1f46e-1f3fd {
    background-position: -100px -625px;
}
.joypixels-24-diversity._1f46e-1f3fe-2640 {
    background-position: -125px -625px;
}
.joypixels-24-diversity._1f46e-1f3fe-2642 {
    background-position: -150px -625px;
}
.joypixels-24-diversity._1f46e-1f3fe {
    background-position: -175px -625px;
}
.joypixels-24-diversity._1f46e-1f3ff-2640 {
    background-position: -200px -625px;
}
.joypixels-24-diversity._1f46e-1f3ff-2642 {
    background-position: -225px -625px;
}
.joypixels-24-diversity._1f46e-1f3ff {
    background-position: -250px -625px;
}
.joypixels-24-diversity._1f470-1f3fb-2640 {
    background-position: -275px -625px;
}
.joypixels-24-diversity._1f470-1f3fb-2642 {
    background-position: -300px -625px;
}
.joypixels-24-diversity._1f470-1f3fb {
    background-position: -325px -625px;
}
.joypixels-24-diversity._1f470-1f3fc-2640 {
    background-position: -350px -625px;
}
.joypixels-24-diversity._1f470-1f3fc-2642 {
    background-position: -375px -625px;
}
.joypixels-24-diversity._1f470-1f3fc {
    background-position: -400px -625px;
}
.joypixels-24-diversity._1f470-1f3fd-2640 {
    background-position: -425px -625px;
}
.joypixels-24-diversity._1f470-1f3fd-2642 {
    background-position: -450px -625px;
}
.joypixels-24-diversity._1f470-1f3fd {
    background-position: -475px -625px;
}
.joypixels-24-diversity._1f470-1f3fe-2640 {
    background-position: -500px -625px;
}
.joypixels-24-diversity._1f470-1f3fe-2642 {
    background-position: -525px -625px;
}
.joypixels-24-diversity._1f470-1f3fe {
    background-position: -550px -625px;
}
.joypixels-24-diversity._1f470-1f3ff-2640 {
    background-position: -575px -625px;
}
.joypixels-24-diversity._1f470-1f3ff-2642 {
    background-position: -600px -625px;
}
.joypixels-24-diversity._1f470-1f3ff {
    background-position: -625px -625px;
}
.joypixels-24-diversity._1f471-1f3fb-2640 {
    background-position: -650px 0px;
}
.joypixels-24-diversity._1f471-1f3fb-2642 {
    background-position: -650px -25px;
}
.joypixels-24-diversity._1f471-1f3fb {
    background-position: -650px -50px;
}
.joypixels-24-diversity._1f471-1f3fc-2640 {
    background-position: -650px -75px;
}
.joypixels-24-diversity._1f471-1f3fc-2642 {
    background-position: -650px -100px;
}
.joypixels-24-diversity._1f471-1f3fc {
    background-position: -650px -125px;
}
.joypixels-24-diversity._1f471-1f3fd-2640 {
    background-position: -650px -150px;
}
.joypixels-24-diversity._1f471-1f3fd-2642 {
    background-position: -650px -175px;
}
.joypixels-24-diversity._1f471-1f3fd {
    background-position: -650px -200px;
}
.joypixels-24-diversity._1f471-1f3fe-2640 {
    background-position: -650px -225px;
}
.joypixels-24-diversity._1f471-1f3fe-2642 {
    background-position: -650px -250px;
}
.joypixels-24-diversity._1f471-1f3fe {
    background-position: -650px -275px;
}
.joypixels-24-diversity._1f471-1f3ff-2640 {
    background-position: -650px -300px;
}
.joypixels-24-diversity._1f471-1f3ff-2642 {
    background-position: -650px -325px;
}
.joypixels-24-diversity._1f471-1f3ff {
    background-position: -650px -350px;
}
.joypixels-24-diversity._1f472-1f3fb {
    background-position: -650px -375px;
}
.joypixels-24-diversity._1f472-1f3fc {
    background-position: -650px -400px;
}
.joypixels-24-diversity._1f472-1f3fd {
    background-position: -650px -425px;
}
.joypixels-24-diversity._1f472-1f3fe {
    background-position: -650px -450px;
}
.joypixels-24-diversity._1f472-1f3ff {
    background-position: -650px -475px;
}
.joypixels-24-diversity._1f473-1f3fb-2640 {
    background-position: -650px -500px;
}
.joypixels-24-diversity._1f473-1f3fb-2642 {
    background-position: -650px -525px;
}
.joypixels-24-diversity._1f473-1f3fb {
    background-position: -650px -550px;
}
.joypixels-24-diversity._1f473-1f3fc-2640 {
    background-position: -650px -575px;
}
.joypixels-24-diversity._1f473-1f3fc-2642 {
    background-position: -650px -600px;
}
.joypixels-24-diversity._1f473-1f3fc {
    background-position: -650px -625px;
}
.joypixels-24-diversity._1f473-1f3fd-2640 {
    background-position: 0px -650px;
}
.joypixels-24-diversity._1f473-1f3fd-2642 {
    background-position: -25px -650px;
}
.joypixels-24-diversity._1f473-1f3fd {
    background-position: -50px -650px;
}
.joypixels-24-diversity._1f473-1f3fe-2640 {
    background-position: -75px -650px;
}
.joypixels-24-diversity._1f473-1f3fe-2642 {
    background-position: -100px -650px;
}
.joypixels-24-diversity._1f473-1f3fe {
    background-position: -125px -650px;
}
.joypixels-24-diversity._1f473-1f3ff-2640 {
    background-position: -150px -650px;
}
.joypixels-24-diversity._1f473-1f3ff-2642 {
    background-position: -175px -650px;
}
.joypixels-24-diversity._1f473-1f3ff {
    background-position: -200px -650px;
}
.joypixels-24-diversity._1f474-1f3fb {
    background-position: -225px -650px;
}
.joypixels-24-diversity._1f474-1f3fc {
    background-position: -250px -650px;
}
.joypixels-24-diversity._1f474-1f3fd {
    background-position: -275px -650px;
}
.joypixels-24-diversity._1f474-1f3fe {
    background-position: -300px -650px;
}
.joypixels-24-diversity._1f474-1f3ff {
    background-position: -325px -650px;
}
.joypixels-24-diversity._1f475-1f3fb {
    background-position: -350px -650px;
}
.joypixels-24-diversity._1f475-1f3fc {
    background-position: -375px -650px;
}
.joypixels-24-diversity._1f475-1f3fd {
    background-position: -400px -650px;
}
.joypixels-24-diversity._1f475-1f3fe {
    background-position: -425px -650px;
}
.joypixels-24-diversity._1f475-1f3ff {
    background-position: -450px -650px;
}
.joypixels-24-diversity._1f476-1f3fb {
    background-position: -475px -650px;
}
.joypixels-24-diversity._1f476-1f3fc {
    background-position: -500px -650px;
}
.joypixels-24-diversity._1f476-1f3fd {
    background-position: -525px -650px;
}
.joypixels-24-diversity._1f476-1f3fe {
    background-position: -550px -650px;
}
.joypixels-24-diversity._1f476-1f3ff {
    background-position: -575px -650px;
}
.joypixels-24-diversity._1f477-1f3fb-2640 {
    background-position: -600px -650px;
}
.joypixels-24-diversity._1f477-1f3fb-2642 {
    background-position: -625px -650px;
}
.joypixels-24-diversity._1f477-1f3fb {
    background-position: -650px -650px;
}
.joypixels-24-diversity._1f477-1f3fc-2640 {
    background-position: -675px 0px;
}
.joypixels-24-diversity._1f477-1f3fc-2642 {
    background-position: -675px -25px;
}
.joypixels-24-diversity._1f477-1f3fc {
    background-position: -675px -50px;
}
.joypixels-24-diversity._1f477-1f3fd-2640 {
    background-position: -675px -75px;
}
.joypixels-24-diversity._1f477-1f3fd-2642 {
    background-position: -675px -100px;
}
.joypixels-24-diversity._1f477-1f3fd {
    background-position: -675px -125px;
}
.joypixels-24-diversity._1f477-1f3fe-2640 {
    background-position: -675px -150px;
}
.joypixels-24-diversity._1f477-1f3fe-2642 {
    background-position: -675px -175px;
}
.joypixels-24-diversity._1f477-1f3fe {
    background-position: -675px -200px;
}
.joypixels-24-diversity._1f477-1f3ff-2640 {
    background-position: -675px -225px;
}
.joypixels-24-diversity._1f477-1f3ff-2642 {
    background-position: -675px -250px;
}
.joypixels-24-diversity._1f477-1f3ff {
    background-position: -675px -275px;
}
.joypixels-24-diversity._1f478-1f3fb {
    background-position: -675px -300px;
}
.joypixels-24-diversity._1f478-1f3fc {
    background-position: -675px -325px;
}
.joypixels-24-diversity._1f478-1f3fd {
    background-position: -675px -350px;
}
.joypixels-24-diversity._1f478-1f3fe {
    background-position: -675px -375px;
}
.joypixels-24-diversity._1f478-1f3ff {
    background-position: -675px -400px;
}
.joypixels-24-diversity._1f47c-1f3fb {
    background-position: -675px -425px;
}
.joypixels-24-diversity._1f47c-1f3fc {
    background-position: -675px -450px;
}
.joypixels-24-diversity._1f47c-1f3fd {
    background-position: -675px -475px;
}
.joypixels-24-diversity._1f47c-1f3fe {
    background-position: -675px -500px;
}
.joypixels-24-diversity._1f47c-1f3ff {
    background-position: -675px -525px;
}
.joypixels-24-diversity._1f481-1f3fb-2640 {
    background-position: -675px -550px;
}
.joypixels-24-diversity._1f481-1f3fb-2642 {
    background-position: -675px -575px;
}
.joypixels-24-diversity._1f481-1f3fb {
    background-position: -675px -600px;
}
.joypixels-24-diversity._1f481-1f3fc-2640 {
    background-position: -675px -625px;
}
.joypixels-24-diversity._1f481-1f3fc-2642 {
    background-position: -675px -650px;
}
.joypixels-24-diversity._1f481-1f3fc {
    background-position: 0px -675px;
}
.joypixels-24-diversity._1f481-1f3fd-2640 {
    background-position: -25px -675px;
}
.joypixels-24-diversity._1f481-1f3fd-2642 {
    background-position: -50px -675px;
}
.joypixels-24-diversity._1f481-1f3fd {
    background-position: -75px -675px;
}
.joypixels-24-diversity._1f481-1f3fe-2640 {
    background-position: -100px -675px;
}
.joypixels-24-diversity._1f481-1f3fe-2642 {
    background-position: -125px -675px;
}
.joypixels-24-diversity._1f481-1f3fe {
    background-position: -150px -675px;
}
.joypixels-24-diversity._1f481-1f3ff-2640 {
    background-position: -175px -675px;
}
.joypixels-24-diversity._1f481-1f3ff-2642 {
    background-position: -200px -675px;
}
.joypixels-24-diversity._1f481-1f3ff {
    background-position: -225px -675px;
}
.joypixels-24-diversity._1f482-1f3fb-2640 {
    background-position: -250px -675px;
}
.joypixels-24-diversity._1f482-1f3fb-2642 {
    background-position: -275px -675px;
}
.joypixels-24-diversity._1f482-1f3fb {
    background-position: -300px -675px;
}
.joypixels-24-diversity._1f482-1f3fc-2640 {
    background-position: -325px -675px;
}
.joypixels-24-diversity._1f482-1f3fc-2642 {
    background-position: -350px -675px;
}
.joypixels-24-diversity._1f482-1f3fc {
    background-position: -375px -675px;
}
.joypixels-24-diversity._1f482-1f3fd-2640 {
    background-position: -400px -675px;
}
.joypixels-24-diversity._1f482-1f3fd-2642 {
    background-position: -425px -675px;
}
.joypixels-24-diversity._1f482-1f3fd {
    background-position: -450px -675px;
}
.joypixels-24-diversity._1f482-1f3fe-2640 {
    background-position: -475px -675px;
}
.joypixels-24-diversity._1f482-1f3fe-2642 {
    background-position: -500px -675px;
}
.joypixels-24-diversity._1f482-1f3fe {
    background-position: -525px -675px;
}
.joypixels-24-diversity._1f482-1f3ff-2640 {
    background-position: -550px -675px;
}
.joypixels-24-diversity._1f482-1f3ff-2642 {
    background-position: -575px -675px;
}
.joypixels-24-diversity._1f482-1f3ff {
    background-position: -600px -675px;
}
.joypixels-24-diversity._1f483-1f3fb {
    background-position: -625px -675px;
}
.joypixels-24-diversity._1f483-1f3fc {
    background-position: -650px -675px;
}
.joypixels-24-diversity._1f483-1f3fd {
    background-position: -675px -675px;
}
.joypixels-24-diversity._1f483-1f3fe {
    background-position: -700px 0px;
}
.joypixels-24-diversity._1f483-1f3ff {
    background-position: -700px -25px;
}
.joypixels-24-diversity._1f485-1f3fb {
    background-position: -700px -50px;
}
.joypixels-24-diversity._1f485-1f3fc {
    background-position: -700px -75px;
}
.joypixels-24-diversity._1f485-1f3fd {
    background-position: -700px -100px;
}
.joypixels-24-diversity._1f485-1f3fe {
    background-position: -700px -125px;
}
.joypixels-24-diversity._1f485-1f3ff {
    background-position: -700px -150px;
}
.joypixels-24-diversity._1f486-1f3fb-2640 {
    background-position: -700px -175px;
}
.joypixels-24-diversity._1f486-1f3fb-2642 {
    background-position: -700px -200px;
}
.joypixels-24-diversity._1f486-1f3fb {
    background-position: -700px -225px;
}
.joypixels-24-diversity._1f486-1f3fc-2640 {
    background-position: -700px -250px;
}
.joypixels-24-diversity._1f486-1f3fc-2642 {
    background-position: -700px -275px;
}
.joypixels-24-diversity._1f486-1f3fc {
    background-position: -700px -300px;
}
.joypixels-24-diversity._1f486-1f3fd-2640 {
    background-position: -700px -325px;
}
.joypixels-24-diversity._1f486-1f3fd-2642 {
    background-position: -700px -350px;
}
.joypixels-24-diversity._1f486-1f3fd {
    background-position: -700px -375px;
}
.joypixels-24-diversity._1f486-1f3fe-2640 {
    background-position: -700px -400px;
}
.joypixels-24-diversity._1f486-1f3fe-2642 {
    background-position: -700px -425px;
}
.joypixels-24-diversity._1f486-1f3fe {
    background-position: -700px -450px;
}
.joypixels-24-diversity._1f486-1f3ff-2640 {
    background-position: -700px -475px;
}
.joypixels-24-diversity._1f486-1f3ff-2642 {
    background-position: -700px -500px;
}
.joypixels-24-diversity._1f486-1f3ff {
    background-position: -700px -525px;
}
.joypixels-24-diversity._1f487-1f3fb-2640 {
    background-position: -700px -550px;
}
.joypixels-24-diversity._1f487-1f3fb-2642 {
    background-position: -700px -575px;
}
.joypixels-24-diversity._1f487-1f3fb {
    background-position: -700px -600px;
}
.joypixels-24-diversity._1f487-1f3fc-2640 {
    background-position: -700px -625px;
}
.joypixels-24-diversity._1f487-1f3fc-2642 {
    background-position: -700px -650px;
}
.joypixels-24-diversity._1f487-1f3fc {
    background-position: -700px -675px;
}
.joypixels-24-diversity._1f487-1f3fd-2640 {
    background-position: 0px -700px;
}
.joypixels-24-diversity._1f487-1f3fd-2642 {
    background-position: -25px -700px;
}
.joypixels-24-diversity._1f487-1f3fd {
    background-position: -50px -700px;
}
.joypixels-24-diversity._1f487-1f3fe-2640 {
    background-position: -75px -700px;
}
.joypixels-24-diversity._1f487-1f3fe-2642 {
    background-position: -100px -700px;
}
.joypixels-24-diversity._1f487-1f3fe {
    background-position: -125px -700px;
}
.joypixels-24-diversity._1f487-1f3ff-2640 {
    background-position: -150px -700px;
}
.joypixels-24-diversity._1f487-1f3ff-2642 {
    background-position: -175px -700px;
}
.joypixels-24-diversity._1f487-1f3ff {
    background-position: -200px -700px;
}
.joypixels-24-diversity._1f48f-1f3fb {
    background-position: -225px -700px;
}
.joypixels-24-diversity._1f48f-1f3fc {
    background-position: -250px -700px;
}
.joypixels-24-diversity._1f48f-1f3fd {
    background-position: -275px -700px;
}
.joypixels-24-diversity._1f48f-1f3fe {
    background-position: -300px -700px;
}
.joypixels-24-diversity._1f48f-1f3ff {
    background-position: -325px -700px;
}
.joypixels-24-diversity._1f491-1f3fb {
    background-position: -350px -700px;
}
.joypixels-24-diversity._1f491-1f3fc {
    background-position: -375px -700px;
}
.joypixels-24-diversity._1f491-1f3fd {
    background-position: -400px -700px;
}
.joypixels-24-diversity._1f491-1f3fe {
    background-position: -425px -700px;
}
.joypixels-24-diversity._1f491-1f3ff {
    background-position: -450px -700px;
}
.joypixels-24-diversity._1f4aa-1f3fb {
    background-position: -475px -700px;
}
.joypixels-24-diversity._1f4aa-1f3fc {
    background-position: -500px -700px;
}
.joypixels-24-diversity._1f4aa-1f3fd {
    background-position: -525px -700px;
}
.joypixels-24-diversity._1f4aa-1f3fe {
    background-position: -550px -700px;
}
.joypixels-24-diversity._1f4aa-1f3ff {
    background-position: -575px -700px;
}
.joypixels-24-diversity._1f574-1f3fb {
    background-position: -600px -700px;
}
.joypixels-24-diversity._1f574-1f3fc {
    background-position: -625px -700px;
}
.joypixels-24-diversity._1f574-1f3fd {
    background-position: -650px -700px;
}
.joypixels-24-diversity._1f574-1f3fe {
    background-position: -675px -700px;
}
.joypixels-24-diversity._1f574-1f3ff {
    background-position: -700px -700px;
}
.joypixels-24-diversity._1f575-1f3fb-2640 {
    background-position: -725px 0px;
}
.joypixels-24-diversity._1f575-1f3fb-2642 {
    background-position: -725px -25px;
}
.joypixels-24-diversity._1f575-1f3fb {
    background-position: -725px -50px;
}
.joypixels-24-diversity._1f575-1f3fc-2640 {
    background-position: -725px -75px;
}
.joypixels-24-diversity._1f575-1f3fc-2642 {
    background-position: -725px -100px;
}
.joypixels-24-diversity._1f575-1f3fc {
    background-position: -725px -125px;
}
.joypixels-24-diversity._1f575-1f3fd-2640 {
    background-position: -725px -150px;
}
.joypixels-24-diversity._1f575-1f3fd-2642 {
    background-position: -725px -175px;
}
.joypixels-24-diversity._1f575-1f3fd {
    background-position: -725px -200px;
}
.joypixels-24-diversity._1f575-1f3fe-2640 {
    background-position: -725px -225px;
}
.joypixels-24-diversity._1f575-1f3fe-2642 {
    background-position: -725px -250px;
}
.joypixels-24-diversity._1f575-1f3fe {
    background-position: -725px -275px;
}
.joypixels-24-diversity._1f575-1f3ff-2640 {
    background-position: -725px -300px;
}
.joypixels-24-diversity._1f575-1f3ff-2642 {
    background-position: -725px -325px;
}
.joypixels-24-diversity._1f575-1f3ff {
    background-position: -725px -350px;
}
.joypixels-24-diversity._1f57a-1f3fb {
    background-position: -725px -375px;
}
.joypixels-24-diversity._1f57a-1f3fc {
    background-position: -725px -400px;
}
.joypixels-24-diversity._1f57a-1f3fd {
    background-position: -725px -425px;
}
.joypixels-24-diversity._1f57a-1f3fe {
    background-position: -725px -450px;
}
.joypixels-24-diversity._1f57a-1f3ff {
    background-position: -725px -475px;
}
.joypixels-24-diversity._1f590-1f3fb {
    background-position: -725px -500px;
}
.joypixels-24-diversity._1f590-1f3fc {
    background-position: -725px -525px;
}
.joypixels-24-diversity._1f590-1f3fd {
    background-position: -725px -550px;
}
.joypixels-24-diversity._1f590-1f3fe {
    background-position: -725px -575px;
}
.joypixels-24-diversity._1f590-1f3ff {
    background-position: -725px -600px;
}
.joypixels-24-diversity._1f595-1f3fb {
    background-position: -725px -625px;
}
.joypixels-24-diversity._1f595-1f3fc {
    background-position: -725px -650px;
}
.joypixels-24-diversity._1f595-1f3fd {
    background-position: -725px -675px;
}
.joypixels-24-diversity._1f595-1f3fe {
    background-position: -725px -700px;
}
.joypixels-24-diversity._1f595-1f3ff {
    background-position: 0px -725px;
}
.joypixels-24-diversity._1f596-1f3fb {
    background-position: -25px -725px;
}
.joypixels-24-diversity._1f596-1f3fc {
    background-position: -50px -725px;
}
.joypixels-24-diversity._1f596-1f3fd {
    background-position: -75px -725px;
}
.joypixels-24-diversity._1f596-1f3fe {
    background-position: -100px -725px;
}
.joypixels-24-diversity._1f596-1f3ff {
    background-position: -125px -725px;
}
.joypixels-24-diversity._1f645-1f3fb-2640 {
    background-position: -150px -725px;
}
.joypixels-24-diversity._1f645-1f3fb-2642 {
    background-position: -175px -725px;
}
.joypixels-24-diversity._1f645-1f3fb {
    background-position: -200px -725px;
}
.joypixels-24-diversity._1f645-1f3fc-2640 {
    background-position: -225px -725px;
}
.joypixels-24-diversity._1f645-1f3fc-2642 {
    background-position: -250px -725px;
}
.joypixels-24-diversity._1f645-1f3fc {
    background-position: -275px -725px;
}
.joypixels-24-diversity._1f645-1f3fd-2640 {
    background-position: -300px -725px;
}
.joypixels-24-diversity._1f645-1f3fd-2642 {
    background-position: -325px -725px;
}
.joypixels-24-diversity._1f645-1f3fd {
    background-position: -350px -725px;
}
.joypixels-24-diversity._1f645-1f3fe-2640 {
    background-position: -375px -725px;
}
.joypixels-24-diversity._1f645-1f3fe-2642 {
    background-position: -400px -725px;
}
.joypixels-24-diversity._1f645-1f3fe {
    background-position: -425px -725px;
}
.joypixels-24-diversity._1f645-1f3ff-2640 {
    background-position: -450px -725px;
}
.joypixels-24-diversity._1f645-1f3ff-2642 {
    background-position: -475px -725px;
}
.joypixels-24-diversity._1f645-1f3ff {
    background-position: -500px -725px;
}
.joypixels-24-diversity._1f646-1f3fb-2640 {
    background-position: -525px -725px;
}
.joypixels-24-diversity._1f646-1f3fb-2642 {
    background-position: -550px -725px;
}
.joypixels-24-diversity._1f646-1f3fb {
    background-position: -575px -725px;
}
.joypixels-24-diversity._1f646-1f3fc-2640 {
    background-position: -600px -725px;
}
.joypixels-24-diversity._1f646-1f3fc-2642 {
    background-position: -625px -725px;
}
.joypixels-24-diversity._1f646-1f3fc {
    background-position: -650px -725px;
}
.joypixels-24-diversity._1f646-1f3fd-2640 {
    background-position: -675px -725px;
}
.joypixels-24-diversity._1f646-1f3fd-2642 {
    background-position: -700px -725px;
}
.joypixels-24-diversity._1f646-1f3fd {
    background-position: -725px -725px;
}
.joypixels-24-diversity._1f646-1f3fe-2640 {
    background-position: -750px 0px;
}
.joypixels-24-diversity._1f646-1f3fe-2642 {
    background-position: -750px -25px;
}
.joypixels-24-diversity._1f646-1f3fe {
    background-position: -750px -50px;
}
.joypixels-24-diversity._1f646-1f3ff-2640 {
    background-position: -750px -75px;
}
.joypixels-24-diversity._1f646-1f3ff-2642 {
    background-position: -750px -100px;
}
.joypixels-24-diversity._1f646-1f3ff {
    background-position: -750px -125px;
}
.joypixels-24-diversity._1f647-1f3fb-2640 {
    background-position: -750px -150px;
}
.joypixels-24-diversity._1f647-1f3fb-2642 {
    background-position: -750px -175px;
}
.joypixels-24-diversity._1f647-1f3fb {
    background-position: -750px -200px;
}
.joypixels-24-diversity._1f647-1f3fc-2640 {
    background-position: -750px -225px;
}
.joypixels-24-diversity._1f647-1f3fc-2642 {
    background-position: -750px -250px;
}
.joypixels-24-diversity._1f647-1f3fc {
    background-position: -750px -275px;
}
.joypixels-24-diversity._1f647-1f3fd-2640 {
    background-position: -750px -300px;
}
.joypixels-24-diversity._1f647-1f3fd-2642 {
    background-position: -750px -325px;
}
.joypixels-24-diversity._1f647-1f3fd {
    background-position: -750px -350px;
}
.joypixels-24-diversity._1f647-1f3fe-2640 {
    background-position: -750px -375px;
}
.joypixels-24-diversity._1f647-1f3fe-2642 {
    background-position: -750px -400px;
}
.joypixels-24-diversity._1f647-1f3fe {
    background-position: -750px -425px;
}
.joypixels-24-diversity._1f647-1f3ff-2640 {
    background-position: -750px -450px;
}
.joypixels-24-diversity._1f647-1f3ff-2642 {
    background-position: -750px -475px;
}
.joypixels-24-diversity._1f647-1f3ff {
    background-position: -750px -500px;
}
.joypixels-24-diversity._1f64b-1f3fb-2640 {
    background-position: -750px -525px;
}
.joypixels-24-diversity._1f64b-1f3fb-2642 {
    background-position: -750px -550px;
}
.joypixels-24-diversity._1f64b-1f3fb {
    background-position: -750px -575px;
}
.joypixels-24-diversity._1f64b-1f3fc-2640 {
    background-position: -750px -600px;
}
.joypixels-24-diversity._1f64b-1f3fc-2642 {
    background-position: -750px -625px;
}
.joypixels-24-diversity._1f64b-1f3fc {
    background-position: -750px -650px;
}
.joypixels-24-diversity._1f64b-1f3fd-2640 {
    background-position: -750px -675px;
}
.joypixels-24-diversity._1f64b-1f3fd-2642 {
    background-position: -750px -700px;
}
.joypixels-24-diversity._1f64b-1f3fd {
    background-position: -750px -725px;
}
.joypixels-24-diversity._1f64b-1f3fe-2640 {
    background-position: 0px -750px;
}
.joypixels-24-diversity._1f64b-1f3fe-2642 {
    background-position: -25px -750px;
}
.joypixels-24-diversity._1f64b-1f3fe {
    background-position: -50px -750px;
}
.joypixels-24-diversity._1f64b-1f3ff-2640 {
    background-position: -75px -750px;
}
.joypixels-24-diversity._1f64b-1f3ff-2642 {
    background-position: -100px -750px;
}
.joypixels-24-diversity._1f64b-1f3ff {
    background-position: -125px -750px;
}
.joypixels-24-diversity._1f64c-1f3fb {
    background-position: -150px -750px;
}
.joypixels-24-diversity._1f64c-1f3fc {
    background-position: -175px -750px;
}
.joypixels-24-diversity._1f64c-1f3fd {
    background-position: -200px -750px;
}
.joypixels-24-diversity._1f64c-1f3fe {
    background-position: -225px -750px;
}
.joypixels-24-diversity._1f64c-1f3ff {
    background-position: -250px -750px;
}
.joypixels-24-diversity._1f64d-1f3fb-2640 {
    background-position: -275px -750px;
}
.joypixels-24-diversity._1f64d-1f3fb-2642 {
    background-position: -300px -750px;
}
.joypixels-24-diversity._1f64d-1f3fb {
    background-position: -325px -750px;
}
.joypixels-24-diversity._1f64d-1f3fc-2640 {
    background-position: -350px -750px;
}
.joypixels-24-diversity._1f64d-1f3fc-2642 {
    background-position: -375px -750px;
}
.joypixels-24-diversity._1f64d-1f3fc {
    background-position: -400px -750px;
}
.joypixels-24-diversity._1f64d-1f3fd-2640 {
    background-position: -425px -750px;
}
.joypixels-24-diversity._1f64d-1f3fd-2642 {
    background-position: -450px -750px;
}
.joypixels-24-diversity._1f64d-1f3fd {
    background-position: -475px -750px;
}
.joypixels-24-diversity._1f64d-1f3fe-2640 {
    background-position: -500px -750px;
}
.joypixels-24-diversity._1f64d-1f3fe-2642 {
    background-position: -525px -750px;
}
.joypixels-24-diversity._1f64d-1f3fe {
    background-position: -550px -750px;
}
.joypixels-24-diversity._1f64d-1f3ff-2640 {
    background-position: -575px -750px;
}
.joypixels-24-diversity._1f64d-1f3ff-2642 {
    background-position: -600px -750px;
}
.joypixels-24-diversity._1f64d-1f3ff {
    background-position: -625px -750px;
}
.joypixels-24-diversity._1f64e-1f3fb-2640 {
    background-position: -650px -750px;
}
.joypixels-24-diversity._1f64e-1f3fb-2642 {
    background-position: -675px -750px;
}
.joypixels-24-diversity._1f64e-1f3fb {
    background-position: -700px -750px;
}
.joypixels-24-diversity._1f64e-1f3fc-2640 {
    background-position: -725px -750px;
}
.joypixels-24-diversity._1f64e-1f3fc-2642 {
    background-position: -750px -750px;
}
.joypixels-24-diversity._1f64e-1f3fc {
    background-position: -775px 0px;
}
.joypixels-24-diversity._1f64e-1f3fd-2640 {
    background-position: -775px -25px;
}
.joypixels-24-diversity._1f64e-1f3fd-2642 {
    background-position: -775px -50px;
}
.joypixels-24-diversity._1f64e-1f3fd {
    background-position: -775px -75px;
}
.joypixels-24-diversity._1f64e-1f3fe-2640 {
    background-position: -775px -100px;
}
.joypixels-24-diversity._1f64e-1f3fe-2642 {
    background-position: -775px -125px;
}
.joypixels-24-diversity._1f64e-1f3fe {
    background-position: -775px -150px;
}
.joypixels-24-diversity._1f64e-1f3ff-2640 {
    background-position: -775px -175px;
}
.joypixels-24-diversity._1f64e-1f3ff-2642 {
    background-position: -775px -200px;
}
.joypixels-24-diversity._1f64e-1f3ff {
    background-position: -775px -225px;
}
.joypixels-24-diversity._1f64f-1f3fb {
    background-position: -775px -250px;
}
.joypixels-24-diversity._1f64f-1f3fc {
    background-position: -775px -275px;
}
.joypixels-24-diversity._1f64f-1f3fd {
    background-position: -775px -300px;
}
.joypixels-24-diversity._1f64f-1f3fe {
    background-position: -775px -325px;
}
.joypixels-24-diversity._1f64f-1f3ff {
    background-position: -775px -350px;
}
.joypixels-24-diversity._1f6a3-1f3fb-2640 {
    background-position: -775px -375px;
}
.joypixels-24-diversity._1f6a3-1f3fb-2642 {
    background-position: -775px -400px;
}
.joypixels-24-diversity._1f6a3-1f3fb {
    background-position: -775px -425px;
}
.joypixels-24-diversity._1f6a3-1f3fc-2640 {
    background-position: -775px -450px;
}
.joypixels-24-diversity._1f6a3-1f3fc-2642 {
    background-position: -775px -475px;
}
.joypixels-24-diversity._1f6a3-1f3fc {
    background-position: -775px -500px;
}
.joypixels-24-diversity._1f6a3-1f3fd-2640 {
    background-position: -775px -525px;
}
.joypixels-24-diversity._1f6a3-1f3fd-2642 {
    background-position: -775px -550px;
}
.joypixels-24-diversity._1f6a3-1f3fd {
    background-position: -775px -575px;
}
.joypixels-24-diversity._1f6a3-1f3fe-2640 {
    background-position: -775px -600px;
}
.joypixels-24-diversity._1f6a3-1f3fe-2642 {
    background-position: -775px -625px;
}
.joypixels-24-diversity._1f6a3-1f3fe {
    background-position: -775px -650px;
}
.joypixels-24-diversity._1f6a3-1f3ff-2640 {
    background-position: -775px -675px;
}
.joypixels-24-diversity._1f6a3-1f3ff-2642 {
    background-position: -775px -700px;
}
.joypixels-24-diversity._1f6a3-1f3ff {
    background-position: -775px -725px;
}
.joypixels-24-diversity._1f6b4-1f3fb-2640 {
    background-position: -775px -750px;
}
.joypixels-24-diversity._1f6b4-1f3fb-2642 {
    background-position: 0px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fb {
    background-position: -25px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fc-2640 {
    background-position: -50px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fc-2642 {
    background-position: -75px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fc {
    background-position: -100px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fd-2640 {
    background-position: -125px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fd-2642 {
    background-position: -150px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fd {
    background-position: -175px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fe-2640 {
    background-position: -200px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fe-2642 {
    background-position: -225px -775px;
}
.joypixels-24-diversity._1f6b4-1f3fe {
    background-position: -250px -775px;
}
.joypixels-24-diversity._1f6b4-1f3ff-2640 {
    background-position: -275px -775px;
}
.joypixels-24-diversity._1f6b4-1f3ff-2642 {
    background-position: -300px -775px;
}
.joypixels-24-diversity._1f6b4-1f3ff {
    background-position: -325px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fb-2640 {
    background-position: -350px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fb-2642 {
    background-position: -375px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fb {
    background-position: -400px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fc-2640 {
    background-position: -425px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fc-2642 {
    background-position: -450px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fc {
    background-position: -475px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fd-2640 {
    background-position: -500px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fd-2642 {
    background-position: -525px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fd {
    background-position: -550px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fe-2640 {
    background-position: -575px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fe-2642 {
    background-position: -600px -775px;
}
.joypixels-24-diversity._1f6b5-1f3fe {
    background-position: -625px -775px;
}
.joypixels-24-diversity._1f6b5-1f3ff-2640 {
    background-position: -650px -775px;
}
.joypixels-24-diversity._1f6b5-1f3ff-2642 {
    background-position: -675px -775px;
}
.joypixels-24-diversity._1f6b5-1f3ff {
    background-position: -700px -775px;
}
.joypixels-24-diversity._1f6b6-1f3fb-2640 {
    background-position: -725px -775px;
}
.joypixels-24-diversity._1f6b6-1f3fb-2642 {
    background-position: -750px -775px;
}
.joypixels-24-diversity._1f6b6-1f3fb {
    background-position: -775px -775px;
}
.joypixels-24-diversity._1f6b6-1f3fc-2640 {
    background-position: -800px 0px;
}
.joypixels-24-diversity._1f6b6-1f3fc-2642 {
    background-position: -800px -25px;
}
.joypixels-24-diversity._1f6b6-1f3fc {
    background-position: -800px -50px;
}
.joypixels-24-diversity._1f6b6-1f3fd-2640 {
    background-position: -800px -75px;
}
.joypixels-24-diversity._1f6b6-1f3fd-2642 {
    background-position: -800px -100px;
}
.joypixels-24-diversity._1f6b6-1f3fd {
    background-position: -800px -125px;
}
.joypixels-24-diversity._1f6b6-1f3fe-2640 {
    background-position: -800px -150px;
}
.joypixels-24-diversity._1f6b6-1f3fe-2642 {
    background-position: -800px -175px;
}
.joypixels-24-diversity._1f6b6-1f3fe {
    background-position: -800px -200px;
}
.joypixels-24-diversity._1f6b6-1f3ff-2640 {
    background-position: -800px -225px;
}
.joypixels-24-diversity._1f6b6-1f3ff-2642 {
    background-position: -800px -250px;
}
.joypixels-24-diversity._1f6b6-1f3ff {
    background-position: -800px -275px;
}
.joypixels-24-diversity._1f6c0-1f3fb {
    background-position: -800px -300px;
}
.joypixels-24-diversity._1f6c0-1f3fc {
    background-position: -800px -325px;
}
.joypixels-24-diversity._1f6c0-1f3fd {
    background-position: -800px -350px;
}
.joypixels-24-diversity._1f6c0-1f3fe {
    background-position: -800px -375px;
}
.joypixels-24-diversity._1f6c0-1f3ff {
    background-position: -800px -400px;
}
.joypixels-24-diversity._1f6cc-1f3fb {
    background-position: -800px -425px;
}
.joypixels-24-diversity._1f6cc-1f3fc {
    background-position: -800px -450px;
}
.joypixels-24-diversity._1f6cc-1f3fd {
    background-position: -800px -475px;
}
.joypixels-24-diversity._1f6cc-1f3fe {
    background-position: -800px -500px;
}
.joypixels-24-diversity._1f6cc-1f3ff {
    background-position: -800px -525px;
}
.joypixels-24-diversity._1f90c-1f3fb {
    background-position: -800px -550px;
}
.joypixels-24-diversity._1f90c-1f3fc {
    background-position: -800px -575px;
}
.joypixels-24-diversity._1f90c-1f3fd {
    background-position: -800px -600px;
}
.joypixels-24-diversity._1f90c-1f3fe {
    background-position: -800px -625px;
}
.joypixels-24-diversity._1f90c-1f3ff {
    background-position: -800px -650px;
}
.joypixels-24-diversity._1f90f-1f3fb {
    background-position: -800px -675px;
}
.joypixels-24-diversity._1f90f-1f3fc {
    background-position: -800px -700px;
}
.joypixels-24-diversity._1f90f-1f3fd {
    background-position: -800px -725px;
}
.joypixels-24-diversity._1f90f-1f3fe {
    background-position: -800px -750px;
}
.joypixels-24-diversity._1f90f-1f3ff {
    background-position: -800px -775px;
}
.joypixels-24-diversity._1f918-1f3fb {
    background-position: 0px -800px;
}
.joypixels-24-diversity._1f918-1f3fc {
    background-position: -25px -800px;
}
.joypixels-24-diversity._1f918-1f3fd {
    background-position: -50px -800px;
}
.joypixels-24-diversity._1f918-1f3fe {
    background-position: -75px -800px;
}
.joypixels-24-diversity._1f918-1f3ff {
    background-position: -100px -800px;
}
.joypixels-24-diversity._1f919-1f3fb {
    background-position: -125px -800px;
}
.joypixels-24-diversity._1f919-1f3fc {
    background-position: -150px -800px;
}
.joypixels-24-diversity._1f919-1f3fd {
    background-position: -175px -800px;
}
.joypixels-24-diversity._1f919-1f3fe {
    background-position: -200px -800px;
}
.joypixels-24-diversity._1f919-1f3ff {
    background-position: -225px -800px;
}
.joypixels-24-diversity._1f91a-1f3fb {
    background-position: -250px -800px;
}
.joypixels-24-diversity._1f91a-1f3fc {
    background-position: -275px -800px;
}
.joypixels-24-diversity._1f91a-1f3fd {
    background-position: -300px -800px;
}
.joypixels-24-diversity._1f91a-1f3fe {
    background-position: -325px -800px;
}
.joypixels-24-diversity._1f91a-1f3ff {
    background-position: -350px -800px;
}
.joypixels-24-diversity._1f91b-1f3fb {
    background-position: -375px -800px;
}
.joypixels-24-diversity._1f91b-1f3fc {
    background-position: -400px -800px;
}
.joypixels-24-diversity._1f91b-1f3fd {
    background-position: -425px -800px;
}
.joypixels-24-diversity._1f91b-1f3fe {
    background-position: -450px -800px;
}
.joypixels-24-diversity._1f91b-1f3ff {
    background-position: -475px -800px;
}
.joypixels-24-diversity._1f91c-1f3fb {
    background-position: -500px -800px;
}
.joypixels-24-diversity._1f91c-1f3fc {
    background-position: -525px -800px;
}
.joypixels-24-diversity._1f91c-1f3fd {
    background-position: -550px -800px;
}
.joypixels-24-diversity._1f91c-1f3fe {
    background-position: -575px -800px;
}
.joypixels-24-diversity._1f91c-1f3ff {
    background-position: -600px -800px;
}
.joypixels-24-diversity._1f91d-1f3fb {
    background-position: -625px -800px;
}
.joypixels-24-diversity._1f91d-1f3fc {
    background-position: -650px -800px;
}
.joypixels-24-diversity._1f91d-1f3fd {
    background-position: -675px -800px;
}
.joypixels-24-diversity._1f91d-1f3fe {
    background-position: -700px -800px;
}
.joypixels-24-diversity._1f91d-1f3ff {
    background-position: -725px -800px;
}
.joypixels-24-diversity._1f91e-1f3fb {
    background-position: -750px -800px;
}
.joypixels-24-diversity._1f91e-1f3fc {
    background-position: -775px -800px;
}
.joypixels-24-diversity._1f91e-1f3fd {
    background-position: -800px -800px;
}
.joypixels-24-diversity._1f91e-1f3fe {
    background-position: -825px 0px;
}
.joypixels-24-diversity._1f91e-1f3ff {
    background-position: -825px -25px;
}
.joypixels-24-diversity._1f91f-1f3fb {
    background-position: -825px -50px;
}
.joypixels-24-diversity._1f91f-1f3fc {
    background-position: -825px -75px;
}
.joypixels-24-diversity._1f91f-1f3fd {
    background-position: -825px -100px;
}
.joypixels-24-diversity._1f91f-1f3fe {
    background-position: -825px -125px;
}
.joypixels-24-diversity._1f91f-1f3ff {
    background-position: -825px -150px;
}
.joypixels-24-diversity._1f926-1f3fb-2640 {
    background-position: -825px -175px;
}
.joypixels-24-diversity._1f926-1f3fb-2642 {
    background-position: -825px -200px;
}
.joypixels-24-diversity._1f926-1f3fb {
    background-position: -825px -225px;
}
.joypixels-24-diversity._1f926-1f3fc-2640 {
    background-position: -825px -250px;
}
.joypixels-24-diversity._1f926-1f3fc-2642 {
    background-position: -825px -275px;
}
.joypixels-24-diversity._1f926-1f3fc {
    background-position: -825px -300px;
}
.joypixels-24-diversity._1f926-1f3fd-2640 {
    background-position: -825px -325px;
}
.joypixels-24-diversity._1f926-1f3fd-2642 {
    background-position: -825px -350px;
}
.joypixels-24-diversity._1f926-1f3fd {
    background-position: -825px -375px;
}
.joypixels-24-diversity._1f926-1f3fe-2640 {
    background-position: -825px -400px;
}
.joypixels-24-diversity._1f926-1f3fe-2642 {
    background-position: -825px -425px;
}
.joypixels-24-diversity._1f926-1f3fe {
    background-position: -825px -450px;
}
.joypixels-24-diversity._1f926-1f3ff-2640 {
    background-position: -825px -475px;
}
.joypixels-24-diversity._1f926-1f3ff-2642 {
    background-position: -825px -500px;
}
.joypixels-24-diversity._1f926-1f3ff {
    background-position: -825px -525px;
}
.joypixels-24-diversity._1f930-1f3fb {
    background-position: -825px -550px;
}
.joypixels-24-diversity._1f930-1f3fc {
    background-position: -825px -575px;
}
.joypixels-24-diversity._1f930-1f3fd {
    background-position: -825px -600px;
}
.joypixels-24-diversity._1f930-1f3fe {
    background-position: -825px -625px;
}
.joypixels-24-diversity._1f930-1f3ff {
    background-position: -825px -650px;
}
.joypixels-24-diversity._1f931-1f3fb {
    background-position: -825px -675px;
}
.joypixels-24-diversity._1f931-1f3fc {
    background-position: -825px -700px;
}
.joypixels-24-diversity._1f931-1f3fd {
    background-position: -825px -725px;
}
.joypixels-24-diversity._1f931-1f3fe {
    background-position: -825px -750px;
}
.joypixels-24-diversity._1f931-1f3ff {
    background-position: -825px -775px;
}
.joypixels-24-diversity._1f932-1f3fb {
    background-position: -825px -800px;
}
.joypixels-24-diversity._1f932-1f3fc {
    background-position: 0px -825px;
}
.joypixels-24-diversity._1f932-1f3fd {
    background-position: -25px -825px;
}
.joypixels-24-diversity._1f932-1f3fe {
    background-position: -50px -825px;
}
.joypixels-24-diversity._1f932-1f3ff {
    background-position: -75px -825px;
}
.joypixels-24-diversity._1f933-1f3fb {
    background-position: -100px -825px;
}
.joypixels-24-diversity._1f933-1f3fc {
    background-position: -125px -825px;
}
.joypixels-24-diversity._1f933-1f3fd {
    background-position: -150px -825px;
}
.joypixels-24-diversity._1f933-1f3fe {
    background-position: -175px -825px;
}
.joypixels-24-diversity._1f933-1f3ff {
    background-position: -200px -825px;
}
.joypixels-24-diversity._1f934-1f3fb {
    background-position: -225px -825px;
}
.joypixels-24-diversity._1f934-1f3fc {
    background-position: -250px -825px;
}
.joypixels-24-diversity._1f934-1f3fd {
    background-position: -275px -825px;
}
.joypixels-24-diversity._1f934-1f3fe {
    background-position: -300px -825px;
}
.joypixels-24-diversity._1f934-1f3ff {
    background-position: -325px -825px;
}
.joypixels-24-diversity._1f935-1f3fb-2640 {
    background-position: -350px -825px;
}
.joypixels-24-diversity._1f935-1f3fb-2642 {
    background-position: -375px -825px;
}
.joypixels-24-diversity._1f935-1f3fb {
    background-position: -400px -825px;
}
.joypixels-24-diversity._1f935-1f3fc-2640 {
    background-position: -425px -825px;
}
.joypixels-24-diversity._1f935-1f3fc-2642 {
    background-position: -450px -825px;
}
.joypixels-24-diversity._1f935-1f3fc {
    background-position: -475px -825px;
}
.joypixels-24-diversity._1f935-1f3fd-2640 {
    background-position: -500px -825px;
}
.joypixels-24-diversity._1f935-1f3fd-2642 {
    background-position: -525px -825px;
}
.joypixels-24-diversity._1f935-1f3fd {
    background-position: -550px -825px;
}
.joypixels-24-diversity._1f935-1f3fe-2640 {
    background-position: -575px -825px;
}
.joypixels-24-diversity._1f935-1f3fe-2642 {
    background-position: -600px -825px;
}
.joypixels-24-diversity._1f935-1f3fe {
    background-position: -625px -825px;
}
.joypixels-24-diversity._1f935-1f3ff-2640 {
    background-position: -650px -825px;
}
.joypixels-24-diversity._1f935-1f3ff-2642 {
    background-position: -675px -825px;
}
.joypixels-24-diversity._1f935-1f3ff {
    background-position: -700px -825px;
}
.joypixels-24-diversity._1f936-1f3fb {
    background-position: -725px -825px;
}
.joypixels-24-diversity._1f936-1f3fc {
    background-position: -750px -825px;
}
.joypixels-24-diversity._1f936-1f3fd {
    background-position: -775px -825px;
}
.joypixels-24-diversity._1f936-1f3fe {
    background-position: -800px -825px;
}
.joypixels-24-diversity._1f936-1f3ff {
    background-position: -825px -825px;
}
.joypixels-24-diversity._1f937-1f3fb-2640 {
    background-position: -850px 0px;
}
.joypixels-24-diversity._1f937-1f3fb-2642 {
    background-position: -850px -25px;
}
.joypixels-24-diversity._1f937-1f3fb {
    background-position: -850px -50px;
}
.joypixels-24-diversity._1f937-1f3fc-2640 {
    background-position: -850px -75px;
}
.joypixels-24-diversity._1f937-1f3fc-2642 {
    background-position: -850px -100px;
}
.joypixels-24-diversity._1f937-1f3fc {
    background-position: -850px -125px;
}
.joypixels-24-diversity._1f937-1f3fd-2640 {
    background-position: -850px -150px;
}
.joypixels-24-diversity._1f937-1f3fd-2642 {
    background-position: -850px -175px;
}
.joypixels-24-diversity._1f937-1f3fd {
    background-position: -850px -200px;
}
.joypixels-24-diversity._1f937-1f3fe-2640 {
    background-position: -850px -225px;
}
.joypixels-24-diversity._1f937-1f3fe-2642 {
    background-position: -850px -250px;
}
.joypixels-24-diversity._1f937-1f3fe {
    background-position: -850px -275px;
}
.joypixels-24-diversity._1f937-1f3ff-2640 {
    background-position: -850px -300px;
}
.joypixels-24-diversity._1f937-1f3ff-2642 {
    background-position: -850px -325px;
}
.joypixels-24-diversity._1f937-1f3ff {
    background-position: -850px -350px;
}
.joypixels-24-diversity._1f938-1f3fb-2640 {
    background-position: -850px -375px;
}
.joypixels-24-diversity._1f938-1f3fb-2642 {
    background-position: -850px -400px;
}
.joypixels-24-diversity._1f938-1f3fb {
    background-position: -850px -425px;
}
.joypixels-24-diversity._1f938-1f3fc-2640 {
    background-position: -850px -450px;
}
.joypixels-24-diversity._1f938-1f3fc-2642 {
    background-position: -850px -475px;
}
.joypixels-24-diversity._1f938-1f3fc {
    background-position: -850px -500px;
}
.joypixels-24-diversity._1f938-1f3fd-2640 {
    background-position: -850px -525px;
}
.joypixels-24-diversity._1f938-1f3fd-2642 {
    background-position: -850px -550px;
}
.joypixels-24-diversity._1f938-1f3fd {
    background-position: -850px -575px;
}
.joypixels-24-diversity._1f938-1f3fe-2640 {
    background-position: -850px -600px;
}
.joypixels-24-diversity._1f938-1f3fe-2642 {
    background-position: -850px -625px;
}
.joypixels-24-diversity._1f938-1f3fe {
    background-position: -850px -650px;
}
.joypixels-24-diversity._1f938-1f3ff-2640 {
    background-position: -850px -675px;
}
.joypixels-24-diversity._1f938-1f3ff-2642 {
    background-position: -850px -700px;
}
.joypixels-24-diversity._1f938-1f3ff {
    background-position: -850px -725px;
}
.joypixels-24-diversity._1f939-1f3fb-2640 {
    background-position: -850px -750px;
}
.joypixels-24-diversity._1f939-1f3fb-2642 {
    background-position: -850px -775px;
}
.joypixels-24-diversity._1f939-1f3fb {
    background-position: -850px -800px;
}
.joypixels-24-diversity._1f939-1f3fc-2640 {
    background-position: -850px -825px;
}
.joypixels-24-diversity._1f939-1f3fc-2642 {
    background-position: 0px -850px;
}
.joypixels-24-diversity._1f939-1f3fc {
    background-position: -25px -850px;
}
.joypixels-24-diversity._1f939-1f3fd-2640 {
    background-position: -50px -850px;
}
.joypixels-24-diversity._1f939-1f3fd-2642 {
    background-position: -75px -850px;
}
.joypixels-24-diversity._1f939-1f3fd {
    background-position: -100px -850px;
}
.joypixels-24-diversity._1f939-1f3fe-2640 {
    background-position: -125px -850px;
}
.joypixels-24-diversity._1f939-1f3fe-2642 {
    background-position: -150px -850px;
}
.joypixels-24-diversity._1f939-1f3fe {
    background-position: -175px -850px;
}
.joypixels-24-diversity._1f939-1f3ff-2640 {
    background-position: -200px -850px;
}
.joypixels-24-diversity._1f939-1f3ff-2642 {
    background-position: -225px -850px;
}
.joypixels-24-diversity._1f939-1f3ff {
    background-position: -250px -850px;
}
.joypixels-24-diversity._1f93d-1f3fb-2640 {
    background-position: -275px -850px;
}
.joypixels-24-diversity._1f93d-1f3fb-2642 {
    background-position: -300px -850px;
}
.joypixels-24-diversity._1f93d-1f3fb {
    background-position: -325px -850px;
}
.joypixels-24-diversity._1f93d-1f3fc-2640 {
    background-position: -350px -850px;
}
.joypixels-24-diversity._1f93d-1f3fc-2642 {
    background-position: -375px -850px;
}
.joypixels-24-diversity._1f93d-1f3fc {
    background-position: -400px -850px;
}
.joypixels-24-diversity._1f93d-1f3fd-2640 {
    background-position: -425px -850px;
}
.joypixels-24-diversity._1f93d-1f3fd-2642 {
    background-position: -450px -850px;
}
.joypixels-24-diversity._1f93d-1f3fd {
    background-position: -475px -850px;
}
.joypixels-24-diversity._1f93d-1f3fe-2640 {
    background-position: -500px -850px;
}
.joypixels-24-diversity._1f93d-1f3fe-2642 {
    background-position: -525px -850px;
}
.joypixels-24-diversity._1f93d-1f3fe {
    background-position: -550px -850px;
}
.joypixels-24-diversity._1f93d-1f3ff-2640 {
    background-position: -575px -850px;
}
.joypixels-24-diversity._1f93d-1f3ff-2642 {
    background-position: -600px -850px;
}
.joypixels-24-diversity._1f93d-1f3ff {
    background-position: -625px -850px;
}
.joypixels-24-diversity._1f93e-1f3fb-2640 {
    background-position: -650px -850px;
}
.joypixels-24-diversity._1f93e-1f3fb-2642 {
    background-position: -675px -850px;
}
.joypixels-24-diversity._1f93e-1f3fb {
    background-position: -700px -850px;
}
.joypixels-24-diversity._1f93e-1f3fc-2640 {
    background-position: -725px -850px;
}
.joypixels-24-diversity._1f93e-1f3fc-2642 {
    background-position: -750px -850px;
}
.joypixels-24-diversity._1f93e-1f3fc {
    background-position: -775px -850px;
}
.joypixels-24-diversity._1f93e-1f3fd-2640 {
    background-position: -800px -850px;
}
.joypixels-24-diversity._1f93e-1f3fd-2642 {
    background-position: -825px -850px;
}
.joypixels-24-diversity._1f93e-1f3fd {
    background-position: -850px -850px;
}
.joypixels-24-diversity._1f93e-1f3fe-2640 {
    background-position: -875px 0px;
}
.joypixels-24-diversity._1f93e-1f3fe-2642 {
    background-position: -875px -25px;
}
.joypixels-24-diversity._1f93e-1f3fe {
    background-position: -875px -50px;
}
.joypixels-24-diversity._1f93e-1f3ff-2640 {
    background-position: -875px -75px;
}
.joypixels-24-diversity._1f93e-1f3ff-2642 {
    background-position: -875px -100px;
}
.joypixels-24-diversity._1f93e-1f3ff {
    background-position: -875px -125px;
}
.joypixels-24-diversity._1f977-1f3fb {
    background-position: -875px -150px;
}
.joypixels-24-diversity._1f977-1f3fc {
    background-position: -875px -175px;
}
.joypixels-24-diversity._1f977-1f3fd {
    background-position: -875px -200px;
}
.joypixels-24-diversity._1f977-1f3fe {
    background-position: -875px -225px;
}
.joypixels-24-diversity._1f977-1f3ff {
    background-position: -875px -250px;
}
.joypixels-24-diversity._1f9b5-1f3fb {
    background-position: -875px -275px;
}
.joypixels-24-diversity._1f9b5-1f3fc {
    background-position: -875px -300px;
}
.joypixels-24-diversity._1f9b5-1f3fd {
    background-position: -875px -325px;
}
.joypixels-24-diversity._1f9b5-1f3fe {
    background-position: -875px -350px;
}
.joypixels-24-diversity._1f9b5-1f3ff {
    background-position: -875px -375px;
}
.joypixels-24-diversity._1f9b6-1f3fb {
    background-position: -875px -400px;
}
.joypixels-24-diversity._1f9b6-1f3fc {
    background-position: -875px -425px;
}
.joypixels-24-diversity._1f9b6-1f3fd {
    background-position: -875px -450px;
}
.joypixels-24-diversity._1f9b6-1f3fe {
    background-position: -875px -475px;
}
.joypixels-24-diversity._1f9b6-1f3ff {
    background-position: -875px -500px;
}
.joypixels-24-diversity._1f9b8-1f3fb-2640 {
    background-position: -875px -525px;
}
.joypixels-24-diversity._1f9b8-1f3fb-2642 {
    background-position: -875px -550px;
}
.joypixels-24-diversity._1f9b8-1f3fb {
    background-position: -875px -575px;
}
.joypixels-24-diversity._1f9b8-1f3fc-2640 {
    background-position: -875px -600px;
}
.joypixels-24-diversity._1f9b8-1f3fc-2642 {
    background-position: -875px -625px;
}
.joypixels-24-diversity._1f9b8-1f3fc {
    background-position: -875px -650px;
}
.joypixels-24-diversity._1f9b8-1f3fd-2640 {
    background-position: -875px -675px;
}
.joypixels-24-diversity._1f9b8-1f3fd-2642 {
    background-position: -875px -700px;
}
.joypixels-24-diversity._1f9b8-1f3fd {
    background-position: -875px -725px;
}
.joypixels-24-diversity._1f9b8-1f3fe-2640 {
    background-position: -875px -750px;
}
.joypixels-24-diversity._1f9b8-1f3fe-2642 {
    background-position: -875px -775px;
}
.joypixels-24-diversity._1f9b8-1f3fe {
    background-position: -875px -800px;
}
.joypixels-24-diversity._1f9b8-1f3ff-2640 {
    background-position: -875px -825px;
}
.joypixels-24-diversity._1f9b8-1f3ff-2642 {
    background-position: -875px -850px;
}
.joypixels-24-diversity._1f9b8-1f3ff {
    background-position: 0px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fb-2640 {
    background-position: -25px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fb-2642 {
    background-position: -50px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fb {
    background-position: -75px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fc-2640 {
    background-position: -100px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fc-2642 {
    background-position: -125px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fc {
    background-position: -150px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fd-2640 {
    background-position: -175px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fd-2642 {
    background-position: -200px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fd {
    background-position: -225px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fe-2640 {
    background-position: -250px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fe-2642 {
    background-position: -275px -875px;
}
.joypixels-24-diversity._1f9b9-1f3fe {
    background-position: -300px -875px;
}
.joypixels-24-diversity._1f9b9-1f3ff-2640 {
    background-position: -325px -875px;
}
.joypixels-24-diversity._1f9b9-1f3ff-2642 {
    background-position: -350px -875px;
}
.joypixels-24-diversity._1f9b9-1f3ff {
    background-position: -375px -875px;
}
.joypixels-24-diversity._1f9bb-1f3fb {
    background-position: -400px -875px;
}
.joypixels-24-diversity._1f9bb-1f3fc {
    background-position: -425px -875px;
}
.joypixels-24-diversity._1f9bb-1f3fd {
    background-position: -450px -875px;
}
.joypixels-24-diversity._1f9bb-1f3fe {
    background-position: -475px -875px;
}
.joypixels-24-diversity._1f9bb-1f3ff {
    background-position: -500px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fb-2640 {
    background-position: -525px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fb-2642 {
    background-position: -550px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fb {
    background-position: -575px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fc-2640 {
    background-position: -600px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fc-2642 {
    background-position: -625px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fc {
    background-position: -650px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fd-2640 {
    background-position: -675px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fd-2642 {
    background-position: -700px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fd {
    background-position: -725px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fe-2640 {
    background-position: -750px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fe-2642 {
    background-position: -775px -875px;
}
.joypixels-24-diversity._1f9cd-1f3fe {
    background-position: -800px -875px;
}
.joypixels-24-diversity._1f9cd-1f3ff-2640 {
    background-position: -825px -875px;
}
.joypixels-24-diversity._1f9cd-1f3ff-2642 {
    background-position: -850px -875px;
}
.joypixels-24-diversity._1f9cd-1f3ff {
    background-position: -875px -875px;
}
.joypixels-24-diversity._1f9ce-1f3fb-2640 {
    background-position: -900px 0px;
}
.joypixels-24-diversity._1f9ce-1f3fb-2642 {
    background-position: -900px -25px;
}
.joypixels-24-diversity._1f9ce-1f3fb {
    background-position: -900px -50px;
}
.joypixels-24-diversity._1f9ce-1f3fc-2640 {
    background-position: -900px -75px;
}
.joypixels-24-diversity._1f9ce-1f3fc-2642 {
    background-position: -900px -100px;
}
.joypixels-24-diversity._1f9ce-1f3fc {
    background-position: -900px -125px;
}
.joypixels-24-diversity._1f9ce-1f3fd-2640 {
    background-position: -900px -150px;
}
.joypixels-24-diversity._1f9ce-1f3fd-2642 {
    background-position: -900px -175px;
}
.joypixels-24-diversity._1f9ce-1f3fd {
    background-position: -900px -200px;
}
.joypixels-24-diversity._1f9ce-1f3fe-2640 {
    background-position: -900px -225px;
}
.joypixels-24-diversity._1f9ce-1f3fe-2642 {
    background-position: -900px -250px;
}
.joypixels-24-diversity._1f9ce-1f3fe {
    background-position: -900px -275px;
}
.joypixels-24-diversity._1f9ce-1f3ff-2640 {
    background-position: -900px -300px;
}
.joypixels-24-diversity._1f9ce-1f3ff-2642 {
    background-position: -900px -325px;
}
.joypixels-24-diversity._1f9ce-1f3ff {
    background-position: -900px -350px;
}
.joypixels-24-diversity._1f9cf-1f3fb-2640 {
    background-position: -900px -375px;
}
.joypixels-24-diversity._1f9cf-1f3fb-2642 {
    background-position: -900px -400px;
}
.joypixels-24-diversity._1f9cf-1f3fb {
    background-position: -900px -425px;
}
.joypixels-24-diversity._1f9cf-1f3fc-2640 {
    background-position: -900px -450px;
}
.joypixels-24-diversity._1f9cf-1f3fc-2642 {
    background-position: -900px -475px;
}
.joypixels-24-diversity._1f9cf-1f3fc {
    background-position: -900px -500px;
}
.joypixels-24-diversity._1f9cf-1f3fd-2640 {
    background-position: -900px -525px;
}
.joypixels-24-diversity._1f9cf-1f3fd-2642 {
    background-position: -900px -550px;
}
.joypixels-24-diversity._1f9cf-1f3fd {
    background-position: -900px -575px;
}
.joypixels-24-diversity._1f9cf-1f3fe-2640 {
    background-position: -900px -600px;
}
.joypixels-24-diversity._1f9cf-1f3fe-2642 {
    background-position: -900px -625px;
}
.joypixels-24-diversity._1f9cf-1f3fe {
    background-position: -900px -650px;
}
.joypixels-24-diversity._1f9cf-1f3ff-2640 {
    background-position: -900px -675px;
}
.joypixels-24-diversity._1f9cf-1f3ff-2642 {
    background-position: -900px -700px;
}
.joypixels-24-diversity._1f9cf-1f3ff {
    background-position: -900px -725px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f33e {
    background-position: -900px -750px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f373 {
    background-position: -900px -775px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f37c {
    background-position: -900px -800px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f384 {
    background-position: -900px -825px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f393 {
    background-position: -900px -850px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f3a4 {
    background-position: -900px -875px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f3a8 {
    background-position: 0px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f3eb {
    background-position: -25px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f3ed {
    background-position: -50px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f4bb {
    background-position: -75px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f4bc {
    background-position: -100px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f527 {
    background-position: -125px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f52c {
    background-position: -150px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f680 {
    background-position: -175px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f692 {
    background-position: -200px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f91d-1f9d1-1f3fb {
    background-position: -225px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f91d-1f9d1-1f3fc {
    background-position: -250px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f91d-1f9d1-1f3fd {
    background-position: -275px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f91d-1f9d1-1f3fe {
    background-position: -300px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f91d-1f9d1-1f3ff {
    background-position: -325px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9af {
    background-position: -350px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9b0 {
    background-position: -375px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9b1 {
    background-position: -400px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9b2 {
    background-position: -425px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9b3 {
    background-position: -450px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9bc {
    background-position: -475px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-1f9bd {
    background-position: -500px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2695 {
    background-position: -525px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2696 {
    background-position: -550px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2708 {
    background-position: -575px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fc {
    background-position: -600px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fd {
    background-position: -625px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fe {
    background-position: -650px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f48b-1f9d1-1f3ff {
    background-position: -675px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f9d1-1f3fc {
    background-position: -700px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f9d1-1f3fd {
    background-position: -725px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f9d1-1f3fe {
    background-position: -750px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb-2764-1f9d1-1f3ff {
    background-position: -775px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fb {
    background-position: -800px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f33e {
    background-position: -825px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f373 {
    background-position: -850px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f37c {
    background-position: -875px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f384 {
    background-position: -900px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f393 {
    background-position: -925px 0px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f3a4 {
    background-position: -925px -25px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f3a8 {
    background-position: -925px -50px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f3eb {
    background-position: -925px -75px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f3ed {
    background-position: -925px -100px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f4bb {
    background-position: -925px -125px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f4bc {
    background-position: -925px -150px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f527 {
    background-position: -925px -175px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f52c {
    background-position: -925px -200px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f680 {
    background-position: -925px -225px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f692 {
    background-position: -925px -250px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f91d-1f9d1-1f3fb {
    background-position: -925px -275px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f91d-1f9d1-1f3fc {
    background-position: -925px -300px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f91d-1f9d1-1f3fd {
    background-position: -925px -325px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f91d-1f9d1-1f3fe {
    background-position: -925px -350px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f91d-1f9d1-1f3ff {
    background-position: -925px -375px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9af {
    background-position: -925px -400px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9b0 {
    background-position: -925px -425px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9b1 {
    background-position: -925px -450px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9b2 {
    background-position: -925px -475px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9b3 {
    background-position: -925px -500px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9bc {
    background-position: -925px -525px;
}
.joypixels-24-diversity._1f9d1-1f3fc-1f9bd {
    background-position: -925px -550px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2695 {
    background-position: -925px -575px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2696 {
    background-position: -925px -600px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2708 {
    background-position: -925px -625px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fb {
    background-position: -925px -650px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fd {
    background-position: -925px -675px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fe {
    background-position: -925px -700px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f48b-1f9d1-1f3ff {
    background-position: -925px -725px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f9d1-1f3fb {
    background-position: -925px -750px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f9d1-1f3fd {
    background-position: -925px -775px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f9d1-1f3fe {
    background-position: -925px -800px;
}
.joypixels-24-diversity._1f9d1-1f3fc-2764-1f9d1-1f3ff {
    background-position: -925px -825px;
}
.joypixels-24-diversity._1f9d1-1f3fc {
    background-position: -925px -850px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f33e {
    background-position: -925px -875px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f373 {
    background-position: -925px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f37c {
    background-position: 0px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f384 {
    background-position: -25px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f393 {
    background-position: -50px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f3a4 {
    background-position: -75px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f3a8 {
    background-position: -100px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f3eb {
    background-position: -125px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f3ed {
    background-position: -150px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f4bb {
    background-position: -175px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f4bc {
    background-position: -200px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f527 {
    background-position: -225px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f52c {
    background-position: -250px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f680 {
    background-position: -275px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f692 {
    background-position: -300px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f91d-1f9d1-1f3fb {
    background-position: -325px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f91d-1f9d1-1f3fc {
    background-position: -350px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f91d-1f9d1-1f3fd {
    background-position: -375px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f91d-1f9d1-1f3fe {
    background-position: -400px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f91d-1f9d1-1f3ff {
    background-position: -425px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9af {
    background-position: -450px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9b0 {
    background-position: -475px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9b1 {
    background-position: -500px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9b2 {
    background-position: -525px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9b3 {
    background-position: -550px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9bc {
    background-position: -575px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-1f9bd {
    background-position: -600px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2695 {
    background-position: -625px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2696 {
    background-position: -650px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2708 {
    background-position: -675px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fb {
    background-position: -700px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fc {
    background-position: -725px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fe {
    background-position: -750px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f48b-1f9d1-1f3ff {
    background-position: -775px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f9d1-1f3fb {
    background-position: -800px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f9d1-1f3fc {
    background-position: -825px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f9d1-1f3fe {
    background-position: -850px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd-2764-1f9d1-1f3ff {
    background-position: -875px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fd {
    background-position: -900px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f33e {
    background-position: -925px -925px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f373 {
    background-position: -950px 0px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f37c {
    background-position: -950px -25px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f384 {
    background-position: -950px -50px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f393 {
    background-position: -950px -75px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f3a4 {
    background-position: -950px -100px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f3a8 {
    background-position: -950px -125px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f3eb {
    background-position: -950px -150px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f3ed {
    background-position: -950px -175px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f4bb {
    background-position: -950px -200px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f4bc {
    background-position: -950px -225px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f527 {
    background-position: -950px -250px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f52c {
    background-position: -950px -275px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f680 {
    background-position: -950px -300px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f692 {
    background-position: -950px -325px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f91d-1f9d1-1f3fb {
    background-position: -950px -350px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f91d-1f9d1-1f3fc {
    background-position: -950px -375px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f91d-1f9d1-1f3fd {
    background-position: -950px -400px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f91d-1f9d1-1f3fe {
    background-position: -950px -425px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f91d-1f9d1-1f3ff {
    background-position: -950px -450px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9af {
    background-position: -950px -475px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9b0 {
    background-position: -950px -500px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9b1 {
    background-position: -950px -525px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9b2 {
    background-position: -950px -550px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9b3 {
    background-position: -950px -575px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9bc {
    background-position: -950px -600px;
}
.joypixels-24-diversity._1f9d1-1f3fe-1f9bd {
    background-position: -950px -625px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2695 {
    background-position: -950px -650px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2696 {
    background-position: -950px -675px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2708 {
    background-position: -950px -700px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fb {
    background-position: -950px -725px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fc {
    background-position: -950px -750px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fd {
    background-position: -950px -775px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f48b-1f9d1-1f3ff {
    background-position: -950px -800px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f9d1-1f3fb {
    background-position: -950px -825px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f9d1-1f3fc {
    background-position: -950px -850px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f9d1-1f3fd {
    background-position: -950px -875px;
}
.joypixels-24-diversity._1f9d1-1f3fe-2764-1f9d1-1f3ff {
    background-position: -950px -900px;
}
.joypixels-24-diversity._1f9d1-1f3fe {
    background-position: -950px -925px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f33e {
    background-position: 0px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f373 {
    background-position: -25px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f37c {
    background-position: -50px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f384 {
    background-position: -75px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f393 {
    background-position: -100px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f3a4 {
    background-position: -125px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f3a8 {
    background-position: -150px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f3eb {
    background-position: -175px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f3ed {
    background-position: -200px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f4bb {
    background-position: -225px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f4bc {
    background-position: -250px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f527 {
    background-position: -275px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f52c {
    background-position: -300px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f680 {
    background-position: -325px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f692 {
    background-position: -350px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f91d-1f9d1-1f3fb {
    background-position: -375px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f91d-1f9d1-1f3fc {
    background-position: -400px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f91d-1f9d1-1f3fd {
    background-position: -425px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f91d-1f9d1-1f3fe {
    background-position: -450px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f91d-1f9d1-1f3ff {
    background-position: -475px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9af {
    background-position: -500px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9b0 {
    background-position: -525px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9b1 {
    background-position: -550px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9b2 {
    background-position: -575px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9b3 {
    background-position: -600px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9bc {
    background-position: -625px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-1f9bd {
    background-position: -650px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2695 {
    background-position: -675px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2696 {
    background-position: -700px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2708 {
    background-position: -725px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fb {
    background-position: -750px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fc {
    background-position: -775px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fd {
    background-position: -800px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fe {
    background-position: -825px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f9d1-1f3fb {
    background-position: -850px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f9d1-1f3fc {
    background-position: -875px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f9d1-1f3fd {
    background-position: -900px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff-2764-1f9d1-1f3fe {
    background-position: -925px -950px;
}
.joypixels-24-diversity._1f9d1-1f3ff {
    background-position: -950px -950px;
}
.joypixels-24-diversity._1f9d2-1f3fb {
    background-position: -975px 0px;
}
.joypixels-24-diversity._1f9d2-1f3fc {
    background-position: -975px -25px;
}
.joypixels-24-diversity._1f9d2-1f3fd {
    background-position: -975px -50px;
}
.joypixels-24-diversity._1f9d2-1f3fe {
    background-position: -975px -75px;
}
.joypixels-24-diversity._1f9d2-1f3ff {
    background-position: -975px -100px;
}
.joypixels-24-diversity._1f9d3-1f3fb {
    background-position: -975px -125px;
}
.joypixels-24-diversity._1f9d3-1f3fc {
    background-position: -975px -150px;
}
.joypixels-24-diversity._1f9d3-1f3fd {
    background-position: -975px -175px;
}
.joypixels-24-diversity._1f9d3-1f3fe {
    background-position: -975px -200px;
}
.joypixels-24-diversity._1f9d3-1f3ff {
    background-position: -975px -225px;
}
.joypixels-24-diversity._1f9d4-1f3fb-2640 {
    background-position: -975px -250px;
}
.joypixels-24-diversity._1f9d4-1f3fb-2642 {
    background-position: -975px -275px;
}
.joypixels-24-diversity._1f9d4-1f3fb {
    background-position: -975px -300px;
}
.joypixels-24-diversity._1f9d4-1f3fc-2640 {
    background-position: -975px -325px;
}
.joypixels-24-diversity._1f9d4-1f3fc-2642 {
    background-position: -975px -350px;
}
.joypixels-24-diversity._1f9d4-1f3fc {
    background-position: -975px -375px;
}
.joypixels-24-diversity._1f9d4-1f3fd-2640 {
    background-position: -975px -400px;
}
.joypixels-24-diversity._1f9d4-1f3fd-2642 {
    background-position: -975px -425px;
}
.joypixels-24-diversity._1f9d4-1f3fd {
    background-position: -975px -450px;
}
.joypixels-24-diversity._1f9d4-1f3fe-2640 {
    background-position: -975px -475px;
}
.joypixels-24-diversity._1f9d4-1f3fe-2642 {
    background-position: -975px -500px;
}
.joypixels-24-diversity._1f9d4-1f3fe {
    background-position: -975px -525px;
}
.joypixels-24-diversity._1f9d4-1f3ff-2640 {
    background-position: -975px -550px;
}
.joypixels-24-diversity._1f9d4-1f3ff-2642 {
    background-position: -975px -575px;
}
.joypixels-24-diversity._1f9d4-1f3ff {
    background-position: -975px -600px;
}
.joypixels-24-diversity._1f9d5-1f3fb {
    background-position: -975px -625px;
}
.joypixels-24-diversity._1f9d5-1f3fc {
    background-position: -975px -650px;
}
.joypixels-24-diversity._1f9d5-1f3fd {
    background-position: -975px -675px;
}
.joypixels-24-diversity._1f9d5-1f3fe {
    background-position: -975px -700px;
}
.joypixels-24-diversity._1f9d5-1f3ff {
    background-position: -975px -725px;
}
.joypixels-24-diversity._1f9d6-1f3fb-2640 {
    background-position: -975px -750px;
}
.joypixels-24-diversity._1f9d6-1f3fb-2642 {
    background-position: -975px -775px;
}
.joypixels-24-diversity._1f9d6-1f3fb {
    background-position: -975px -800px;
}
.joypixels-24-diversity._1f9d6-1f3fc-2640 {
    background-position: -975px -825px;
}
.joypixels-24-diversity._1f9d6-1f3fc-2642 {
    background-position: -975px -850px;
}
.joypixels-24-diversity._1f9d6-1f3fc {
    background-position: -975px -875px;
}
.joypixels-24-diversity._1f9d6-1f3fd-2640 {
    background-position: -975px -900px;
}
.joypixels-24-diversity._1f9d6-1f3fd-2642 {
    background-position: -975px -925px;
}
.joypixels-24-diversity._1f9d6-1f3fd {
    background-position: -975px -950px;
}
.joypixels-24-diversity._1f9d6-1f3fe-2640 {
    background-position: 0px -975px;
}
.joypixels-24-diversity._1f9d6-1f3fe-2642 {
    background-position: -25px -975px;
}
.joypixels-24-diversity._1f9d6-1f3fe {
    background-position: -50px -975px;
}
.joypixels-24-diversity._1f9d6-1f3ff-2640 {
    background-position: -75px -975px;
}
.joypixels-24-diversity._1f9d6-1f3ff-2642 {
    background-position: -100px -975px;
}
.joypixels-24-diversity._1f9d6-1f3ff {
    background-position: -125px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fb-2640 {
    background-position: -150px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fb-2642 {
    background-position: -175px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fb {
    background-position: -200px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fc-2640 {
    background-position: -225px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fc-2642 {
    background-position: -250px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fc {
    background-position: -275px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fd-2640 {
    background-position: -300px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fd-2642 {
    background-position: -325px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fd {
    background-position: -350px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fe-2640 {
    background-position: -375px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fe-2642 {
    background-position: -400px -975px;
}
.joypixels-24-diversity._1f9d7-1f3fe {
    background-position: -425px -975px;
}
.joypixels-24-diversity._1f9d7-1f3ff-2640 {
    background-position: -450px -975px;
}
.joypixels-24-diversity._1f9d7-1f3ff-2642 {
    background-position: -475px -975px;
}
.joypixels-24-diversity._1f9d7-1f3ff {
    background-position: -500px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fb-2640 {
    background-position: -525px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fb-2642 {
    background-position: -550px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fb {
    background-position: -575px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fc-2640 {
    background-position: -600px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fc-2642 {
    background-position: -625px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fc {
    background-position: -650px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fd-2640 {
    background-position: -675px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fd-2642 {
    background-position: -700px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fd {
    background-position: -725px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fe-2640 {
    background-position: -750px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fe-2642 {
    background-position: -775px -975px;
}
.joypixels-24-diversity._1f9d8-1f3fe {
    background-position: -800px -975px;
}
.joypixels-24-diversity._1f9d8-1f3ff-2640 {
    background-position: -825px -975px;
}
.joypixels-24-diversity._1f9d8-1f3ff-2642 {
    background-position: -850px -975px;
}
.joypixels-24-diversity._1f9d8-1f3ff {
    background-position: -875px -975px;
}
.joypixels-24-diversity._1f9d9-1f3fb-2640 {
    background-position: -900px -975px;
}
.joypixels-24-diversity._1f9d9-1f3fb-2642 {
    background-position: -925px -975px;
}
.joypixels-24-diversity._1f9d9-1f3fb {
    background-position: -950px -975px;
}
.joypixels-24-diversity._1f9d9-1f3fc-2640 {
    background-position: -975px -975px;
}
.joypixels-24-diversity._1f9d9-1f3fc-2642 {
    background-position: -1000px 0px;
}
.joypixels-24-diversity._1f9d9-1f3fc {
    background-position: -1000px -25px;
}
.joypixels-24-diversity._1f9d9-1f3fd-2640 {
    background-position: -1000px -50px;
}
.joypixels-24-diversity._1f9d9-1f3fd-2642 {
    background-position: -1000px -75px;
}
.joypixels-24-diversity._1f9d9-1f3fd {
    background-position: -1000px -100px;
}
.joypixels-24-diversity._1f9d9-1f3fe-2640 {
    background-position: -1000px -125px;
}
.joypixels-24-diversity._1f9d9-1f3fe-2642 {
    background-position: -1000px -150px;
}
.joypixels-24-diversity._1f9d9-1f3fe {
    background-position: -1000px -175px;
}
.joypixels-24-diversity._1f9d9-1f3ff-2640 {
    background-position: -1000px -200px;
}
.joypixels-24-diversity._1f9d9-1f3ff-2642 {
    background-position: -1000px -225px;
}
.joypixels-24-diversity._1f9d9-1f3ff {
    background-position: -1000px -250px;
}
.joypixels-24-diversity._1f9da-1f3fb-2640 {
    background-position: -1000px -275px;
}
.joypixels-24-diversity._1f9da-1f3fb-2642 {
    background-position: -1000px -300px;
}
.joypixels-24-diversity._1f9da-1f3fb {
    background-position: -1000px -325px;
}
.joypixels-24-diversity._1f9da-1f3fc-2640 {
    background-position: -1000px -350px;
}
.joypixels-24-diversity._1f9da-1f3fc-2642 {
    background-position: -1000px -375px;
}
.joypixels-24-diversity._1f9da-1f3fc {
    background-position: -1000px -400px;
}
.joypixels-24-diversity._1f9da-1f3fd-2640 {
    background-position: -1000px -425px;
}
.joypixels-24-diversity._1f9da-1f3fd-2642 {
    background-position: -1000px -450px;
}
.joypixels-24-diversity._1f9da-1f3fd {
    background-position: -1000px -475px;
}
.joypixels-24-diversity._1f9da-1f3fe-2640 {
    background-position: -1000px -500px;
}
.joypixels-24-diversity._1f9da-1f3fe-2642 {
    background-position: -1000px -525px;
}
.joypixels-24-diversity._1f9da-1f3fe {
    background-position: -1000px -550px;
}
.joypixels-24-diversity._1f9da-1f3ff-2640 {
    background-position: -1000px -575px;
}
.joypixels-24-diversity._1f9da-1f3ff-2642 {
    background-position: -1000px -600px;
}
.joypixels-24-diversity._1f9da-1f3ff {
    background-position: -1000px -625px;
}
.joypixels-24-diversity._1f9db-1f3fb-2640 {
    background-position: -1000px -650px;
}
.joypixels-24-diversity._1f9db-1f3fb-2642 {
    background-position: -1000px -675px;
}
.joypixels-24-diversity._1f9db-1f3fb {
    background-position: -1000px -700px;
}
.joypixels-24-diversity._1f9db-1f3fc-2640 {
    background-position: -1000px -725px;
}
.joypixels-24-diversity._1f9db-1f3fc-2642 {
    background-position: -1000px -750px;
}
.joypixels-24-diversity._1f9db-1f3fc {
    background-position: -1000px -775px;
}
.joypixels-24-diversity._1f9db-1f3fd-2640 {
    background-position: -1000px -800px;
}
.joypixels-24-diversity._1f9db-1f3fd-2642 {
    background-position: -1000px -825px;
}
.joypixels-24-diversity._1f9db-1f3fd {
    background-position: -1000px -850px;
}
.joypixels-24-diversity._1f9db-1f3fe-2640 {
    background-position: -1000px -875px;
}
.joypixels-24-diversity._1f9db-1f3fe-2642 {
    background-position: -1000px -900px;
}
.joypixels-24-diversity._1f9db-1f3fe {
    background-position: -1000px -925px;
}
.joypixels-24-diversity._1f9db-1f3ff-2640 {
    background-position: -1000px -950px;
}
.joypixels-24-diversity._1f9db-1f3ff-2642 {
    background-position: -1000px -975px;
}
.joypixels-24-diversity._1f9db-1f3ff {
    background-position: 0px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fb-2640 {
    background-position: -25px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fb-2642 {
    background-position: -50px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fb {
    background-position: -75px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fc-2640 {
    background-position: -100px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fc-2642 {
    background-position: -125px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fc {
    background-position: -150px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fd-2640 {
    background-position: -175px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fd-2642 {
    background-position: -200px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fd {
    background-position: -225px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fe-2640 {
    background-position: -250px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fe-2642 {
    background-position: -275px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3fe {
    background-position: -300px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3ff-2640 {
    background-position: -325px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3ff-2642 {
    background-position: -350px -1000px;
}
.joypixels-24-diversity._1f9dc-1f3ff {
    background-position: -375px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fb-2640 {
    background-position: -400px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fb-2642 {
    background-position: -425px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fb {
    background-position: -450px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fc-2640 {
    background-position: -475px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fc-2642 {
    background-position: -500px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fc {
    background-position: -525px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fd-2640 {
    background-position: -550px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fd-2642 {
    background-position: -575px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fd {
    background-position: -600px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fe-2640 {
    background-position: -625px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fe-2642 {
    background-position: -650px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3fe {
    background-position: -675px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3ff-2640 {
    background-position: -700px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3ff-2642 {
    background-position: -725px -1000px;
}
.joypixels-24-diversity._1f9dd-1f3ff {
    background-position: -750px -1000px;
}
.joypixels-24-diversity._1fac3-1f3fb {
    background-position: -775px -1000px;
}
.joypixels-24-diversity._1fac3-1f3fc {
    background-position: -800px -1000px;
}
.joypixels-24-diversity._1fac3-1f3fd {
    background-position: -825px -1000px;
}
.joypixels-24-diversity._1fac3-1f3fe {
    background-position: -850px -1000px;
}
.joypixels-24-diversity._1fac3-1f3ff {
    background-position: -875px -1000px;
}
.joypixels-24-diversity._1fac4-1f3fb {
    background-position: -900px -1000px;
}
.joypixels-24-diversity._1fac4-1f3fc {
    background-position: -925px -1000px;
}
.joypixels-24-diversity._1fac4-1f3fd {
    background-position: -950px -1000px;
}
.joypixels-24-diversity._1fac4-1f3fe {
    background-position: -975px -1000px;
}
.joypixels-24-diversity._1fac4-1f3ff {
    background-position: -1000px -1000px;
}
.joypixels-24-diversity._1fac5-1f3fb {
    background-position: -1025px 0px;
}
.joypixels-24-diversity._1fac5-1f3fc {
    background-position: -1025px -25px;
}
.joypixels-24-diversity._1fac5-1f3fd {
    background-position: -1025px -50px;
}
.joypixels-24-diversity._1fac5-1f3fe {
    background-position: -1025px -75px;
}
.joypixels-24-diversity._1fac5-1f3ff {
    background-position: -1025px -100px;
}
.joypixels-24-diversity._1faf0-1f3fb {
    background-position: -1025px -125px;
}
.joypixels-24-diversity._1faf0-1f3fc {
    background-position: -1025px -150px;
}
.joypixels-24-diversity._1faf0-1f3fd {
    background-position: -1025px -175px;
}
.joypixels-24-diversity._1faf0-1f3fe {
    background-position: -1025px -200px;
}
.joypixels-24-diversity._1faf0-1f3ff {
    background-position: -1025px -225px;
}
.joypixels-24-diversity._1faf1-1f3fb-1faf2-1f3fc {
    background-position: -1025px -250px;
}
.joypixels-24-diversity._1faf1-1f3fb-1faf2-1f3fd {
    background-position: -1025px -275px;
}
.joypixels-24-diversity._1faf1-1f3fb-1faf2-1f3fe {
    background-position: -1025px -300px;
}
.joypixels-24-diversity._1faf1-1f3fb-1faf2-1f3ff {
    background-position: -1025px -325px;
}
.joypixels-24-diversity._1faf1-1f3fb {
    background-position: -1025px -350px;
}
.joypixels-24-diversity._1faf1-1f3fc-1faf2-1f3fb {
    background-position: -1025px -375px;
}
.joypixels-24-diversity._1faf1-1f3fc-1faf2-1f3fd {
    background-position: -1025px -400px;
}
.joypixels-24-diversity._1faf1-1f3fc-1faf2-1f3fe {
    background-position: -1025px -425px;
}
.joypixels-24-diversity._1faf1-1f3fc-1faf2-1f3ff {
    background-position: -1025px -450px;
}
.joypixels-24-diversity._1faf1-1f3fc {
    background-position: -1025px -475px;
}
.joypixels-24-diversity._1faf1-1f3fd-1faf2-1f3fb {
    background-position: -1025px -500px;
}
.joypixels-24-diversity._1faf1-1f3fd-1faf2-1f3fc {
    background-position: -1025px -525px;
}
.joypixels-24-diversity._1faf1-1f3fd-1faf2-1f3fe {
    background-position: -1025px -550px;
}
.joypixels-24-diversity._1faf1-1f3fd-1faf2-1f3ff {
    background-position: -1025px -575px;
}
.joypixels-24-diversity._1faf1-1f3fd {
    background-position: -1025px -600px;
}
.joypixels-24-diversity._1faf1-1f3fe-1faf2-1f3fb {
    background-position: -1025px -625px;
}
.joypixels-24-diversity._1faf1-1f3fe-1faf2-1f3fc {
    background-position: -1025px -650px;
}
.joypixels-24-diversity._1faf1-1f3fe-1faf2-1f3fd {
    background-position: -1025px -675px;
}
.joypixels-24-diversity._1faf1-1f3fe-1faf2-1f3ff {
    background-position: -1025px -700px;
}
.joypixels-24-diversity._1faf1-1f3fe {
    background-position: -1025px -725px;
}
.joypixels-24-diversity._1faf1-1f3ff-1faf2-1f3fb {
    background-position: -1025px -750px;
}
.joypixels-24-diversity._1faf1-1f3ff-1faf2-1f3fc {
    background-position: -1025px -775px;
}
.joypixels-24-diversity._1faf1-1f3ff-1faf2-1f3fd {
    background-position: -1025px -800px;
}
.joypixels-24-diversity._1faf1-1f3ff-1faf2-1f3fe {
    background-position: -1025px -825px;
}
.joypixels-24-diversity._1faf1-1f3ff {
    background-position: -1025px -850px;
}
.joypixels-24-diversity._1faf2-1f3fb {
    background-position: -1025px -875px;
}
.joypixels-24-diversity._1faf2-1f3fc {
    background-position: -1025px -900px;
}
.joypixels-24-diversity._1faf2-1f3fd {
    background-position: -1025px -925px;
}
.joypixels-24-diversity._1faf2-1f3fe {
    background-position: -1025px -950px;
}
.joypixels-24-diversity._1faf2-1f3ff {
    background-position: -1025px -975px;
}
.joypixels-24-diversity._1faf3-1f3fb {
    background-position: -1025px -1000px;
}
.joypixels-24-diversity._1faf3-1f3fc {
    background-position: 0px -1025px;
}
.joypixels-24-diversity._1faf3-1f3fd {
    background-position: -25px -1025px;
}
.joypixels-24-diversity._1faf3-1f3fe {
    background-position: -50px -1025px;
}
.joypixels-24-diversity._1faf3-1f3ff {
    background-position: -75px -1025px;
}
.joypixels-24-diversity._1faf4-1f3fb {
    background-position: -100px -1025px;
}
.joypixels-24-diversity._1faf4-1f3fc {
    background-position: -125px -1025px;
}
.joypixels-24-diversity._1faf4-1f3fd {
    background-position: -150px -1025px;
}
.joypixels-24-diversity._1faf4-1f3fe {
    background-position: -175px -1025px;
}
.joypixels-24-diversity._1faf4-1f3ff {
    background-position: -200px -1025px;
}
.joypixels-24-diversity._1faf5-1f3fb {
    background-position: -225px -1025px;
}
.joypixels-24-diversity._1faf5-1f3fc {
    background-position: -250px -1025px;
}
.joypixels-24-diversity._1faf5-1f3fd {
    background-position: -275px -1025px;
}
.joypixels-24-diversity._1faf5-1f3fe {
    background-position: -300px -1025px;
}
.joypixels-24-diversity._1faf5-1f3ff {
    background-position: -325px -1025px;
}
.joypixels-24-diversity._1faf6-1f3fb {
    background-position: -350px -1025px;
}
.joypixels-24-diversity._1faf6-1f3fc {
    background-position: -375px -1025px;
}
.joypixels-24-diversity._1faf6-1f3fd {
    background-position: -400px -1025px;
}
.joypixels-24-diversity._1faf6-1f3fe {
    background-position: -425px -1025px;
}
.joypixels-24-diversity._1faf6-1f3ff {
    background-position: -450px -1025px;
}
.joypixels-24-diversity._261d-1f3fb {
    background-position: -475px -1025px;
}
.joypixels-24-diversity._261d-1f3fc {
    background-position: -500px -1025px;
}
.joypixels-24-diversity._261d-1f3fd {
    background-position: -525px -1025px;
}
.joypixels-24-diversity._261d-1f3fe {
    background-position: -550px -1025px;
}
.joypixels-24-diversity._261d-1f3ff {
    background-position: -575px -1025px;
}
.joypixels-24-diversity._26f9-1f3fb-2640 {
    background-position: -600px -1025px;
}
.joypixels-24-diversity._26f9-1f3fb-2642 {
    background-position: -625px -1025px;
}
.joypixels-24-diversity._26f9-1f3fb {
    background-position: -650px -1025px;
}
.joypixels-24-diversity._26f9-1f3fc-2640 {
    background-position: -675px -1025px;
}
.joypixels-24-diversity._26f9-1f3fc-2642 {
    background-position: -700px -1025px;
}
.joypixels-24-diversity._26f9-1f3fc {
    background-position: -725px -1025px;
}
.joypixels-24-diversity._26f9-1f3fd-2640 {
    background-position: -750px -1025px;
}
.joypixels-24-diversity._26f9-1f3fd-2642 {
    background-position: -775px -1025px;
}
.joypixels-24-diversity._26f9-1f3fd {
    background-position: -800px -1025px;
}
.joypixels-24-diversity._26f9-1f3fe-2640 {
    background-position: -825px -1025px;
}
.joypixels-24-diversity._26f9-1f3fe-2642 {
    background-position: -850px -1025px;
}
.joypixels-24-diversity._26f9-1f3fe {
    background-position: -875px -1025px;
}
.joypixels-24-diversity._26f9-1f3ff-2640 {
    background-position: -900px -1025px;
}
.joypixels-24-diversity._26f9-1f3ff-2642 {
    background-position: -925px -1025px;
}
.joypixels-24-diversity._26f9-1f3ff {
    background-position: -950px -1025px;
}
.joypixels-24-diversity._270a-1f3fb {
    background-position: -975px -1025px;
}
.joypixels-24-diversity._270a-1f3fc {
    background-position: -1000px -1025px;
}
.joypixels-24-diversity._270a-1f3fd {
    background-position: -1025px -1025px;
}
.joypixels-24-diversity._270a-1f3fe {
    background-position: -1050px 0px;
}
.joypixels-24-diversity._270a-1f3ff {
    background-position: -1050px -25px;
}
.joypixels-24-diversity._270b-1f3fb {
    background-position: -1050px -50px;
}
.joypixels-24-diversity._270b-1f3fc {
    background-position: -1050px -75px;
}
.joypixels-24-diversity._270b-1f3fd {
    background-position: -1050px -100px;
}
.joypixels-24-diversity._270b-1f3fe {
    background-position: -1050px -125px;
}
.joypixels-24-diversity._270b-1f3ff {
    background-position: -1050px -150px;
}
.joypixels-24-diversity._270c-1f3fb {
    background-position: -1050px -175px;
}
.joypixels-24-diversity._270c-1f3fc {
    background-position: -1050px -200px;
}
.joypixels-24-diversity._270c-1f3fd {
    background-position: -1050px -225px;
}
.joypixels-24-diversity._270c-1f3fe {
    background-position: -1050px -250px;
}
.joypixels-24-diversity._270c-1f3ff {
    background-position: -1050px -275px;
}
.joypixels-24-diversity._270d-1f3fb {
    background-position: -1050px -300px;
}
.joypixels-24-diversity._270d-1f3fc {
    background-position: -1050px -325px;
}
.joypixels-24-diversity._270d-1f3fd {
    background-position: -1050px -350px;
}
.joypixels-24-diversity._270d-1f3fe {
    background-position: -1050px -375px;
}
.joypixels-24-diversity._270d-1f3ff {
    background-position: -1050px -400px;
}
